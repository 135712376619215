import { SHIPMENT_STATUSES } from '~/Configurations/constants';

export const TOTAL_HOURS_IN_A_DAY = 24;
export const TIME_WINDOW_DURATION = 2; // 2 hours

export const LOCATION_TYPE = {
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
};

// duplicated with the root constants (/src/Configurations/constants.js)
// TODO: remove this and use variable from the root constants
export const ROUTE_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
  DROPOFF_AND_PICKUP: 'DROPOFF_AND_PICKUP',
};

// dont sort SEGMENT_ARRAY & SEGMENT_ARRAY_NAV, idx used in stage of segments & sidenav
export const SEGMENT_ARRAY = ['DETAILS_FORM', 'ROUTE_FORM', 'PRODUCT_FORM', 'ALLOCATION_FORM', 'REVIEW_SUMMARY'];
export const SEGMENT_ARRAY_NAV = ['DETAILS_FORM', 'ROUTE_FORM', 'PRODUCT_FORM'];
export const SEGMENT_ARRAY_DTD_NAV = ['SHIPMENT_ROUTE', 'CONTAINER_DETAILS', 'REVIEW'];

export const SHIPMENT_ARRIVAL_STATUS_COLORS = {
  BLACK: '#000',
  RED: '#D13042'
};

const {
  ASSIGNED,
  UPCOMING,
  PLANNED,
  CANCELLED_BY_SHIPPER,
  CANCELLED_BY_TRANSPORTER,
  UNFULFILLED,
  REASSIGNED_BY_SHIPPER,
  REJECTED_BY_TRANSPORTER,
  CONFIRMATION_EXPIRED,
  ...SHIPMENT_ACTIVITY_LOG_STATUSES
} = SHIPMENT_STATUSES;

export { SHIPMENT_ACTIVITY_LOG_STATUSES };

export const VENDOR_ASSIGNMENT_TYPE = {
  BROADCAST_CONTRACTED: 'BROADCAST_CONTRACTED',
  PRIVATE_BIDDING: 'PRIVATE_BIDDING',
  SINGLE_CONTRACTED: 'SINGLE_CONTRACTED',
};

export const MAX_BID_LIMIT_TYPE = {
  VALUE: 'Rp',
  PERCENTAGE: '%',
};

export const SHIPMENT_SEARCH_TYPES = {
  DO_NUMBER: 'DO_NUMBER',
  EXTERNAL_ID: 'EXTERNAL_ID',
  LICENSE_PLATE: 'LICENSE_PLATE',
  PO_ORDER_NUMBER: 'PO_ORDER_NUMBER',
};

export const ORDERS_CSV_HEADER = {
  DO_NUMBER: 'order_num',
  EXTERNAL_ID: 'external_id',
  INBOUND_NO: 'inbound_po_number',
  OUTBOUND_NO: 'outbound_po_number',
  CONTAINER_NO: 'container_no',
  SEAL_NO: 'seal_no',
  SHIPPING_SEAL_NO: 'shipping_seal_no',
  VARIANT: 'variant',
  SERIAL_NUMBER: 'serial_num',
  POINT_TYPE: 'point_type',
  ORIGIN_LOCATION_ID: 'origin_location_id',
  ORIGIN_LOCATION_NAME: 'origin_location',
  DESTINATION_LOCATION_ID: 'destination_location_id',
  DESTINATION_LOCATION_NAME: 'destination_location',
  CREATED_AT: 'created_at',
  VENDOR_CONFIRMATION_AT: 'vendor_confirmation_at',
  REQUESTED_PICKUP_DATETIME: 'requested_pickup_date_time',
  ONGOING_TO_PICKUP: 'ongoing_to_pickup',
  ARRIVE_AT_PICKUP: 'arrive_at_pickup',
  START_LOADING: 'start_loading',
  FINISH_LOADING: 'finish_loading',
  POL_UPLOAD: 'pol_upload',
  ON_SHIPMENT: 'on_shipment',
  ARRIVE_AT_DESTINATION: 'arrive_at_destination',
  START_UNLOADING: 'start_unloading',
  FINISH_UNLOADING: 'finish_unloading',
  POD_UPLOAD: 'pod_upload',
  SHIPMENT_COMPLETE: 'shipment_complete',
  TRUCK_TYPE: 'truck_type',
  TRUCK_SUBTYPE: 'truck_subtype',
  VENDOR_NAME: 'vendor_name',
  DRIVER_NAME: 'driver_name',
  ORIGIN_TRANSIT_TIME: 'origin_transit_time',
  DESTINATION_TRANSIT_TIME: 'destination_transit_time',
  LEAD_TIME: 'lead_time',
  SKU_TYPE: 'sku_type',
  SKU_QTY: 'sku_qty',
  PRODUCT_BATCH: 'product_batch',
  ORDER_STATUS: 'order_status',
  ORDER_REJECTION_REASON: 'order_rejection_reason',
  CANCELLATION_REASON: 'cancellation_reason'
};

export const DOWNLOAD_ORDERS_FILE_NAME = 'orders';

export const FIRST_LOC_POINT = 'A';

export const ONE_HOUR_IN_SECOND = 3600;

export const OVERTIME_ARRIVE_AT_PICKUP_IN_HOUR = 6;

export const OVERTIME_ARRIVE_AT_DESTINATION_IN_HOUR = 10;

export const DESTINATION_WAREHOUSE_KSUID = 'destination_warehouse_ksuid';

export const UPDATE_SHIPMENT_ALERT_SOURCES = {
  DA: 'DA',
  TTMS: 'TTMS',
};

export const DEVICE_LOCATION_UPDATE_SOURCES = {
  MOBILE: 'MOBILE',
  WHATSAPP: 'WHATSAPP',
  GPS_DEVICE: 'GPS_DEVICE'
};

export default {};
