// TODO: Tear down this file into smaller ones

import moment from 'moment';

// don't sort this, will be used for tab order
export const LOCAL_SHIPMENT_STATUSES = {
  UPCOMING: 'upcoming',
  ALL: 'all',
  PLANNED: 'planned',
  AT_WAREHOUSE: 'at-warehouse',
  IN_TRANSIT: 'in-transit',
  AT_LOCATION: 'at-location',
  ONGOING: 'ongoing',
  COMPLETED: 'history',
  TRACKED: 'tracked', // not used by tabs, have filter at tabNav
};

export const CONTRACT_VENDOR_TAB = {
  CONTRACT: 'contracts',
  VENDOR: 'all',
};

export const CONTRACT_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const CONTRACT_STATUS_COLORS = {
  [CONTRACT_STATUSES.ACTIVE]: 'success',
  [CONTRACT_STATUSES.INACTIVE]: 'danger',
};

export const CONTRACT_RATE_TYPES = {
  PER_ROUTE: {
    label: 'Per Route',
    unit: '',
    value: 'PER_ROUTE',
  },
  PER_CBM: {
    label: 'Per CBM',
    unit: 'CBM',
    value: 'PER_CBM',
  },
  PER_KG: {
    label: 'Per Kg',
    unit: 'Kg',
    value: 'PER_KG',
  },
  PER_LANE: {
    label: 'Per Lane',
    unit: '',
    value: 'PER_LANE',
  },
  PER_UNIT: {
    label: 'Per Unit',
    unit: '',
    value: 'PER_UNIT',
  },
};

export const CONTRACT_EDITABLE_FIELDS = {
  AMOUNT: 'amount',
  CAPACITY: 'capacity',
  CAPACITY_UNIT: 'capacity_unit',
  END_DATETIME: 'end_datetime',
  MINIMUM_QUANTITY_TO_BE_PAID: 'minimum_quantity_to_be_paid',
  PARTNERSHIP_PRICING_NUMBER: 'partnership_pricing_number',
  PRICING_RATE_TYPE: 'pricing_rate_type',
  START_DATETIME: 'start_datetime',
  STATUS: 'status',
  UPDATED_BY: 'updated_by',
};

// The order of this constant matters for download csv
export const CONTRACT_CSV_HEADER = {
  TRANSPORTER_NAME: 'Transporter Name',
  CONTACT_NO: 'Contract No.',
  ORIGIN_ID: 'Origin ID',
  ORIGIN_NAME: 'Origin Name',
  DESTINATION_ID: 'Destination ID',
  DESTINATION_NAME: 'Destination Name',
  TRUCK_TYPE: 'Truck Type',
  BODY_TYPE: 'Body Type',
  TONNAGE: 'Tonnage',
  SKU_GROUP: 'SKU Group',
  SHIPMENT_TYPE: 'Shipment Type',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  PRICE: 'Price',
  RATE_TYPE: 'Rate Type',
  MIN_QUANTITY: 'Min Quantity',
  STATUS: 'Status',
  CREATED_AT: 'Created At',
  CREATED_BY: 'Created By',
};

// The order of this constant matters for download invoice csv
export const INVOICE_CSV_HEADER = {
  // TODO:  change property name based on input data from component
  INVOICE_NO: 'Invoice No.',
  VENDOR: 'Vendor',
  DATE_CREATED: 'Date Created',
  DUE_DATE: 'Due Date',
  TOTAL: 'Total',
  STATUS: 'Status',
};

export const CONTRACT_DOWNLOAD_FILE_NAME = 'contracts';
export const INVOICE_DOWNLOAD_FILE_NAME = 'invoice';

export const TRACKER_EVENTS = {
  ACCESS_SHARABLE_LINK: 'Access Sharable Link',
  BULK_DO_CLICKED: 'Bulk DO clicked',
  BULK_DO_SUBMITTED: 'Bulk DO submitted',
  CHOOSE_COMPANY_SUCCESS: 'ChooseCompanySuccess',
  DO_DETAIL_ASSIGN: 'DO detail assign',
  DO_LIST_ASSIGN: 'DO list assign',
  DO_LIST_EXPANDED: 'DO list expanded',
  DO_LIST_REASSIGN: 'DO list reassign',
  DOWNLOAD_INSIGHTS_CSV: 'Download insights CSV',
  INSIGHTS_FILTER_CHANGED: 'Insight filter changed',
  INSIGHTS_CHART_TARGET_CHANGED: 'Insight chart target changed',
  LOGIN_SUCCESS: 'LoginSuccess',
  LOGOUT_SUCCESS: 'LogoutSuccess',
  MAP_MARKER_SELECTED: 'Map marker selected',
  MAP_SEARCH_KEYWORD_APPLIED: 'Map searchKeyword filter applied',
  MAP_SHIPMENT_DIRECTION_APPLIED: 'Map shipment direction filter applied',
  MAP_STATUS_APPLIED: 'Map status filter applied',
  MAP_WAREHOUSE_APPLIED: 'Map warehouse filter applied',
  NAVIGATION_CHANGED: 'Navigation changed',
  ONSITE_NOTIF_OPENED: 'Onsite notification opened',
  SINGLE_DO_CLICKED: 'Single DO clicked',
  SINGLE_DO_SUBMITTED: 'Single DO Submitted',
  FILTER_STATUS: 'Filter Status',
  FILTER_PICKUP_LOCATION: 'Filter pickup location',
  FILTER_DROPOFF_LOCATION: 'Filter dropoff location',
  FILTER_PICKUP_DATE: 'Filter pickup date time',
  FILTER_DROPOFF_DATE: 'Filter dropoff date time',
  EXPAND_SHIPMENT: 'click expand shipment',
  OPEN_SHIPMENT_DETAIL: 'Open shipment detail',
  OPEN_SHIPMENT_LIST: 'Open shipment list page',
  OPEN_SHIPMENT_TRACKING_LINK: 'Open shipment from sharing link',
  OPEN_TAB: 'Open shipment list tab',
  CLICK_CONTACT_US: 'Click Hubungi Kami in shipment detail',
  UPSELLING_BANNER_START_TRIAL: 'Upselling Banner Start Trial',
  UPSELLING_HOME_CLOSE: 'Close Upselling Home Popup',
  UPSELLING_HOME_START_TRIAL: 'Upselling Home Popup Start Free Trial',
  UPSELLING_LAST_START_TRIAL: 'Upselling Last Page Popup Start Free Trial',
  UPSELLING_LAST_CLOSE: 'Close Upselling Last Page Popup',
  UPSELLING_SEND_FEEDBACK: 'Upselling Send Feedback Page Popup',
};

export const LEGACY_ANALYTICS_EVENT = {
  BUTTON_ADD_CONTRACT: 'nfs_button_add_contract',
  SCREEN_VIEW_CONTRACT: 'nfs_page_view_contract',
};

export const LOGIN_PAGE_ANALYTICS_EVENT = {
  CONTINUE_LOGIN_CLICK: 'ContinueLoginClick',
  CONTINUE_REGISTER_CLICK: 'ContinueRegisterClick',
  LOGIN_CLICK: 'LoginClick',
  REGISTER_CLICK: 'RegisterClick',
  SCREEN_VIEW_LOGIN_SCREEN: 'ScreenView_LoginScreen',
};

export const TABS_ANALYTICS_EVENT = {
  UPPER_TAB_INVOICE_CLICK: 'UpperTab_InvoiceClick',
  UPPER_TAB_LIVE_LOCATION_CLICK: 'UpperTab_LiveLocationClick',
  UPPER_TAB_ORDERS_CLICK: 'UpperTab_OrdersClick',
  UPPER_TAB_REPORTS_CLICK: 'UpperTab_ReportsClick',
};

export const ORDERS_PAGE_ANALYTICS_EVENT = {
  ORDERS_CREATE_ORDER_CLICK: 'Orders_CreateOrderClick',
  ORDERS_DOWNLOAD_CSV_CLICK: 'Orders_DownloadCSVClick',
  ORDERS_UPLOAD_MULTI_ORDERS: 'Orders_UploadMultiOrders',
  ORDERS_UPLOAD_SINGLE_ORDERS: 'Orders_UploadSingleOrders',
  SCREEN_VIEW_ORDERS_TAB: 'ScreenView_OrdersTab',
};

export const CREATE_ORDERS_ANALYTICS_EVENT = {
  ORDERS_ASSIGN_VENDOR_CONT: 'Orders_AssignVendorCont',
  ORDERS_FINAL_CREATE_ORDER: 'Orders_FinalCreateOrder',
  ORDERS_FINAL_UPLOAD_CSV: 'Orders_FinalUploadCSV',
  ORDERS_ORDER_DETAILS_CONT: 'Orders_OrderDetailsCont',
  ORDERS_PRODUCT_DETAILS_CONT: 'Orders_ProductDetailsCont',
  ORDERS_ROUTE_DETAILS_CONT: 'Orders_RouteDetailsCont',
};

export const REPORTS_PAGE_ANALYTICS_EVENT = {
  REPORTS_DOWNLOAD_CSV_CLICK: 'Reports_DownloadCSVClick',
  SCREEN_VIEW_REPORTS_TAB: 'ScreenView_ReportsTab',
};

export const INVOICE_PAGE_ANALYTICS_EVENT = {
  INVOICE_DOWNLOAD_CSV_CLICK: 'Invoice_DownloadCSVClick',
  SCREEN_VIEW_INVOICE_TAB: 'ScreenView_InvoiceTab',
  SCREEN_VIEW_PBI_SOA_TAB: 'ScreenView_PBISoATab',
};

export const LIVE_MONITORING_PAGE_ANALYTICS_EVENT = {
  SCREEN_VIEW_LIVE_MONITORING_CTR: 'ScreenView_LiveMonitoringCTR',
  SCREEN_VIEW_PUBLIC_LIVE_LOCATION_MONITORING: 'ScreenView_LiveLocationMonitoringCTR'
};

export const ANALYTICS_EVENT = {
  ...CREATE_ORDERS_ANALYTICS_EVENT,
  ...INVOICE_PAGE_ANALYTICS_EVENT,
  ...LEGACY_ANALYTICS_EVENT,
  ...LIVE_MONITORING_PAGE_ANALYTICS_EVENT,
  ...LOGIN_PAGE_ANALYTICS_EVENT,
  ...ORDERS_PAGE_ANALYTICS_EVENT,
  ...REPORTS_PAGE_ANALYTICS_EVENT,
  ...TABS_ANALYTICS_EVENT,
};

export const PHONE_NUMBER_STATUS = {
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
  FIRST_TIME_SIGN_IN: 'FIRST_TIME_SIGN_IN',
};

export const COMPANY_TYPES = {
  PT: 'PT',
  UD: 'UD',
  CV: 'CV',
  FA: 'FA',
};

export const DO_REJECTION_REASONS = {
  VEHICLE_NOT_READY: 'VEHICLE_NOT_READY',
  VEHICLE_MAINTENANCE_NEEDED: 'VEHICLE_MAINTENANCE_NEEDED',
  DRIVER_NOT_READY: 'DRIVER_NOT_READY',
  OTHER_REASON: 'OTHER_REASON',
};

export const DO_CANCELLATION_REASONS = {
  TRUCK_NO_SHOW: 'TRUCK_NO_SHOW',
  COMPLIANCE_ISSUE: 'COMPLIANCE_ISSUE',
  FULL_STOCK: 'FULL_STOCK',
  NO_EMPTY_JUGS: 'NO_EMPTY_JUGS',
  NO_PALLET_JUGRACK: 'NO_PALLET_JUGRACK',
  NO_STOCK: 'NO_STOCK',
  LOADING_DELAY: 'LOADING_DELAY',
  TKBM_ISSUE: 'TKBM_ISSUE',
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  ALLOCATION_REVISION: 'ALLOCATION_REVISION',
  OTHER_REASON: 'OTHER_REASON',
};

export const DO_EDIT_LOCATION_CHANGE_REASONS = {
  CHANGE_OF_PLANS: 'CHANGE_OF_PLANS',
  LOCATION_IS_FULL: 'LOCATION_IS_FULL',
  OTHERS: 'OTHERS',
};

export const OTP_OPTIONS = {
  REGISTRATION: 'REGISTRATION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FIRST_TIME_LOGIN: 'FIRST_TIME_LOGIN',
};

export const PLATE_COLORS = {
  BLACK_PLATE: 'BLACK_PLATE',
  YELLOW_PLATE: 'YELLOW_PLATE',
};

export const LOCATION_TYPE = {
  NORMAL: 'NORMAL',
  HOLDING: 'HOLDING',
  PORT: 'PORT',
  RETURN_DEPO: 'RETURN_DEPO',
  STORE: 'STORE',
};

export const ROUTE_LOCATION_GROUP = {
  PORT_STORE_RETURN_DEPO: [LOCATION_TYPE.PORT, LOCATION_TYPE.STORE, LOCATION_TYPE.RETURN_DEPO],
  PORT_HOLDING: [LOCATION_TYPE.PORT, LOCATION_TYPE.HOLDING],
  HOLDING_STORE_RETURN_DEPO: [LOCATION_TYPE.HOLDING, LOCATION_TYPE.STORE, LOCATION_TYPE.RETURN_DEPO],
};

export const ROUTE_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
  DROPOFF_AND_PICKUP: 'DROPOFF_AND_PICKUP',
};

export const PAYMENT_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  PPN: 'PPN',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  TICKET_FEE: 'TICKET_FEE',
  OTHER_FEE: '',
};

export const PAYMENT_TYPES_INVOICE = {
  LOADING_FEE: 'LOADING_FEE',
  MULTIDROPOFF_FEE: 'MULTIDROPOFF_FEE',
  MULTIPICKUP_FEE: 'MULTIPICKUP_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  TICKET_FEE: 'TICKET_FEE',
  TRUCKING_FEE: 'TRUCKING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
};

export const SERVICES_URL = {
  PANTHERA: window.PANTHERA_SERVICE_URL,
  LOAD: window.LOAD_SERVICE_URL,
  EVENT: window.EVENT_SERVICE_URL,
  PROFILE: window.PROFILE_SERVICE_URL,
  MASTER_SERVICE: window.MASTER_SERVICE_URL,
  AUTH: window.AUTH_SERVICE_URL,
  PROFILE_PUBLIC: window.PROFILE_SERVICE_URL_PUBLIC,
  LOAD_PUBLIC: window.LOAD_SERVICE_URL_PUBLIC,
  PAYMENT: window.PAYMENT_SERVICE_URL,
  SHIPPER_ORDER_MANAGEMENT: window.SHIPPER_ORDER_MANAGEMENT_URL
};

export const EVENT_SERVICE_DEVICE_TYPE = 'transporter_central';

export const USER_SALES_ROLE = {
  SME_TELESALES: 'SME_TELESALES',
  SME_SALES_EXECUTIVE: 'SME_SALES_EXECUTIVE',
  SME_TEAM_LEADER: 'SME_TEAM_LEADER',
};

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  INACTIVE: 'INACTIVE',
};

export const USER_STATUS_COLORS = {
  [USER_STATUSES.ACTIVE]: 'success',
  [USER_STATUSES.REGISTERED]: 'warning',
  [USER_STATUSES.INACTIVE]: 'danger',
};

export const NON_EDITABLE_USER_STATUSES = [USER_STATUSES.BLOCKED, USER_STATUSES.INACTIVE];

export const DOCUMENT_TYPES = {
  POD: 'POD',
  POL: 'POL',
  CLAIMS_AND_RETURNS: 'CAR',
  CLAIM_AND_RETURN_DOCUMENT: 'CLAIM_AND_RETURN_DOCUMENT',
  CLAIM_AND_RETURN_GOODS: 'CLAIM_AND_RETURN_GOODS',
  DRIVER_REJECTED_GOODS: 'REJECTED_GOODS',
  DRIVER_REJECTION_LETTER: 'REJECTION_LETTER',
};

export const REQUIRED_DOCUMENT_RETURN_TYPE = {
  BERITA_ACARA: 'BERITA_ACARA',
  BUKTI_PENERIMAAN_BARANG: 'BUKTI_PENERIMAAN_BARANG',
  DISPATCH_MEMO: 'DISPATCH_MEMO',
  FORM_ABSENSI: 'FORM_ABSENSI',
  FORM_ABSENSI_BONGKAR: 'FORM_ABSENSI_BONGKAR',
  FORM_ABSENSI_MUAT: 'FORM_ABSENSI_MUAT',
  KWITANSI_TKBM: 'KWITANSI_TKBM',
  NOTA_TIMBANG: 'NOTA_TIMBANG',
  NOTA_TIMBANG_CARBONIZE_BONGKAR: 'NOTA_TIMBANG_CARBONIZE_BONGKAR',
  NOTA_TIMBANG_CARBONIZE_MUAT: 'NOTA_TIMBANG_CARBONIZE_MUAT',
  NOTA_TIMBANG_ORIGINAL_BONGKAR: 'NOTA_TIMBANG_ORIGINAL_BONGKAR',
  NOTA_TIMBANG_ORIGINAL_MUAT: 'NOTA_TIMBANG_ORIGINAL_MUAT',
  PACKING_LIST: 'PACKING_LIST',
  SURAT_PERINTAH_JALAN: 'SURAT_PERINTAH_JALAN',
  BILL_OF_LADING: 'BILL_OF_LADING',
  SURAT_JALAN_CARBONIZE: 'SURAT_JALAN_CARBONIZE',
  SURAT_JALAN_ORIGINAL: 'SURAT_JALAN_ORIGINAL',
  BERITA_ACARA_MUAT: 'BERITA_ACARA_MUAT',
  BERITA_ACARA_SERAH_TERIMA_BARANG: 'BERITA_ACARA_SERAH_TERIMA_BARANG',
  BERITA_SERAH_TERIMA_BARANG: 'BERITA_SERAH_TERIMA_BARANG',
  BON_TIMBANG: 'BON_TIMBANG',
  COMMERCIAL_INVOICE: 'COMMERCIAL_INVOICE',
  DELIVERY_LOCAL_ORDER_COPY: 'DELIVERY_LOCAL_ORDER_COPY',
  DELIVERY_LOCAL_ORDER_ORIGINAL: 'DELIVERY_LOCAL_ORDER_ORIGINAL',
  DELIVERY_NOTE: 'DELIVERY_NOTE',
  EIR_DEPOT: 'EIR_DEPOT',
  EIR_PORT: 'EIR_PORT',
  FAKTUR_PAJAK: 'FAKTUR_PAJAK',
  FAKTUR_PAJAK_DEPOT: 'FAKTUR_PAJAK_DEPOT',
  GOOD_RECEIPT: 'GOOD_RECEIPT',
  LIVE_LOADING_DOCUMENT: 'LIVE_LOADING_DOCUMENT',
  LOLO: 'LOLO',
  PACKINGLIST_CARBONIZE: 'PACKINGLIST_CARBONIZE',
  PACKINGLIST_ORIGINAL: 'PACKINGLIST_ORIGINAL',
  PPFTZ_03: 'PPFTZ_03',
  RBPB_CARBONIZE: 'RBPB_CARBONIZE',
  RBPB_ORIGINAL: 'RBPB_ORIGINAL',
  RECEIPT_DEURAGE: 'RECEIPT_DEURAGE',
  RECEIPT_EXTEND: 'RECEIPT_EXTEND',
  RECEIPT_REPAIR: 'RECEIPT_REPAIR',
  RECEIPT_STORAGE_PORT: 'RECEIPT_STORAGE_PORT',
  SURAT_JALAN_PABRIK_CARBONIZE: 'SURAT_JALAN_PABRIK_CARBONIZE',
  SURAT_JALAN_PABRIK_ORIGINAL: 'SURAT_JALAN_PABRIK_ORIGINAL',
  SURAT_JALAN_PANTHERA_CARBONIZE: 'SURAT_JALAN_PANTHERA_CARBONIZE',
  SURAT_JALAN_PANTHERA_ORIGINAL: 'SURAT_JALAN_PANTHERA_ORIGINAL',
  SURAT_JALAN_SHIPPER_CARBONIZE: 'SURAT_JALAN_SHIPPER_CARBONIZE',
  SURAT_JALAN_SHIPPER_ORIGINAL: 'SURAT_JALAN_SHIPPER_ORIGINAL',
  SURAT_JALAN_TRANSFER_CARBONIZE: 'SURAT_JALAN_TRANSFER_CARBONIZE',
  SURAT_JALAN_TRANSFER_ORIGINAL: 'SURAT_JALAN_TRANSFER_ORIGINAL',
  SURAT_KETERANGAN_MUAT_BARANG: 'SURAT_KETERANGAN_MUAT_BARANG',
  SURAT_PENERIMAAN_BARANG: 'SURAT_PENERIMAAN_BARANG',
  SURAT_PERINTAH_EXPEDISI: 'SURAT_PERINTAH_EXPEDISI',
  SURAT_PERINTAH_PENGELUARAN_BARANG: 'SURAT_PERINTAH_PENGELUARAN_BARANG',
  TALLY_SHEET: 'TALLY_SHEET',
  TKBM_BONGKAR: 'TKBM_BONGKAR',
  TKBM_MUAT: 'TKBM_MUAT',
  OTHER: 'OTHER',
};

export const ADDITIONAL_DOCUMENT_RETURN_TYPE = {
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  CLAIM_AND_RETURN: 'CLAIM_AND_RETURN',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
};

export const SHIPMENT_DOCUMENT_TYPES = {
  INTERNAL_POD: 'internal-pod',
  INTERNAL_POL: 'internal-pol',
  CLAIMS_AND_RETURNS: 'claims-returns',
};

export const DOCUMENT_VERIFICATION_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  NEGLECTED: 'NEGLECTED',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  REJECTED: 'REJECTED',
};

export const CUSTOMER_DIVISION = {
  OPERATIONS: 'OPERATIONS',
  WAREHOUSE: 'WAREHOUSE',
  SALES: 'SALES',
  FINANCE: 'FINANCE',
  PROCUREMENT: 'PROCUREMENT',
  LOGISTICS: 'LOGISTICS',
  TREASURY: 'TREASURY',
  CLAIMS: 'CLAIMS',
};

export const WEIGHT_UNITS = {
  TON: 'TON',
  CBM: 'CBM',
};

// Dont sort, used for toggle route type
export const ROUTE_TYPES = {
  SINGLE_PD: 'Single Pickup and Dropoff',
  MULTI_PD: 'Multipickup/Multidropoff',
};

export const PPH23_DEDUCTOR = {
  PARTNER: 'PARTNER',
  SELF: 'SELF',
};

export const SHIPMENT_FORM_TYPES = {
  ALLOCATE: 'ALLOCATE',
  APPROVE_REASSIGNMENT: 'APPROVE_REASSIGNMENT',
  ASSIGN_SHIPMENT: 'ASSIGN_SHIPMENT',
  CANCEL_MARKETPLACE_JOB: 'CANCEL_MARKETPLACE_JOB',
  CANCEL_SHIPMENT: 'CANCEL_SHIPMENT',
  CHOOSE_WINNER: 'CHOOSE_WINNER',
  CREATE_REMINDER: 'CREATE_REMINDER',
  CREATE: 'CREATE',
  EVALUATE: 'EVALUATE',
  REALLOCATE: 'REALLOCATE',
  REEVALUATE: 'REEVALUATE',
  REOPEN_JOB: 'REOPEN_JOB',
  SET_BID_BASE_PRICE: 'SET_BID_BASE_PRICE',
  SET_BID_WINNER: 'SET_BID_WINNER',
  UNFULFILLMENT_SHIPMENT: 'UNFULFILLMENT_SHIPMENT',
  UPDATE_DO_NUMBER: 'UPDATE_DO_NUMBER',
  UPDATE: 'UPDATE',
};

export const SHIPMENT_FORM_SEGMENTS = {
  ALLOCATION_FORM: 'ALLOCATION_FORM',
  DETAILS_FORM: 'DETAILS_FORM',
  PRODUCT_FORM: 'PRODUCT_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  ROUTE_FORM: 'ROUTE_FORM',
};

export const SHIPMENT_DTD_FORM_SEGMENTS = {
  SHIPMENT_ROUTE: 'SHIPMENT_ROUTE',
  CONTAINER_DETAILS: 'CONTAINER_DETAILS',
  REVIEW: 'REVIEW',
};

export const SHIPMENT_POINT_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
};

export const SHIPMENT_STATUSES = {
  UPCOMING: 'UPCOMING',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ON_SHIPMENT: 'ON_SHIPMENT',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  SHIPMENT_REASSIGNMENT: 'SHIPMENT_REASSIGNMENT',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED: 'CANCELLED',
  UNFULFILLED: 'UNFULFILLED',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  ACTIVATE_VEHICLE_ALERT: 'ACTIVATE_VEHICLE_ALERT',
  DEACTIVATE_VEHICLE_ALERT: 'DEACTIVATE_VEHICLE_ALERT',
  GPS_LOCATION: 'GPS_LOCATION',
};

export const PURCHASE_ORDER_STATUS = {
  CANCELLED: 'CANCELLED',
  ORDER_APPROVED: 'ORDER_APPROVED',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  ORDER_PENDING: 'ORDER_PENDING',
  REJECTED_BY_VENDOR: 'REJECTED_BY_TRANSPORTER'
};

export const PURCHASE_ORDER_STATUS_COLOR = {
  [PURCHASE_ORDER_STATUS.ORDER_APPROVED]: 'primaryBlue',
  [PURCHASE_ORDER_STATUS.ORDER_COMPLETED]: 'highlightGreen',
  [PURCHASE_ORDER_STATUS.ORDER_APPROVED]: 'primaryBlue',
  [PURCHASE_ORDER_STATUS.ORDER_PENDING]: 'highlightRed',
  [PURCHASE_ORDER_STATUS.CANCELLED]: 'highlightRed',
  [PURCHASE_ORDER_STATUS.REJECTED_BY_VENDOR]: 'highlightRed'
};

export const PURCHASE_ORDER_TYPE = {
  DTD: 'DTD',
  FM_IMPORT: 'FM_IMPORT',
  FM_EXPORT: 'FM_EXPORT',
  FM_INBOUND: 'FM_INBOUND',
  FM_OUTBOUND: 'FM_OUTBOUND',
};

export const BTMS_SHIPMENT_STATUSES = {
  ...SHIPMENT_STATUSES,
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  QUEUED: 'QUEUED',
};

export const BTMS_NEW_FILTER_SHIPMENT_STATUSES = [
  BTMS_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
  SHIPMENT_STATUSES.UNFULFILLED,
];

export const DERIVED_BTMS_NEW_SHIPMENT_STATUSES = {
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  UNFULFILLED: 'UNFULFILLED',
};

export const ARRIVED_STATUSES = [
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
];

export const SHIPMENT_ALERT_STATUSES = [
  SHIPMENT_STATUSES.ACTIVATE_VEHICLE_ALERT,
  SHIPMENT_STATUSES.DEACTIVATE_VEHICLE_ALERT,
];

export const DERIVED_SHIPMENT_STATUSES = {
  MAIN_SHIPMENT: 'MAIN_SHIPMENT',
  BEHIND_THE_SCHEDULE: 'BEHIND_THE_SCHEDULE',
  ON_TIME: 'ON_TIME',
  QUEUEING_AT_ORIGIN: 'QUEUEING_AT_ORIGIN',
  QUEUE_AT_ORIGIN_LATE: 'QUEUE_AT_ORIGIN_LATE',
  QUEUEING_AT_DESTINATION: 'QUEUEING_AT_DESTINATION',
  QUEUE_AT_DESTINATION_LATE: 'QUEUE_AT_DESTINATION_LATE',
  QUEUEING_OVERTIME: 'QUEUEING_OVERTIME',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_WAITING_FOR_PRICE_INPUT: 'JOB_WAITING_FOR_PRICE_INPUT',
  ISSUES: 'ISSUES',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  PICKUP_EMPTY_CONTAINER: 'PICKUP_EMPTY_CONTAINER',
  STUFFING: 'STUFFING',
  ONGOING_TO_PORT_OF_LOADING: 'ONGOING_TO_PORT_OF_LOADING',
  AT_PORT_OF_LOADING: 'AT_PORT_OF_LOADING',
  ONGOING_TO_PORT_OF_DISCHARGE: 'ONGOING_TO_PORT_OF_DISCHARGE',
  AT_PORT_OF_DISCHARGE: 'AT_PORT_OF_DISCHARGE',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  ORDER_PENDING: 'ORDER_PENDING',
};

export const SHIPMENT_COUNT_QUERY_NAME = {
  [SHIPMENT_STATUSES.UPCOMING]: 'upcomingCount',
  [SHIPMENT_STATUSES.PLANNED]: 'plannedCount',
  [SHIPMENT_STATUSES.ASSIGNED]: 'assignedCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
  [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDestinationCount',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'shipmentCompleteCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'cancelledByTransporterCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'cancelledByShipperCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'cancelledByInternalCount',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'queueingOvertimeCount',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'behindTheScheduleCount',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'onTimeCount',
  [DERIVED_SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'waitingForJobConfirmationCount',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'confirmationExpiredCount',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'onGoingToPickupCount',
  [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'ongoingToDestinationCount',
};

export const SHIPMENT_STATUS_COUNTS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    DERIVED_SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION,
    SHIPMENT_STATUSES.PLANNED,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME,
    SHIPMENT_STATUSES.START_LOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE,
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE,
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE,
    DERIVED_SHIPMENT_STATUSES.ON_TIME,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.LATE_TO_ORIGIN,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.LATE_TO_DESTINATION,
  ],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER, SHIPMENT_STATUSES.SHIPMENT_COMPLETE],
  [LOCAL_SHIPMENT_STATUSES.ALL]: [SHIPMENT_STATUSES.UPCOMING, SHIPMENT_STATUSES.CONFIRMATION_EXPIRED],
};

export const SHIPMENT_STATUS_LOCAL_GROUPS = ({
  hasDoorToDoorManagementConfig = false
}) => ({
  [LOCAL_SHIPMENT_STATUSES.UPCOMING]: [SHIPMENT_STATUSES.UPCOMING],
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.UPCOMING,
    DERIVED_SHIPMENT_STATUSES.JOB_WAITING_FOR_PRICE_INPUT,
    DERIVED_SHIPMENT_STATUSES.JOB_BIDDING,
    DERIVED_SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION,
    SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.ASSIGNED,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    ...(hasDoorToDoorManagementConfig ? [
      DERIVED_SHIPMENT_STATUSES.STUFFING,
      DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_LOADING,
      DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_DISCHARGE,
    ] : [])
  ],
  [LOCAL_SHIPMENT_STATUSES.HISTORY]: [SHIPMENT_STATUSES.SHIPMENT_COMPLETE, SHIPMENT_STATUSES.SHIPMENT_CANCELLED],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    ...(hasDoorToDoorManagementConfig ? [
      DERIVED_SHIPMENT_STATUSES.STUFFING,
      DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_LOADING,
      DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_DISCHARGE,
    ] : [])
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ON_SHIPMENT,
    ...(hasDoorToDoorManagementConfig ? [
      DERIVED_SHIPMENT_STATUSES.PICKUP_EMPTY_CONTAINER,
      DERIVED_SHIPMENT_STATUSES.ONGOING_TO_PORT_OF_LOADING,
      DERIVED_SHIPMENT_STATUSES.ONGOING_TO_PORT_OF_DISCHARGE,
    ] : [])
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [SHIPMENT_STATUSES.SHIPMENT_COMPLETE, SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER],
  // Fixing ST-1979, don't sort this, need sort by shipment status sequences
  [LOCAL_SHIPMENT_STATUSES.TRACKED]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ON_SHIPMENT,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  get [LOCAL_SHIPMENT_STATUSES.ALL]() {
    return [
      ...this[LOCAL_SHIPMENT_STATUSES.PLANNED],
      ...this[LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE],
      ...this[LOCAL_SHIPMENT_STATUSES.IN_TRANSIT],
      ...this[LOCAL_SHIPMENT_STATUSES.COMPLETED],
    ];
  },
});

export const SHIPPER_LOCATION_STATUS = {
  ACTIVE: 'REGISTERED',
  INACTIVE: 'DEACTIVATED',
  REGISTERED: 'REGISTERED',
};

export const JOB_STATUSES = {
  COMPLETED: 'COMPLETED',
  OPEN: 'OPEN',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  TRANSPORTER_CONFIRMED: 'TRANSPORTER_CONFIRMED',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  EXPIRED: 'EXPIRED',
  UNFULFILLED: 'UNFULFILLED',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
};

export const FULLY_EDITABLE_JOB_STATUSES = [
  JOB_STATUSES.OPEN,
  JOB_STATUSES.WAITING_FOR_CONFIRMATION,
  // intended to use SHIPMENT_STATUSES
  // because the actual job status returned by backend has REJECTED_BY_TRANSPORTER value
  // but, to prevent unknown breaking changes, the existing JOB_STATUSES is not modified
  SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER,
  JOB_STATUSES.CONFIRMATION_EXPIRED,
];

export const LOCATION_DROPOFF_EDITABLE_JOB_STATUSES = [
  JOB_STATUSES.UNASSIGNED,
  JOB_STATUSES.WAITING_FOR_CONFIRMATION,
  JOB_STATUSES.CONFIRMATION_EXPIRED,
  JOB_STATUSES.REJECTED_BY_TRANSPORTER,
];

export const SHIPMENT_STATUS_SEQUENCE = [
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];

export const SHIPPER_LOCATION_OPERATING_DAY = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  WEEKDAY: 'WEEKDAY',
  WEEKEND: 'WEEKEND',
  HOLIDAY: 'HOLIDAY',
};

export const SHIPMENT_STATUS_COLORS = {
  [SHIPMENT_STATUSES.UPCOMING]: 'danger',
  [SHIPMENT_STATUSES.PLANNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ASSIGNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.START_LOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_LOADING]: 'warning',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'primary',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED]: 'danger',
  [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'warning',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'primary',
  [DERIVED_SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'warning',
  [DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_DISCHARGE]: 'success',
  [DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_LOADING]: 'success',
  [DERIVED_SHIPMENT_STATUSES.ONGOING_TO_PORT_OF_DISCHARGE]: 'warning',
  [DERIVED_SHIPMENT_STATUSES.ONGOING_TO_PORT_OF_LOADING]: 'warning',
  [DERIVED_SHIPMENT_STATUSES.PICKUP_EMPTY_CONTAINER]: 'warning',
  [DERIVED_SHIPMENT_STATUSES.ORDER_CONFIRMED]: 'success',
  [DERIVED_SHIPMENT_STATUSES.STUFFING]: 'warning',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
};

export const BTMS_SHIPMENT_STATUS_COLORS = {
  ...SHIPMENT_STATUS_COLORS,
  [BTMS_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'danger',
  [BTMS_SHIPMENT_STATUSES.QUEUED]: 'darkGrey',
  [BTMS_SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'success',
};

export const TRUCK_MONITORING_STATUS_COLORS = {
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'warning',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'success',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.ISSUES]: 'danger',
};

export const MORE_FILTER_KEYS = {
  ORIGIN_LOCATION_FIELD: 'originWarehouseKsuid',
  DESTINATION_LOCATION_FIELD: 'destinationWarehouseKsuid',
  LOADING_DATE_FIELD: 'loadingDate',
  UNLOADING_DATE_FIELD: 'unloadingDate',
  ARRIVE_AT_ORIGIN_DATE_FIELD: 'arriveAtOriginDate',
  ARRIVE_AT_DESTINATION_DATE_FIELD: 'arriveAtDestinationDate',
  TAGS_FIELD: 'tags',
  SUPPORT_FIELD: 'createdByKsuid',
  EXTERNAL_ID: 'externalId',
  GROUP_LOAD_NUMBER: 'groupLoadNumber',
  TRANSPORTER_COMPANY: 'transporterCompanyKsuids',
  DRIVER: 'driverKsuids',
};

export const GENERAL_ENTITY_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REGISTERED: 'REGISTERED',
  CHURN: 'CHURN',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_STATUS_COLORS = {
  [COMPANY_STATUSES.ACTIVE]: 'success',
  [COMPANY_STATUSES.INACTIVE]: 'danger',
  [COMPANY_STATUSES.REGISTERED]: 'orangeyYellow',
  [COMPANY_STATUSES.CHURN]: 'danger',
  [COMPANY_STATUSES.DEACTIVATED]: 'danger',
};

const LOCATION_STATUSES = {
  REGISTERED: 'REGISTERED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const LOCATION_STATUS_COLORS = {
  [LOCATION_STATUSES.REGISTERED]: 'success',
  [LOCATION_STATUSES.ACTIVE]: 'success',
  [LOCATION_STATUSES.INACTIVE]: 'danger',
};

export const GENERAL_ENTITY_STATUS_COLORS = {
  REGISTERED: 'success',
  DEACTIVATED: 'danger',
};

export const USER_ACCESS_TYPE = {
  ADMIN: 'ADMIN',
  FULL_ACCESS: 'FULL_ACCESS',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
  FINANCE: 'FINANCE',
};

export const FILE_UPLOAD_SOURCE = {
  BTMS: 'BTMS',
  DA: 'DA',
  TMS: 'TMS',
};

export const FILE_PREVIEW_TYPE = {
  IMAGE: 'IMAGE',
  GDRIVE_FOLDER: 'GDRIVE_FOLDER',
  GDRIVE_FILE: 'GDRIVE_FILE',
  PDF: 'PDF',
};

export const MIME_TO_FILE_PREVIEW_TYPE_MAP = {
  'image/jpeg': FILE_PREVIEW_TYPE.IMAGE,
  'image/png': FILE_PREVIEW_TYPE.IMAGE,
  'application/pdf': FILE_PREVIEW_TYPE.PDF,
};

// [Start] Fist Mile Project Specific
export const FIRST_MILE_USER_ACCESS_TYPE = {
  FREIGHT_FORWARDER: 'FREIGHT_FORWARDER',
  CONSIGNEE: 'CONSIGNEE',
};

export const CONTAINER_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  BATCH_UPDATE: 'BATCH_UPDATE',
};

export const CONTAINER_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  BATCH_UPDATE_FORM: 'BATCH_UPDATE_FORM',
};

export const CONTAINER_STATUSES = {
  ARRIVED_AT_PORT: 'ARRIVED_AT_PORT',
  PIB: 'PIB',
  NO_NOA: 'NO_NOA',
  NOA: 'NOA',
  SPPB: 'SPPB',
  WAITING_FOR_SPPB: 'WAITING_FOR_SPPB',
  WAITING_FOR_ORIGINAL_DOC: 'WAITING_FOR_ORIGINAL_DOC',
  QUARANTINED: 'QUARANTINED',
  ON_SHIPMENT: 'ON_SHIPMENT',
  MOVED_TO_HOLDING: 'MOVED_TO_HOLDING',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  INVOICED: 'INVOICED',
  PAID: 'PAID',
};

export const CONTAINER_STATUS_COLOR = {
  [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'primary',
  [CONTAINER_STATUSES.ON_SHIPMENT]: 'primary',
  [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [CONTAINER_STATUSES.PAYMENT_PENDING]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'warning',
  [CONTAINER_STATUSES.PAID]: 'success',
  [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'primary',
  [CONTAINER_STATUSES.PIB]: 'primary',
  [CONTAINER_STATUSES.NO_NOA]: 'warning',
  [CONTAINER_STATUSES.NOA]: 'primary',
  [CONTAINER_STATUSES.SPPB]: 'primary',
  [CONTAINER_STATUSES.QUARANTINED]: 'primary',
  [CONTAINER_STATUSES.INVOICED]: 'success',
};

export const FIRST_MILE_INVOICE_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const FIRST_MILE_INVOICE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const FIRST_MILE_INVOICE_STATUSES = {
  DUE: 'DUE',
  OVERDUE: 'OVERDUE',
  VERY_OVERDUE: 'VERY_OVERDUE',
  PAID: 'PAID',
};

export const FIRST_MILE_INVOICE_STATUS_COLORS = {
  [FIRST_MILE_INVOICE_STATUSES.DUE]: 'primary',
  [FIRST_MILE_INVOICE_STATUSES.OVERDUE]: 'warning',
  [FIRST_MILE_INVOICE_STATUSES.VERY_OVERDUE]: 'danger',
  [FIRST_MILE_INVOICE_STATUSES.PAID]: 'success',
};

export const BILL_OF_LADING_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const BILL_OF_LADING_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const FIRST_MILE_DOCUMENT_TYPES = {
  HOUSE_BILL_OF_LADING: 'HOUSE_BILL_OF_LADING',
  BILL_OF_LADING: 'BILL_OF_LADING',
  NOTICE_OF_ARRIVAL: 'NOTICE_OF_ARRIVAL',
  PIB: 'PIB',
  QUARANTINE: 'QUARANTINE',
  EBILLING: 'EBILLING',
  SHIPPING_LINE_DELIVERY_ORDER: 'SHIPPING_LINE_DELIVERY_ORDER',
  STORAGE_CHARGES: 'STORAGE_CHARGES',
  TILA: 'TILA',
  SPPB: 'SPPB',
  CONTAINER_RENTAL: 'CONTAINER_RENTAL',
  PACKING_LIST: 'PACKING_LIST',
  MATERIAL_SAFETY_AND_DATA_SHEET: 'MATERIAL_SAFETY_AND_DATA_SHEET',
  CERTIFICATE_OF_ORIGIN: 'CERTIFICATE_OF_ORIGIN',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  LOLO: 'LOLO',
  TAX_INVOICE: 'TAX_INVOICE',
  BILL_OF_LADING_INVOICE: 'BILL_OF_LADING_INVOICE',
};

export const CONTAINER_TYPES = {
  REEFER: 'REEFER',
  DRY: 'DRY',
};

export const FIRST_MILE_CONTAINER_SIZES = {
  '20_FEET': '20_FEET',
  '20_FR': '20_FR',
  '20_GP': '20_GP',
  '20_OT': '20_OT',
  '20_OW': '20_OW',
  '40_FEET': '40_FEET',
  '40_FR': '40_FR',
  '40_GP': '40_GP',
  '40_HC': '40_HC',
  '40_OW': '40_OW',
  '40_OT': '40_OT',
  '45_FEET': '45_FEET',
};

export const FIRST_MILE_COMPANY_CONSTANT_TYPES = {
  PORT: 'PORT',
  SUPPLIER: 'SUPPLIER',
  SHIPPING_LINE: 'SHIPPING_LINE',
  CONSIGNEE: 'CONSIGNEE',
  NOTIFY_PARTY: 'NOTIFY_PARTY',
  TERMINAL: 'TERMINAL',
};

export const FIRST_MILE_COMMODITIES = {
  'Acrylic Staple Fiber': 'Acrylic Staple Fiber',
  'American Raw Cotton': 'American Raw Cotton',
  'Australian Raw Cotton': 'Australian Raw Cotton',
  'Brazillian Raw Cotton': 'Brazillian Raw Cotton',
  Chemicals: 'Chemicals',
  'Greek Raw Cotton': 'Greek Raw Cotton',
  Machinery: 'Machinery',
  'Memphis Raw Cotton': 'Memphis Raw Cotton',
  'Polyester Staple Fibre': 'Polyester Staple Fibre',
  'Spin Finish': 'Spin Finish',
};

export const CUSTOMS_TYPES = {
  BC20: 'BC20',
  BC23: 'BC23',
  BC25: 'BC25',
};

// [End] Fist Mile Project Specific

export const EMPLOYEE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const SHIPMENT_COMPANY_CONSTANT_TYPES = {
  SHIPMENT_TYPE: 'SHIPMENT_TYPE',
  ORDER_TYPE: 'ORDER_TYPE',
};

export const APP_CONTEXT_STORAGE_KEY = 'appContext';

export const MULTIPLE_SHIPMENT_TYPE = {
  MULTIDROP: 'MULTIDROP',
  MULTIPICKUP: 'MULTIPICKUP',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP',
};

export const DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
  ALL: 'all',
};

export const FM_DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE = {
  STUFFING: 'stuffing',
  PORT: 'port',
};

export const CUSTOM_CITY_REGION_MAPPING_METADATA = 'custom_city_region_mapping';
export const CUSTOM_CITY_CONSTANT_NAME = 'CITY';
export const CUSTOM_REGION_CONSTANT_NAME = 'REGION';
export const CUSTOM_LOCATION_GROUP_NAME = 'LOCATION_GROUP';
export const CUSTOM_QUANTITY_TYPE_NAME = 'QUANTITY_TYPE';
export const CUSTOM_PRODUCT_GROUP_NAME = 'PRODUCT_GROUP';
export const CUSTOM_COMPANY_ROLES_METADATA = 'company_roles';
export const PRICE_RATE_TYPE = 'price_rate_type';

export const DANONE_USER_ACCESS_TYPE = {
  DANONE_ADMIN_3PL: 'DANONE_ADMIN_3PL',
  DANONE_LOGISTIC: 'DANONE_LOGISTIC',
  DANONE_PROCUREMENT: 'DANONE_PROCUREMENT',
  WAREHOUSE: 'WAREHOUSE',
};

// List of types for confirmation time on Order Type Management
export const VENDOR_CONFIRMATION_TIME_TYPES = {
  AFTER: 'AFTER',
  AT: 'AT',
  BEFORE: 'BEFORE',
};

export const ORDER_TYPE_CONFIRMATION_TIME_LIMITS = {
  MAX_OLD: 4321, // in minutes
  MAX: 14400, // in minutes
  MAX_IN_DAYS: 10,
  MIN: 0, // in minutes
};

export const EVALUATE_STATUSES = {
  EVALUATE_SHIPMENT: 'EVALUATE_SHIPMENT',
  RE_EVALUATE_SHIPMENT: 'RE_EVALUATE_SHIPMENT',
};

export const EVALUATE_NOTE_TYPES = {
  HANDLING_ISSUE: 'HANDLING_ISSUE',
  QUALITY_ISSUE: 'QUALITY_ISSUE',
  PRODUCT_NEAR_ED: 'PRODUCT_NEAR_ED',
  NO_PO_PO_EXPIRED: 'NO_PO_PO_EXPIRED',
  PO_IS_NOT_ON_SCHEDULE: 'PO_IS_NOT_ON_SCHEDULE',
  OTHER: 'OTHER',
};

export const EVALUATE_RATE_DESC_TYPES = {
  VERY_POOR: 'VERY_POOR',
  POOR: 'POOR',
  STANDARD: 'STANDARD',
  GOOD: 'GOOD',
  VERY_GOOD: 'VERY_GOOD',
};

export const SHIPMENT_FORM_SECTIONS = {
  ORDER_DETAILS_SECTION: 'ORDER_DETAILS_SECTION',
  ROUTE_SECTION: 'ROUTE_SECTION',
  PRODUCT_DETAIL_SECTION: 'PRODUCT_DETAIL_SECTION',
  ASSIGN_VENDOR_SECTION: 'ASSIGN_VENDOR_SECTION',
  SUMMARY_SECTION: 'SUMMARY_SECTION',
};

export const MAXIMUM_EVALUATE_VALUE = 5;

export const TRUCK_RATING_TYPES = {
  CONDITION: 'CONDITION',
  CLEANLINESS: 'CLEANLINESS',
  COMPLETENESS: 'COMPLETENESS',
};

export const TRUCK_RATING_DEFAULT_VALUE = {
  CONDITION: 40,
  CLEANLINESS: 30,
  COMPLETENESS: 30,
  TOTAL: 100,
};
/**
 * @deprecated
 *
 * No more usage for S_TMS_TRACKER_EVENTS.
 * For STMS, please use TRACKER_EVENTS from src/Configurations/constants.js.
 * Remove S_TMS_TRACKER_EVENTS when all the legacy codes migrated to the new constant.
 */
export const S_TMS_TRACKER_EVENTS = {
  BULK_DO_CLICKED: 'Bulk DO clicked',
  BULK_DO_SUBMITTED: 'Bulk DO submitted',
  CHOOSE_COMPANY: 'Choose company',
  DO_DETAIL_ASSIGN: 'DO detail assign',
  DO_LIST_ASSIGN: 'DO list assign',
  DO_LIST_EVALUATE: 'DO list evaluate',
  DO_LIST_EXPANDED: 'DO list expanded',
  DO_LIST_REASSIGN: 'DO list reassign',
  DOWNLOAD_INSIGHTS_CSV: 'Download insights CSV',
  INSIGHTS_FILTER_CHANGED: 'Insight filter changed',
  INSIGHTS_CHART_TARGET_CHANGED: 'Insight chart target changed',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  MAP_MARKER_SELECTED: 'Map marker selected',
  MAP_SEARCH_KEYWORD_APPLIED: 'Map searchKeyword filter applied',
  MAP_SHIPMENT_DIRECTION_APPLIED: 'Map shipment direction filter applied',
  MAP_STATUS_APPLIED: 'Map status filter applied',
  MAP_WAREHOUSE_APPLIED: 'Map warehouse filter applied',
  NAVIGATION_CHANGED: 'Navigation changed',
  ONSITE_NOTIF_OPENED: 'Onsite notification opened',
  SINGLE_DO_CLICKED: 'Single DO clicked',
  SINGLE_DO_SUBMITTED: 'Single DO Submitted',
};

export const TON_TO_KG_MULTIPLIER = 1000;
export const EVALUATION_RATING = {
  DEFAULT_LOAD_RATING: 40,
  DEFAULT_TIME_RATING: 40,
  DEFAULT_TRUCK_RATING: 20,
  TIME_RATING_TOOLTIP: `
  Time Rating Classification

  Rate 5:in Time
  Rate 4:Delay < 2 Hours
  Rate 3:Delay 2-4 Hours
  Rate 2:Delay 4-6 Hours
  Rate 1:Delay > 6 Hours`,
  LOAD_RATING_TOOLTIP: `
  Load Rating Classification

  Rate 5: ≥ 97% Delivered Goods
  Rate 4: 92%-97% Delivered Goods
  Rate 3: 82%-92% Delivered Goods
  Rate 2: 70%-82% Delivered Goods
  Rate 1: <70% Delivered Goods`,
};

export const FORM_CONFIRMATION_TYPE = {
  ROUTE_PUSH: 'push',
  ROUTE_BACK: 'back',
};

export const QUEUEING_OVERTIME_REQUIRED_STATUSES = [
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
];

export const USER_PLATFORM = {
  DRIVER: 'Driver',
  INTERNAL: 'Internal',
  QRCODE: 'QRCode',
  SHIPPER: 'Shipper',
  TRANSPORTER: 'Vendor',
};

export const INVOICE_STATUS = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PAID: 'PAID',
  CREATED: 'CREATED',
};

export const INVOICE_STATUS_COLOR_SCHEME = {
  [INVOICE_STATUS.APPROVED]: 'primary',
  [INVOICE_STATUS.PAID]: 'success',
  [INVOICE_STATUS.PAYMENT_PENDING]: 'primary',
  [INVOICE_STATUS.CREATED]: 'primary',
  [INVOICE_STATUS.REJECTED]: 'danger',
};

// CONSTANT FOR BULK UPLOAD TEMPLATE FILENAME
export const BULK_DO_TEMPLATE = {
  SS: 'enterprise-delivery-orders.xlsx',
  MM: 'enterprise-delivery-orders-multiDP.xlsx',
  SS_VARIANT: 'enterprise-delivery-orders-with-variant.xlsx',
  MM_VARIANT: 'enterprise-delivery-orders-multiDP-with-variant.xlsx',
  SS_SEAL: 'enterprise-delivery-orders-with-seal-no.xlsx',
  MM_SEAL: 'enterprise-delivery-orders-multiDP-with-seal-no.xlsx',
  SS_SERIAL: 'enterprise-delivery-orders-with-serial-no.xlsx',
  MM_SERIAL: 'enterprise-delivery-orders-multiDP-with-serial-no.xlsx',
  SS_SEAL_SERIAL: 'enterprise-delivery-orders-with-combination.xlsx',
  MM_SEAL_SERIAL: 'enterprise-delivery-orders-multiDP-with-combination.xlsx',
};

/**
 * Shipment Types constant
 * Only used for BTMS Shipper for NFS x BTMS integration
 * Do not sort this constant
 */
export const BTMS_SHIPMENT_TYPE = {
  MT: 'MT',
  GT: 'GT',
  W2W: 'W2W',
  F2DEPO: 'F2DEPO',
  F2DIST: 'F2DIST',
  F2MT: 'F2MT',
  F2W: 'F2W',
  W2DEPO: 'W2DEPO',
  W2DIST: 'W2DIST',
  W2MT: 'W2MT',
  DOOR_TO_DOOR: 'DOOR_TO_DOOR',
  MULTI_DOOR_TO_DOOR: 'MULTI_DOOR_TO_DOOR',
  INBOUND_DTD: 'INBOUND_DTD',
  SHIPPING_LINE_DTD: 'SHIPPING_LINE_DTD',
  OUTBOUND_DTD: 'OUTBOUND_DTD',
  MULTIDROP: 'MULTIDROP',
  MULTIPICKUP: 'MULTIPICKUP',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP',
};

export const PRODUCT_CATEGORY = {
  ALCOHOLIC_BEVARAGES: 'ALCOHOLIC_BEVARAGES',
  DRINKING_WATER: 'DRINKING_WATER',
  COFFEE: 'COFFEE',
  FRUIT_FLAVORED_DRINKS: 'FRUIT_FLAVORED_DRINKS',
  JUICE: 'JUICE',
  MILK: 'MILK',
  NON_DAIRY: 'NON_DAIRY',
  POWDERED_BEVERAGES_MIXES: 'POWDERED_BEVERAGES_MIXES',
  SODA: 'SODA',
  SPORTS_ENERGY_DRINKS: 'SPORTS_ENERGY_DRINKS',
  TEA_INFUSIONS: 'TEA_INFUSIONS',
  WATER: 'WATER',
  BAKERY: 'BAKERY',
  SNAKES: 'SNAKES',
  CONDIMENTS_SOUCES: 'CONDIMENTS_SOUCES',
  COOKING_BAKING_INGREDIENTS: 'COOKING_BAKING_INGREDIENTS',
  DAIRY: 'DAIRY',
  DIPS: 'DIPS',
  FOOD: 'FOOD',
  FROZEN: 'FROZEN',
  FRUITS: 'FRUITS',
  GRAINS: 'GRAINS',
  MEAT: 'MEAT',
  NUTS: 'NUTS',
  PASTA: 'PASTA',
  PREPARED: 'PREPARED',
  SPICES: 'SPICES',
  SNACKS: 'SNACKS',
  SOUPS: 'SOUPS',
  TOUFU: 'TOUFU',
  FITNESS_AND_NUTRITION: 'FITNESS_AND_NUTRITION',
  MEDICINE_AND_DRUGS: 'MEDICINE_AND_DRUGS',
  FMCG: 'FMCG',
  PLASTIC: 'PLASTIC',
  PACKAGING: 'PACKAGING',
};

export const PT_PANTHERA_BIRU_INDONESIA = 'PT Panthera Biru Indonesia';

export const WHATSAPP_LINK = (phone = '', message = '') => `https://api.whatsapp.com/send/?
phone=${phone}&text=${message}`;

export const WHATSAPP_UPSELLING = {
  phone: window.WA_PHONE_KARGO_NEXUS || '6281119058080',
  message: `Hi Kargo Nexus team, 
  I'm interested in the Kargo Nexus free trial. Kindly provide more information about the services and features.`,
};

export const WHATSAPP_FREE_TRIAL_EXPIRED = {
  phone: window.WA_PHONE_KARGO_NEXUS || '6281119058080',
  message: 'Hi Kargo Nexus team, I would like to continue my access by upgrading the account. Can we schedule a demo?',
};

export const JOB_REFERENCE_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  BIDDING: 'BIDDING',
  BID_WON: 'BID_WON',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  REJECTED_BY_BIDDING: 'REJECTED_BY_BIDDING',
  OPEN_FOR_BIDDING: 'OPEN_FOR_BIDDING',
  REJECTED_BY_SHIPPER: 'REJECTED_BY_SHIPPER',
  REJECTED_BY_PBI: 'REJECTED_BY_PBI',
};

export const INDONESIA_TIMEZONE = {
  '+0700': 'WIB',
  '+0800': 'WITA',
  '+0900': 'WIT'
};

export const KARGO_EMAIL_SUFFIX = '@kargo.tech';

export const DEFAULT_DERIVED_STATUSES = [
  DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE,
  DERIVED_SHIPMENT_STATUSES.ON_TIME,
  DERIVED_SHIPMENT_STATUSES.ISSUES,
];

export const FIRST_MILE_TRACKER_STATUS = {
  PICKUP_EMPTY_CONTAINER: 'PICKUP_EMPTY_CONTAINER',
  STUFFING: 'STUFFING',
  ONGOING_TO_PORT_OF_LOADING: 'ONGOING_TO_PORT_OF_LOADING',
  AT_PORT_OF_LOADING: 'AT_PORT_OF_LOADING',
};

/* Date picker default value */
const today = moment()
  .utc()
  .hours(16)
  .minutes(0)
  .seconds(0)
  .milliseconds(0);
const oneMonthAgo = today
  .clone()
  .subtract(1, 'month')
  .hours(17)
  .minutes(0)
  .seconds(0)
  .milliseconds(0);
export const LAST_MONTH_DATE = {
  start: oneMonthAgo.toISOString(),
  end: today.toISOString(),
};

export default {};
