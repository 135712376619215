/* eslint-disable max-len */

import {
  CONTAINER_STATUSES,
  FIRST_MILE_CONTAINER_SIZES,
  FIRST_MILE_DOCUMENT_TYPES,
  FIRST_MILE_INVOICE_STATUSES,
} from '~/Configurations/constants';

export default {
  bill_of_lading: 'Bill of Lading',
  bill_of_lading_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.BILL_OF_LADING]: 'Bill of Lading',
    [FIRST_MILE_DOCUMENT_TYPES.CERTIFICATE_OF_ORIGIN]: 'Certificate of Origin',
    [FIRST_MILE_DOCUMENT_TYPES.EBILLING]: 'E-Billing',
    [FIRST_MILE_DOCUMENT_TYPES.HOUSE_BILL_OF_LADING]: 'House Bill of Lading',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]: 'Material Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.NOTICE_OF_ARRIVAL]: 'NOA',
    [FIRST_MILE_DOCUMENT_TYPES.PIB]: 'PIB',
    [FIRST_MILE_DOCUMENT_TYPES.QUARANTINE]: 'Quarantine',
    [FIRST_MILE_DOCUMENT_TYPES.SHIPPING_LINE_DELIVERY_ORDER]: 'Shipping Line Delivery Order',
    [FIRST_MILE_DOCUMENT_TYPES.STORAGE_CHARGES]: 'Storage Charges',
  },
  bill_of_lading_number: '$t(first_mile:bill_of_lading) Number',
  bill_of_lading_summary: 'Bill of Lading Summary',
  bulk_edit: 'Bulk Edit',
  choose_container_if_bl: 'You must choose container if you have selected Bill of Lading',
  container_all: 'All containers',
  container_arrival_eta: 'Store Arrival',
  container_attachments: 'Container Attachments',
  container_detail: 'Container Details',
  container_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.CERTIFICATE_OF_ORIGIN]: 'Certificate of Origin',
    [FIRST_MILE_DOCUMENT_TYPES.CONTAINER_RENTAL]: 'Container Rental',
    [FIRST_MILE_DOCUMENT_TYPES.LOLO]: 'LOLO',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]: 'Material Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.PACKING_LIST]: 'Packing List',
    [FIRST_MILE_DOCUMENT_TYPES.PROOF_OF_DELIVERY]: 'Surat Jalan',
    [FIRST_MILE_DOCUMENT_TYPES.TILA]: 'TILA',
  },
  container_holding_area: 'Holding Area',
  container_in_holding: 'In Holding',
  container_number: 'Container Number',
  container_return_depo: 'Return Depo',
  container_sizes: {
    [FIRST_MILE_CONTAINER_SIZES['20_FEET']]: '20"',
    [FIRST_MILE_CONTAINER_SIZES['20_FR']]: '20" FR',
    [FIRST_MILE_CONTAINER_SIZES['20_GP']]: '20" GP',
    [FIRST_MILE_CONTAINER_SIZES['20_OT']]: '20" OT',
    [FIRST_MILE_CONTAINER_SIZES['20_OW']]: '20" OW',
    [FIRST_MILE_CONTAINER_SIZES['40_FEET']]: '40"',
    [FIRST_MILE_CONTAINER_SIZES['40_FR']]: '40" FR',
    [FIRST_MILE_CONTAINER_SIZES['40_GP']]: '40" GP',
    [FIRST_MILE_CONTAINER_SIZES['40_HC']]: '40" HC',
    [FIRST_MILE_CONTAINER_SIZES['40_OT']]: '40" OT',
    [FIRST_MILE_CONTAINER_SIZES['40_OW']]: '40" OW',
    [FIRST_MILE_CONTAINER_SIZES['45_FEET']]: '45"',
  },
  container_status: {
    [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'Arrived at Port',
    [CONTAINER_STATUSES.INVOICED]: 'Invoiced',
    [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'Moved to holding',
    [CONTAINER_STATUSES.NO_NOA]: 'No NOA',
    [CONTAINER_STATUSES.NOA]: 'NOA',
    [CONTAINER_STATUSES.ON_SHIPMENT]: 'On Shipment',
    [CONTAINER_STATUSES.PAID]: 'Paid',
    [CONTAINER_STATUSES.PAYMENT_PENDING]: 'Pending',
    [CONTAINER_STATUSES.PIB]: 'PIB',
    [CONTAINER_STATUSES.QUARANTINED]: 'Quarantined',
    [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'Complete',
    [CONTAINER_STATUSES.SPPB]: 'SPPB',
    [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'Waiting for Original Doc',
    [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'Waiting for SPPB',
  },
  container_type: 'Container Type',
  container_unloading_capacity: 'Daily Unloading Capacity',
  container_use_holding: 'Use Holding',
  container_using_holding: 'Using Holding',
  container_weight: 'Weight',
  create_batch_container_for: 'Add containers for {{name}}',
  dashboard: 'Dashboard',
  date_placed_in_holding: 'Date Placed in Holding',
  delivery_order_attachments: 'Delivery Order Attachments',
  download_report: 'Download Report',
  invoice_status: {
    [FIRST_MILE_INVOICE_STATUSES.DUE]: 'Due',
    [FIRST_MILE_INVOICE_STATUSES.OVERDUE]: 'Overdue',
    [FIRST_MILE_INVOICE_STATUSES.PAID]: 'Paid',
    [FIRST_MILE_INVOICE_STATUSES.VERY_OVERDUE]: 'Very Overdue',
  },
  location_detail: 'Location Details',
  number_of_containers: 'Number of Containers',
  other_documents: 'Other Documents',
  port_address: 'Port Address',
  port_detail: 'Port Details',
  reports: 'Reports',
  review_batch_container_for: 'Review containers for {{name}}',
  schedule_report: 'Schedule Report',
  seal_number: 'Seal Number',
  store_arrival_date: 'Store Arrival Date',
  store_arrival_eta: 'Store Arrival',
  store_holding: 'Use Holding',
  terminal: 'Terminal',
  volume_report: 'Volume Report'
};
