/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function Warehouse() {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8415 5.59168L14.4832 3.23335C14.1748 2.92502 13.7498 2.75002 13.3082 2.75002H6.49984V0.750017C6.49984 0.291683 6.12484 0.400017 5.6665 0.400017C5.20817 0.400017 4.83317 0.291684 4.83317 0.750017V2.75002H4.6915C4.24984 2.75002 3.82484 2.92502 3.50817 3.24168L1.15817 5.59168C0.841504 5.90835 0.666504 6.33335 0.666504 6.77502V11.9167C0.666504 12.8334 1.4165 13.5834 2.33317 13.5834H15.6665C16.5832 13.5834 17.3332 12.8334 17.3332 11.9167V6.77502C17.3332 6.33335 17.1582 5.90835 16.8415 5.59168ZM10.6665 11.9167H2.33317V7.75002H10.6665V11.9167ZM15.6665 11.9167H12.3332V6.77502L13.9998 5.10835L15.6665 6.77502V11.9167Z" fill="currentColor" />
    </svg>
  );
}
