/* eslint-disable max-len */

import { STMS_DTD_TRUCKING_STATUS } from '../constants';
import { SHIPMENT_SEARCH_TYPES } from '../FirstMileOrderVehicleMonitoringPage/constants';

export default {
  first_mile_status_log_content_with_location: 'Status changed to {{newStatus}} at {{location}} by <strong>{{actor}}</strong>',
  first_mile_status_log_content: 'Status changed to {{newStatus}} by <strong>{{actor}}</strong>',
  shipment_search_types: {
    [SHIPMENT_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate',
    [SHIPMENT_SEARCH_TYPES.ORDER_NUMBER]: 'Order no',
    [SHIPMENT_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container',
  },
  shipment_dtd_trucking_monitoring_status: {
    [STMS_DTD_TRUCKING_STATUS.PICKUP_EMPTY_CONTAINER]: 'Pickup Empty',
    [STMS_DTD_TRUCKING_STATUS.STUFFING]: 'Stuffing',
    [STMS_DTD_TRUCKING_STATUS.AT_PORT_OF_LOADING]: 'at Port of Loading',
    [STMS_DTD_TRUCKING_STATUS.ONGOING_TO_PORT_OF_LOADING]: 'Ongoing to Port',
  },
  data_not_found: 'Data is not Found',
  possible_reasons: 'Possible Reasons:',
  empty_tracking_reason: {
    not_started: 'Shipment has not been started',
    next_stage: 'Container is already on the shipping line or dooring agent',
    not_exist: 'The search you insert does not exist'
  }
};
