export const MAP_LEFT_SIDEBAR_MODE = {
  TRUCK_LIST_TOGGLE: 'TRUCK_LIST_TOGGLE',
  TRUCK_LIST: 'TRUCK_LIST',
  TRUCK_DETAILS: 'TRUCK_DETAILS'
};

export const SHIPMENT_SEARCH_TYPES = {
  CONTAINER_NUMBER: 'CONTAINER_NUMBER',
  ORDER_NUMBER: 'ORDER_NUMBER',
  LICENSE_PLATE: 'LICENSE_PLATE',
};

export const UPDATE_SHIPMENT_ALERT_SOURCES = {
  DA: 'DA',
  TTMS: 'TTMS',
};
