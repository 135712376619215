import Loadable from 'react-loadable';
import { isMobile } from '@kargotech/tms-common/Utilities/user-agent';
import { PageSuspenseFallback } from '@kargotech/tms-ui/components';

const routes = isMobile()
  ? import(/* webpackChunkName: "MobileRoutes" */ './Mobile')
  : import(/* webpackChunkName: "DesktopRoutes" */ './Desktop');

export default Loadable({
  loader: () => routes,
  loading: PageSuspenseFallback,
});
