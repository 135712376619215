import React, { useCallback, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TmsUIProvider } from '@kargotech/tms-ui';
import { isMobile } from '@kargotech/tms-common/Utilities/user-agent';
import { AuthProvider } from '@kargotech/tms-core/auth';
import FlagProvider from '@unleash/proxy-client-react';

import { APP_CONTEXT_STORAGE_KEY, TRACKER_EVENTS } from '~/Configurations/constants';
import theme from './Configurations/theme';
import Routes from './Configurations/Routes';
import UNLEASH_CONFIGS from '~/Configurations/unleash';
import AppProvider from './Contexts/AppProvider';
import ApolloClientProvider from './Contexts/ApolloClientProvider';
import ProfileProvider from './Contexts/ProfileProvider';
import NavigationProvider from './Contexts/NavigationProvider';
import { APOLLO_CLIENTS } from './Services/apollo';
import { trackerEvent } from '~/Services/tracker';
import { getFieldSession } from '~/Utilities/sessionDetail';

require('dotenv').config();

export function UIWrapper({ children }) {
  const { i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <TmsUIProvider i18n={i18n} isMobile={isMobile()}>
        {children}
      </TmsUIProvider>
    </ThemeProvider>
  );
}

export function AppWrapper({ children }) {
  const trackerEventWithEmployee = async action => {
    const userKsuid = await getFieldSession(`custom:ksuid`);
    trackerEvent({
      action,
      employee_ksuid: userKsuid,
    });
  };

  const handleLogout = useCallback(() => {
    trackerEventWithEmployee(TRACKER_EVENTS.LOGOUT_SUCCESS);
    Object.keys(APOLLO_CLIENTS).forEach(apolloClient => {
      const cache = get(APOLLO_CLIENTS, `${apolloClient}.cache`);
      if (cache) {
        cache.reset();
      }
    });
  }, []);

  const commonAuthTrackerEvents = useMemo(() => ({
    onSelectCompanySuccess() {
      trackerEvent({ category: 'Company', action: TRACKER_EVENTS.CHOOSE_COMPANY_SUCCESS });
    },
    onLoginSuccess() {
      trackerEventWithEmployee(TRACKER_EVENTS.LOGIN_SUCCESS);
    }
  }), []);

  return (
    <UIWrapper>
      <AppProvider>
        <AuthProvider
          apiClient={APOLLO_CLIENTS.PROFILE}
          onLogoutSuccess={handleLogout}
          preservedLocalStorageKeys={[APP_CONTEXT_STORAGE_KEY]}
          {...commonAuthTrackerEvents}
        >
          <ApolloClientProvider>
            <FlagProvider config={UNLEASH_CONFIGS}>
              <ProfileProvider>
                <NavigationProvider>
                  {children}
                </NavigationProvider>
              </ProfileProvider>
            </FlagProvider>
          </ApolloClientProvider>
        </AuthProvider>
      </AppProvider>
    </UIWrapper>
  );
}

export default function App() {
  return (
    <AppWrapper>
      <Router>
        <Routes />
      </Router>
    </AppWrapper>
  );
}
