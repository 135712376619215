import shipmentTranslations from '~/Pages/Shipment/Translations';
import insightTranslations from '~/Pages/Insights/Translations';
import vehicleTranslations from '~/Pages/Vehicle/Translations';

import common from './en_common';
import compose from './en_compose';
import firstMile from './en_first_mile';
import message from './en_message';

export default {
  common,
  compose,
  first_mile: firstMile,
  insight: insightTranslations.en,
  message,
  shipment: shipmentTranslations.en,
  vehicle: vehicleTranslations.en,
};
