/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function VendorConfirmed() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.89543 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.89543 11.1046 0 10 0H2ZM2 2L10 2V10H2V2ZM3.75 3.5H3V5H3.75H8.25H9V3.5H8.25H3.75ZM3 6.5H3.75H7.25H8V8H7.25H3.75H3V6.5ZM13.75 2.5H12.75V4.5H13.75H14V14H4.5V13.75V12.75H2.5V13.75V14C2.5 15.1046 3.39543 16 4.5 16H14C15.1046 16 16 15.1046 16 14V4.5C16 3.39543 15.1046 2.5 14 2.5H13.75Z" fill="currentColor" />
    </svg>
  );
}
