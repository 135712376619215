/* eslint-disable camelcase */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { get } from 'lodash-es';
import moment from 'moment';
import { AuthContext } from '@kargotech/tms-core/auth';

const aDay = 86400;
const sixDays = aDay * 6;

const useExpiredFreeTrialHook = getCompanyMetadata => {
  const { logout } = useContext(AuthContext);
  const [closeExpiredBanner, setCloseExpiredBanner] = useState(localStorage.getItem('closeExpiredBanner'));

  const getNfsUpsellingFreeTrialMetadata = useCallback(() => {
    const { nfs_upselling_free_trial } = getCompanyMetadata() || {};
    if (!nfs_upselling_free_trial || typeof nfs_upselling_free_trial !== 'string') {
      return undefined;
    }
    const freeTrialData = JSON.parse(nfs_upselling_free_trial);

    try {
      return {
        isActive: freeTrialData.is_active,
        activationDate: freeTrialData.activation_date,
        expiredDate: freeTrialData.expired_date,
      };
    } catch {
      return undefined;
    }
  }, [getCompanyMetadata]);

  const expiredDate = useMemo(() => get(getNfsUpsellingFreeTrialMetadata(), 'expiredDate', undefined), [
    getNfsUpsellingFreeTrialMetadata,
  ]);

  const isExpiredFreeTrial = useMemo(() => expiredDate
      && moment().unix() >= moment(expiredDate).unix(), [expiredDate]);

  const isShowExpiredBanner = useMemo(() => (expiredDate
      && (!closeExpiredBanner || moment().day() > moment(closeExpiredBanner).day())
      && moment(expiredDate).unix() - moment().unix() > 0
      && moment(expiredDate).unix() - moment().unix() < sixDays), [closeExpiredBanner, expiredDate]);

  useEffect(() => {
    if (closeExpiredBanner && (isShowExpiredBanner || isExpiredFreeTrial)) {
      localStorage.removeItem('closeExpiredBanner');
    }
  }, [closeExpiredBanner, isExpiredFreeTrial, isShowExpiredBanner]);

  const getExpiredBannerCopy = useCallback(() => {
    try {
      if (
        expiredDate
        && moment(expiredDate).unix() - moment().unix() > 0
        && moment(expiredDate).unix() - moment().unix() < aDay
      ) {
        return 'Tonight';
      }
      if (
        expiredDate
        && moment(expiredDate).unix() - moment().unix() > 0
        && moment(expiredDate).unix() - moment().unix() < sixDays
      ) {
        return `on ${moment(expiredDate).format('DD MMM YYYY')}`;
      }
      return '';
    } catch (_) {
      return '';
    }
  }, [expiredDate]);

  useEffect(() => {
    if (isExpiredFreeTrial) logout();
  }, [isExpiredFreeTrial, logout]);

  return {
    getExpiredBannerCopy,
    getNfsUpsellingFreeTrialMetadata,
    isExpiredFreeTrial,
    isShowExpiredBanner,
    setCloseExpiredBanner,
  };
};

export default useExpiredFreeTrialHook;
