/* eslint-disable max-len */
import { PURCHASE_ORDER_STATUS, PURCHASE_ORDER_TYPE } from '~/Configurations/constants';
import {
  DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE,
  DO_CREATION_TYPE,
  FIRST_MILE_SHIPMENT_TYPE,
  FIRST_MILE_TRUCKING_SHIPMENT_TYPE,
  ROUTE_TYPE,
  ROUTE_TYPES_DTD,
} from '../constants';
import { DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME, STMS_AGGREGATED_STATUS } from '../DoorToDoorDetailPage/constants';
import { DTD_CANCEL_REASON } from '../PurchaseOrderPage/constant';
import { SHIPMENT_SEARCH_TYPES } from '../Utilities/constants';

export default {
  additional_description: 'Additional Description',
  awaiting_vendors_confirmation: `Awaiting Vendor's Confirmation`,
  booking_number: 'Booking Number',
  bulk_upload_download_instructions_1: 'Use our <strong>latest template</strong>.',
  bulk_upload_download_instructions_2: '<strong>Don\'t edit</strong> the Column Header.',
  bulk_upload_download_instructions_3: 'Fill all Required Field.',
  cancel_order: 'Cancel Order',
  cancel_reasons_purchase_order: {
    [DTD_CANCEL_REASON.FORCE_MAJEURE]: 'Force Majeure',
    [DTD_CANCEL_REASON.CHANGE_ROUTE]: 'Change Route',
    [DTD_CANCEL_REASON.CHANGE_SCHEDULE]: 'Change Schedule',
    [DTD_CANCEL_REASON.OTHER]: 'Other'
  },
  cancellation_reason: 'Cancellation Reason',
  changed_in_x: 'Changed in {{x}}',
  choose_route_type: 'Choose Route Type',
  choose_your_preferable_schedule: 'Choose your preferable schedule',
  close_date: 'Close Date',
  closing_date: 'Closing Date',
  complete_route: 'Complete Route',
  container_details: 'Container Details',
  container_type: 'Container Type',
  count_order_not_recognized: 'There are {{count}} order are not recognized.',
  count_type_order_created: '{{count}} {{type}} Order Created.',
  created_date: 'Created Date',
  creating_order_on_file: 'Creating order on {{fileName}}...',
  custom_items_created: '{{count}} {{item}} Created',
  destination_wh: 'Destination WH',
  do_confirmed_by_vendor: 'DO Confirmed by Vendor',
  do_rejected: 'DO Rejected',
  do_creation_type: {
    [DO_CREATION_TYPE.D2D]: 'Door to Door',
    [DO_CREATION_TYPE.MM]: 'Mid Mile Trucking'
  },
  door_to_door_activity_log_content: {
    // pre-shipment
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_dtd_shipment_created_from_btms]: 'Shipment created by <strong>PT Panthera Biru Indonesia</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_dtd_shipment_created]: 'Shipment created by <strong>{{actor}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_purchase_order_confirmed]: 'Shipment confirmed by Vendor.<br />Vessel voyage will be booked based on your preferable schedule.',
    // DO 1
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_assign_planned_trucking_shipment_from_btms]: 'Trucking assigned to <strong>{{licensePlate}}, {{driverName}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_arrive_at_empty_depo_from_btms]: 'Truck has arrived at <strong>{{location}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_arrive_at_pickup_from_btms]: 'Truck has arrived at <strong>{{location}}</strong> for stuffing',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_gate_out_depo_from_btms]: `
      Container has been picked up with following details<br />
      Container Number: <strong>{{containerNumber}}</strong><br />
      Seal Number: <strong>{{sealNumber}}</strong>
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_gate_in_store_from_btms]: 'Truck has arrived at {{location}} for stuffing',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_gate_out_store_from_btms]: 'Stuffing completed at <strong>{{currentLocation}}</strong>. Truck is en route to <strong>{{nextLocation}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_gate_in_cy_from_btms]: 'Truck has arrived at <strong>{{location}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_completed_from_btms]: 'Container has been dropped off at <strong>{{portOfLoading}}</strong>, awaiting loading onto <strong>{{vesselVoyage}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_to_completed_no_spl_from_btms]: 'Container has been dropped off at <strong>{{portOfLoading}}</strong>, awaiting to be booked to another shipping line',
    // DO 2
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_dtd_shipping_line_created_from_btms]: `
      Vessel Voyage has been booked, your container slot is secured.<br />
      ETD: <strong>{{etd}}</strong><br />
      ETA: <strong>{{eta}}</strong>
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_shipping_line_departure_from_btms]: 'Vessel Voyage departed from <strong>{{port}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_shipping_line_arrival_from_btms]: 'Vessel Voyage has arrived at <strong>{{port}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_shipping_line_shipment_complete_from_btms]: 'Container has been lifted off vessel voyage, currently awaiting pick up by Consignee.',
    // DO 3
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_planned_dooring_date]: 'Container is scheduled for delivery to your destination warehouse on <strong>{{date}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_assign_planned_dooring_shipment_from_btms]: 'Dooring assigned to <strong>{{licensePlate}}</strong>, <strong>{{driverName}}</strong>. Container is currently en route to <strong>{{warehouse}}</strong>',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_dooring_at_destination_from_btms]: 'Container arrived at <strong>{{destination}}</strong> for unloading',
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_dooring_completed_from_btms]: 'Container finished unloading. Shipment successfully completed.',
    // cancel reject PO
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_cancel_purchase_order]: `
      Shipment has been cancelled by {{actor}}<br />
      Reason {{reason}}<br />
      {{description}}
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_reject_po_from_btms]: `
      Shipment Rejected by {{actor}}<br />
      Reason {{reason}}<br />
      {{description}}
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_cancel_dtd_containers_from_po_from_btms]: `
      Panthera Biru Indonesia has cancelled your order. Please contact our sales representative for further assistance.
    `,
    // handle reassignment
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_reallocate_trucking_do_from_btms]: `
      Trucking updated, reassigned to <strong>{{licensePlate}}</strong>, <strong>{{driverName}}</strong>
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_reallocate_dooring_do_from_btms]: `
      Dooring updated, reassigned to <strong>{{licensePlate}}</strong>, <strong>{{driverName}}</strong>
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_approve_reassignment_from_btms]: `
      Trucking updated, reassigned to <strong>{{licensePlate}}</strong>, <strong>{{driverName}}</strong>
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_update_vessel_voyage_from_btms]: `
      Vessel Voyage schedule has been updated.<br />
      ETD: <strong>{{etd}}</strong><br />
      ETA: <strong>{{eta}}</strong>
    `,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_edit_route_change_destination_location]: `Destination warehouse has been updated to <strong>{{warehouse}}</strong>`,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_edit_route_change_planned_pickup_date]: `Planned stuffing for <strong>{{warehouse}}</strong> has been updated to <strong>{{plannedDate}}</strong>`,
    [DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME.nfs_edit_route_change_stuffing_location]: `Stuffing warehouse has been updated from <strong>{{oldWarehouse}}</strong> to <strong>{{newWarehouse}}</strong>`
  },
  dropoff: 'Dropoff',
  dtd_container_details_document_type: {
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.DTD_BILL_OF_LADING]: 'Bill of Lading',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.DTD_RECEIVING_CARD]: 'Receiving Card',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.DTD_RECEIVING_ORDER]: 'EIR',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.LOADING_INSTRUCTION]: 'Surat Perintah Muat',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.OCEAN_FREIGHT]: 'Invoice Shipping Line',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.PROOF_OF_DELIVERY]: 'Surat Jalan',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.POL]: 'Proof of Loading Store {{sequence}}',
    [DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE.RELEASE_ORDER]: 'Release Order'
  },
  dtd_surat_jalan_shipment_type: {
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.TRUCKING_DTD]: 'Outbound Domestik',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_OUTBOUND]: 'Outbound Domestik',
    [FIRST_MILE_TRUCKING_SHIPMENT_TYPE.FM_EXPORT]: 'Ekspor'
  },
  due_unrecognized_reason: 'Due unrecognized template uploaded. Please Consider this couple of reasons:',
  empty_depo: 'Empty Depo',
  empty_required_shipment: 'Empty Required Field in a Shipment list,',
  eta: 'ETA',
  eta_destination: 'ETA Destination',
  etd: 'ETD',
  etd_origin: 'ETD Origin',
  excel_template: 'Excel Template',
  first_mile_shipment_type: {
    [FIRST_MILE_SHIPMENT_TYPE.DOOR_TO_DOOR]: 'Door To Door',
    [FIRST_MILE_SHIPMENT_TYPE.MULTI_DOOR_TO_DOOR]: 'Multi Door To Door',
    [FIRST_MILE_SHIPMENT_TYPE.INBOUND_DTD]: 'Inbound DTD',
    [FIRST_MILE_SHIPMENT_TYPE.TRUCKING_DTD]: 'Trucking DTD',
    [FIRST_MILE_SHIPMENT_TYPE.SHIPPING_LINE_DTD]: 'Shipping Line DTD',
    [FIRST_MILE_SHIPMENT_TYPE.DOORING_AGENT_DTD]: 'Dooring DTD',
    [FIRST_MILE_SHIPMENT_TYPE.OUTBOUND_DTD]: 'Outbound DTD',
    [FIRST_MILE_SHIPMENT_TYPE.FIRST_MILE_TRUCKING]: 'First Mile Trucking'
  },
  for_accurate_unit_position: 'For accurate unit position',
  getting_started_with_bulk_order: 'Getting Started with Bulk Order?',
  getting_started_with_bulk_order_instructions_1: '<strong>Choose Route:</strong> Single / Multi Pickup or Dropoff.',
  getting_started_with_bulk_order_instructions_2: '<strong>Download template:</strong> then fill your order on the template.',
  getting_started_with_bulk_order_instructions_3: '<strong>Upload:</strong> Use .xlsx or .csv files.',
  getting_started_with_bulk_order_instructions_4: '<strong>Don’t edit Column Headers:</strong> should be match with latest template.',
  getting_started_with_bulk_order_instructions_5: '<strong>Error Handling:</strong> Download, review, and re-upload if needed.',
  header_should_match: 'Header column of the <strong>tables should match</strong> with your latest template.',
  items_from_file_are_recognized: '{{count}} Shipment list that has been defined from the {{fileName}} are successfully recognized.',
  loading_date_and_time: 'Loading Date and Time',
  missmatch_naming: 'Miss-match naming on the sheets of the table.',
  no_data_provided: 'No data provided on the sheets.',
  no_document_upload_yet: 'No document uploaded yet.',
  not_registered_location: 'Not registered Location / Truck type',
  on_shipment: 'On Shipment',
  open_stack: 'Open Stack',
  order_cancellation_confirmation: 'Order Cancellation Confirmation',
  order_route_type: {
    [ROUTE_TYPE.SINGLE_PICKUP]: 'Single Pickup / Dropoff Order',
    [ROUTE_TYPE.MULTIPLE_PICKUP]: 'Multi Pickup & Dropoff Order'
  },
  pickup: 'Pickup',
  planned_loading: 'Planned Loading',
  please_consider_these_when_fill_the_list: 'Please consider these when fill the list',
  po_creation_date_and_time: 'PO Creation Date and Time',
  po_confirmed_by_vendor: 'PO Confirmed by Vendor',
  po_rejected: 'PO Rejected',
  po_number: 'PO Number',
  po_types: {
    [PURCHASE_ORDER_TYPE.DTD]: 'Door to Door'
  },
  po_x: 'PO {{x}}',
  point_x: 'Point {{x}}',
  preferable_vessel_schedule: 'Preferable Vessel Schedule',
  processing_bulk_upload_alert_message: 'Cancelling this activity will stop the order creation process.<br/> Saving orders...',
  purchase_order_status: {
    [PURCHASE_ORDER_STATUS.CANCELLED]: 'Cancelled',
    [PURCHASE_ORDER_STATUS.ORDER_APPROVED]: 'On Shipment',
    [PURCHASE_ORDER_STATUS.ORDER_COMPLETED]: 'Shipment Completed',
    [PURCHASE_ORDER_STATUS.ORDER_CONFIRMED]: 'Shipment Confirmed',
    [PURCHASE_ORDER_STATUS.ORDER_PENDING]: 'Waiting For Confirmation',
    [PURCHASE_ORDER_STATUS.REJECTED_BY_VENDOR]: 'Rejected by Vendor'
  },
  rc: 'RC',
  related_containers_in_vessel_x: 'Related Containers in Vessel ({{x}})',
  reupload_bulk_order_button_label: 'Re-upload Bulk Order (.xls or .xlsx)',
  review_order: 'Review Order',
  route_type: {
    [ROUTE_TYPES_DTD.MULTIPLE_PICKUP]: 'Multiple Pickup',
    [ROUTE_TYPES_DTD.SINGLE_PICKUP]: 'Single Pickup'
  },
  seal_number: 'Seal Number',
  search_shipment_types: {
    [SHIPMENT_SEARCH_TYPES.DO_NUMBER]: 'DO Number',
    [SHIPMENT_SEARCH_TYPES.EXTERNAL_ID]: 'External ID',
    [SHIPMENT_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate',
    [SHIPMENT_SEARCH_TYPES.PO_ORDER_NUMBER]: 'PO Number'
  },
  shipment_completed: 'Shipment Completed',
  shipment_details: 'Shipment Details',
  shipment_type: 'Shipment Type',
  stms_statuses: {
    [STMS_AGGREGATED_STATUS.ORDER_PENDING]: 'Awaiting Confirmation',
    [STMS_AGGREGATED_STATUS.ORDER_CONFIRMED]: 'Vendor Confirmed',
    [STMS_AGGREGATED_STATUS.PICKUP_EMPTY_CONTAINER]: 'Pickup Empty',
    [STMS_AGGREGATED_STATUS.STUFFING]: 'Stuffing',
    [STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_LOADING]: 'Ongoing to Port'
  },
  system_couldnt_process_the: 'System couldn’t process the Shipment list on the',
  template: 'Template',
  to_be_confirmed: 'To Be Confirmed',
  total_containers: 'Total Containers',
  this_happened_reason: 'This might happened due to these reason:',
  unit_almost_arrive_x: 'Unit almost arrive at {{x}}',
  unrecognized_document: 'Unrecognized Document',
  unrecognized_shipment_list: 'Unrecognized Shipment List',
  unrecognized_template: 'Unrecognized Template',
  upload_bulk_order_button_label: 'Upload Bulk Order (.xls or .xlsx)',
  vessel_voyage_list: 'Vessel Voyage List',
  x_shipments: '{{x}} Shipments(s)',
  yes_cancel_order: 'Yes, Cancel Order'
};
