export const SHIPMENT_POINT_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
};

export const DO_CREATION_TYPE = {
  D2D: 'D2D',
  MM: 'MM',
};

export const FIRST_MILE_SHIPMENT_TYPE = {
  DOOR_TO_DOOR: 'DOOR_TO_DOOR',
  MULTI_DOOR_TO_DOOR: 'MULTI_DOOR_TO_DOOR',
  TRUCKING_DTD: 'TRUCKING_DTD',
  SHIPPING_LINE_DTD: 'SHIPPING_LINE_DTD',
  DOORING_AGENT_DTD: 'DOORING_AGENT_DTD',
  FIRST_MILE_TRUCKING: 'FIRST_MILE_TRUCKING',
};

export const FIRST_MILE_TRUCKING_SHIPMENT_TYPE = {
  TRUCKING_DTD: 'TRUCKING_DTD',
  FM_EXPORT: 'FM_EXPORT',
  FM_IMPORT: 'FM_IMPORT',
  FM_INBOUND: 'FM_INBOUND',
  FM_OUTBOUND: 'FM_OUTBOUND',
};

export const ROUTE_TYPES_DTD = {
  SINGLE_PICKUP: 'SINGLE_PICKUP',
  MULTIPLE_PICKUP: 'MULTIPLE_PICKUP',
};

export const ROUTE_TYPE = {
  SINGLE_PICKUP: 'SINGLE_PICKUP',
  MULTIPLE_PICKUP: 'MULTIPLE_PICKUP',
};

export const BTMS_REQUIRED_DOCUMENT_RETURN_TYPE = {
  REQUIRED_DOCUMENTS_FOR_RECEIVER_AT_DESTINATION: 'REQUIRED_DOCUMENTS_FOR_RECEIVER_AT_DESTINATION',
  REQUIRED_DOCUMENTS_TO_BE_RETURNED_TO_SHIPPER: 'REQUIRED_DOCUMENTS_TO_BE_RETURNED_TO_SHIPPER',
  REQUIRED_DOCUMENTS_TO_BE_ATTACHED_IN_INVOICE: 'REQUIRED_DOCUMENTS_TO_BE_ATTACHED_IN_INVOICE',
};

export const BTMS_ADDITIONAL_DOCUMENT_RETURN_TYPE = {
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  CLAIM_AND_RETURN: 'CLAIM_AND_RETURN',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
};

/**
 * Container documents generated from multiple sources,
 * some of the types are defined in backend,
 * but some of them retrieve from different fields without document type
 */
export const DOOR_TO_DOOR_SHIPMENT_DETAILS_DOCUMENT_TYPE = {
  // defined in FE only
  RELEASE_ORDER: 'RELEASE_ORDER',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  DTD_BILL_OF_LADING: 'DTD_BILL_OF_LADING',
  INVOICE_SHIPPING_LINE: 'INVOICE_SHIPPING_LINE',

  // defined in BE
  DTD_RECEIVING_ORDER: 'DTD_RECEIVING_ORDER',
  LOADING_INSTRUCTION: 'LOADING_INSTRUCTION',
  POL: 'POL',
  DTD_RECEIVING_CARD: 'DTD_RECEIVING_CARD',
  OCEAN_FREIGHT: 'OCEAN_FREIGHT',
};
