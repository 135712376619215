/* eslint-disable max-len */

import { DERIVED_SHIPMENT_STATUSES, SHIPMENT_STATUSES } from '~/Configurations/constants';

export default {
  account_deactivation_warning_message: 'You will not be able to use this bank account to create invoice',
  account_successfully_updated_as_default: 'Account {{account_number}} saved as main account',
  activity_log_time: 'Activity Log: {{time}} {{timezone}}',
  all_count: 'All ({{count}})',
  arrival_at_x: 'Arrival at {{x}}',
  by_actor: 'by <strong>{{actor}}</strong>',
  cancel_adding_fee: 'Cancel adding this fee?',
  cancel_do: 'Cancel {{do_number}}?',
  cancel_order_po: 'Cancel Order {{po_number}}',
  capacity_in_cbm: '{{capacity}} CBM',
  capacity_in_kg: '{{capacity}} Kg',
  capacity_in_ton: '{{capacity}} Ton',
  change_shipment_status: 'Change {{do_number}} status?',
  change_status_time: 'Change {{do_number}} status time?',
  contract_activated: '{{contract}} Activated',
  contract_inactivated: '{{contract}} Inactivated',
  contract_no_changed_to: '{{old}} changed to {{new}}',
  create_new: 'Create New {{name}}',
  created_on: 'Created on {{date}}',
  customer_successfully_created: '{{name}} successfully added.',
  customer_successfully_updated: '{{name}} successfully updated.',
  date_range_invalid: 'Date range {{field_start}} to {{field_end}} is invalid',
  date_should_be_earlier: '{{field_start}} should be earlier than {{field_end}}',
  day_and_minutes: '{{days}} Days ({{minutes}} Minutes)',
  delete_bill_of_lading: 'Delete {{blNumber}}?',
  delete_this_bank_account: 'Delete this bank account?',
  detail_of: '{{object}} Details',
  detail_of_shipment: 'Details of {{shipment_number}}',
  do_cancelled: '{{do_number}} cancelled.',
  document_title: '{{documentType}} - Point {{pointName}}',
  document_uploaded_successfully: 'Dokumen successfully uploaded',
  download_x: 'Download {{x}}',
  end_date_field_blank: 'Will automatically set to {{endDate}} if the End Date is blank',
  evaluation_status_manual_update_log: 'Shipment {{updated_status}} by <strong>{{actor}}</strong>',
  filename_uploaded_success_created_shipments:
    '<strong>{{fileName}}</strong> uploaded. You have successfully created {{total}} shipments',
  file_error: {
    1: 'Cannot Find Company',
    9: 'Cannot Find Warheouse',
    13: 'Invalid Order Type',
    45: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
    46: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
    55: 'Invalid Vehicle ID',
    56: 'Invalid SKU',
    58: 'Invalid Capacity Unit',
    60: 'Address ({{column_name}}) is Outside Indonesia',
    61: 'Cannot Find Address ({{column_name}})',
    62: 'Invalid Loading Date',
    63: 'Invalid Unloading Date',
    64: 'Cannot find truck',
    65: 'Invalid SKU',
    66: 'Cannot Find Customer',
    67: 'Cannot Find Driver',
    96: 'BL not found',
    97: 'Invalid container status',
    98: 'Invalid container type',
    99: 'Warehouse not found',
    105: 'Partnership pricing with given transporter does not exist',
    113: 'Invalid Order ID',
    114: 'Invalid Shipment Type',
    115: 'Invalid Latest Loading Date Time',
    116: 'Invalid Location ID',
    117: 'Invalid Truck Type',
    118: 'Invalid Truck Subtype',
    119: 'Truck Type or Subtruck Type Not Found',
    120: 'Invalid Loads Capacity',
    121: 'Shipment Type Not Found',
    122: 'Multiple Company Shipment Type Found',
    123: 'Company Order Type Not Found',
    124: 'Multiple Company Order Type Found',
    125: 'Invalid Transporter ID or Name',
    126: 'requested_pickup_datetime must be earlier than latest_pickup_datetime',
    127: 'requested_dropoff_datetime must be later than requested_pickup_datetime',
    128: 'latest_pickup_datetime must be earlier than requested_dropoff_datetime',
    140: 'Invalid window time',
    142: 'Invalid SKU',
    143: 'Invalid SKU quantity',
    156: 'Invalid Fulfillment Type',
    157: 'Transporter ID and Transporter Name Should Be Blank',
    158: 'Contracted transporters for the given route does not exist',
    159: 'Transporters without contract for the given route does not exist',
    184: 'No Multistop Fee',
    188: 'Capacity (weight) must be less than 28 TON',
    189: 'Capacity (weight) must be a number with maximum of 2 decimal value',
    190: 'Capacity (weight) must be greater than 0',
    193: 'Truck Selection by Vendor must be On or Off',
    194: 'Truck Type and Sub Type should be blank if Truck Selection by Vendor is On',
    199: 'Invalid Variant',
    474: 'Invalid Header CSV',
    475: 'Invalid column {{column_name}}',
    476: 'Invalid CSV File',
    477: 'Empty CSV Header',
    478: 'Unknown Error',
    479: 'Invalid Escape Sequence',
    480: 'Invalid Row Length',
    481: 'Invalid Row With Stray Quotes',
    483: 'Vehicle License Plate Already Registered',
    531: 'Invalid Point Types',
    532: 'Invalid Point Date',
    572: 'Invalid Window Time',
    573: 'Invalid Location ID',
    574: 'Point requested dates and time windows should be sequential',
    575: 'No Multistop Fee, Fulfillment Type should be Private Bidding, and Transporter ID should be blank',
    577: 'Invalid Latest Point A Datetime',
    619: 'First point should be pickup',
    620: 'Last point should be drop-off',
    621: 'Invalid Shipping Seal Number',
    622: 'Invalid Seal Number',
    623: 'Invaid Container Number',
    624: 'Invalid Serial Number',
    625: 'Empty Transporter ID',
    626: 'Empty Order ID',
    627: 'Empty Shipment Type',
    628: 'Empty Order Type',
    629: 'Empty Variant',
    630: 'Empty Capacity',
    631: 'Empty Capacity Unit',
    632: 'Empty Truck Selection by Vendor',
    633: 'Empty Truck Type',
    634: 'Empty Truck Sub Type',
    635: 'Empty Truck Type and Sub Type',
    636: 'Empty Window Time',
    637: 'Empty Location ID',
    638: 'Empty Point Types',
    639: 'Transporter Name must be blank',
    640: 'Invalid Capacity',
    641: 'Invalid Capacity Unit',
    643: 'Order No. Existed',
    1101: 'Invalid Vendor ID',
    1102: 'Invalid Vendor Name',
    1103: 'Effective Date Invalid',
    1104: 'Expiration Date Invalid',
    1105: 'Invalid Truck Type or Sub Type',
    1106: 'Invalid Amount',
    1107: 'Invalid Shipment Type',
    1108: 'Invalid Lane ID',
    1117: 'Invalid Capacity',
    1118: 'Invalid Capacity Unit',
    1126: 'SKU Group Invalid',
    1127: 'Origin location ID and/or city does not exist',
    1128: 'Destination location ID and/or city does not exist',
    1129: 'Origin location ID and/or city does not exist',
    1130: 'Destination location ID and/or city does not exist',
    1131: 'Capacity and/or Capacity Unit does not exist',
    1132: 'Origin location ID and/or city does not exist',
    1133: 'Destination location ID and/or city does not exist',
    1134: 'Effective and/or Expiration Date Invalid',
    1136: 'Invalid Price Rate Type',
    1137: 'Invalid Min. Quantity',
    1138: 'Capacity and Capacity Unit should be blank',
    1139: 'Min. Quantity should be blank',
    1140: 'Min. Quantity is empty',
    2001: 'Invalid Operational Day',
    2002: 'Invalid Available Facility',
    2003: 'Invalid radius',
    2004: 'Invalid Fee Name',
    2005: 'Invalid Longitude',
    2006: 'Invalid Latitude',
    2007: 'Invalid province',
    2008: 'Invalid City',
    2009: 'Invalid District',
    2010: 'Must input operational hours for at least one operational hours column (operational_monday, operational_tuesday, operational_wednesday, operational_thursday, operational_friday, operational_saturday, operational_sunday, operational_holiday)',
    2011: 'Invalid Name',
    2012: 'Invalid Address',
    2013: 'Invalid Location ID',
    2014: 'Invalid Location Code',
    2015: 'Invalid region',
    2016: 'Duplicate city found',
    2017: 'Duplicate region found',
    2018: 'Duplicate location group found',
    2501: 'Location ID Not Found',
    2502: 'Fee type is invalid',
    2503: 'Shipment type is invalid',
    2504: 'Max amount is invalid number',
    2505: 'Reimbursement percentage is invalid',
    2506: 'Reimbursement requirement is invalid',
    2507: 'Truck type/subtype is not found',
    2508: 'Reimbursement type is invalid',
    26104: 'Invalid origin location ID',
    26105: 'Invalid destination location ID',
    26100: 'Please update your location details in Settings',
  },
  fill_in_the_truck_details: 'Fill Truck Details',
  filter_by_x: 'Filter by {{x}}',
  form_dialog: {
    fill_in_this_CSV_file_to_upload_a_entity: 'Fill this CSV to upload a {{entity}}.',
    upload_entity_from_csv_file: 'Upload {{entity}} from CSV',
  },
  form_error_message: {
    document_is_required: 'This document has to be uploaded.',
    field_already_exist: '{{ field }} already exist',
    invalid_format: 'Please enter a valid {{field}}',
    invalid_email: 'Invalid email. Try a different one.',
    is_number: '{{ field }} must be a number',
    mandatory: '{{field}} is mandatory',
    max_length: 'Maximum length for {{field}} is {{max}}',
    max_value: '{{field}} must be less than {{max}}',
    more_than_zero: '{{field}} should be more than 0',
    min_length: 'Minimum length for {{field}} is {{min}}',
    min_value: '{{field}} must be greater than {{min}}',
    num_with_max_decimal_value: '{{field}} must be a number with maximum of {{fraction}} decimal value',
    number_with_decimals: 'Use 0 or positive number with up to 8 decimal points here.',
    required: 'Insert {{field}}',
    strong_password_rule:
      '{{field}} must contain at least {{min}} characters, including upper and lowercase, number, and special character.',
    max_file_size:
      'File size must be less than {{max}}. File: {{filename}}'
  },
  general_action_by: '{{action}} by {{actor}}',
  general_add: 'Add {{name}}',
  general_assign: 'Assign {{name}}',
  general_change_details: 'Change {{name}} Details',
  general_choose: 'Choose {{name}}',
  general_confirmation: '{{name}} Confirmation',
  general_confirm: 'Confirm {{name}}',
  general_create: 'Create {{name}}',
  general_creation_success_message: '{{name}} successfully added.',
  general_deactivation_confirmation: 'Deactivate {{name}}?',
  general_deactivation_success_message: '{{name}} successfully deactivated',
  general_deactivation_warning_message: 'Are you sure you want to deactivate {{name}}',
  general_delete_contract: 'Are you sure you want to delete contract?',
  general_delete_message: 'Are you sure you want to delete {{name}}?',
  general_edit: 'Edit {{name}}',
  general_edit_detail: 'Update {{name}} detail',
  general_edit_success_message: '{{name}} successfully updated.',
  general_issue: 'Issue: {{reason}}',
  general_list: 'List {{name}}',
  general_new_item: 'New {{name}}',
  general_reassign: 'Reassign {{name}}',
  general_removal_confirmation: 'Delete {{name}}?',
  general_removal_success_message: '{{name}} successfully deleted',
  general_removal_warning_message: 'Are you sure to delete {{name}}?',
  general_remove: 'Delete {{name}}',
  general_remove_confirmation: 'Remove {{name}}?',
  general_request: '{{name}} request',
  general_save: 'Save {{name}}',
  general_search: 'Search {{name}}',
  general_search_based_on_various_fields:
    'You can search based on DO Number / Vendor Name / License Plate / External ID {{additional}}',
  general_search_dashboard_with_additional: 'Search License Plate, Order No, {{additional}}.',
  general_select: 'Select {{x}}',
  general_submit_success_message: '{{name}} successfully submitted.',
  general_summary: '{{name}} Summary',
  general_upload_success_message: '{{name}} successfully uploaded.',
  general_review: 'Review {{name}}',
  generic_text_input_placeholder: 'Enter {{field}}',
  hide_x: 'Hide {{x}}',
  invoice_status_update_log: 'Status changed to {{updated_status}} by <strong>{{actor}}</strong>',
  KTP_uploaded_successfully: 'KTP successfully uploaded',
  last_update: 'Last Update {{date}}',
  location_more: '{{visible}} more location(s)',
  location_previous: '{{visible}} previous location(s)',
  location_point_name: 'Point {{name}}',
  lost_fee_warning_msg: 'This fee will be lost.',
  mass_do_cancellation_confirmation: 'Are you sure you want to cancel {{x}} Orders?',
  max_do_selection_exceeded: 'The limit of orders that can be selected is {{x}} Orders.',
  max_size_x: 'Max Size {{size}}',
  multipoint_shipment_status_location_changed:
    '{{pointName}} changed from <strong>{{from_location}}</strong> to <strong>{{to_location}}</strong>',
  no_data_for_x: 'No data for {{x}}',
  NPWP_uploaded_successfully: 'NPWP successfully uploaded',
  pagination_indicator: 'Page {{page}} from {{total}}',
  pagination_indicator_per_page: 'Page {{page}}/{{total}}',
  pic_sequence: 'PIC {{sequence}}',
  placeholder_text_input: 'Input {{field}}',
  proof_of_payment_for_x_invoices_uploaded: 'Proof of payment for {{x}} invoices uploaded successfully',
  pod_from_source: 'POD from {{source}}',
  rate_comparison: '{{value}}/{{totalValue}}',
  rate_do: 'Rate {{id}}',
  reassign_truck_driver_notification:
    '<strong>{{transporterName}}</strong> assigned a new license plate and driver for <strong>{{doNumber}}</strong>',
  register_new: 'Register New {{name}}',
  related_containers_from_number: 'Related Containers from {{number}}',
  resend_otp_countdown_message: 'Resend OTP in {{countdown}}',
  selected_do_count: '{{x}} Order(s)',
  shipment_alert_notification:
    'Shipment <strong>{{doNumber}}</strong> is having a truck issue: <strong>{{alertTypeName}}</strong>. {{additionalInfo}}',
  shipment_alert_notification_major:
    'Shipment <strong>{{doNumber}}</strong> is having a major truck issue: <strong>{{alertTypeName}}</strong>.',
  shipment_alert_notification_minor:
    'Shipment <strong>{{doNumber}}</strong>  is having a minor truck issue: <strong>{{alertTypeName}}</strong>.',
  shipment_alert_notification_old: 'Shipment <strong>{{doNumber}}</strong>  alert: <strong>{{reason}}</strong>.',
  shipment_location_change: 'Location Updated - {{location}} by <strong>{{actor}} ({{platform}})</strong>',
  shipment_status_alert_change: 'Shipment alert {{updated_status}} by <strong>{{actor}} ({{platform}})</strong>',
  shipment_status_alert_change_plain: 'Shipment alert {{updated_status}} by {{actor}}',
  shipment_status_location_changed:
    'Drop-off Location changed from <strong>{{from_location}}</strong> to <strong>{{to_location}}</strong> by <strong>{{actor}}</strong>',
  shipment_status_location_reason: 'Reason: {{reason}}, {{note}}',
  shipment_status_manual_update_log: 'Status changed to {{updated_status}} by {{actor}}',
  shipment_status_manual_update_log_with_platform:
    'Status changed to {{updated_status}} by <strong>{{actor}} {{platform}}</strong>',
  shipment_status_revert_note: 'Status automatically returned to Ongoing to {{pointName}}',
  shipment_status_tracker_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrive at Destination',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrive at Origin',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper, reason: {{inactive_reason}}',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Not Confirmed by Vendor',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'In Transit to Destination',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'In Transit to Origin',
    [SHIPMENT_STATUSES.PLANNED]: 'Vendor Assigned',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.UPCOMING]: 'Shipment Created by {{actor}}',
  },
  // create this for avoid old map translation (shipment_status_tracker_map) error
  shipment_status_tracker_map_trans: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrive at Destination',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrive at Origin',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper, reason: {{inactive_reason}}',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Not Confirmed by Vendor',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'In Transit to Destination',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'In Transit to Origin',
    [SHIPMENT_STATUSES.PLANNED]: 'Vendor Assigned',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.UPCOMING]: 'Shipment Created by <strong>{{actor}}</strong>',
  },
  show_x: 'Show {{x}}',
  SPPKP_uploaded_successfully: 'SPPKP successfully uploaded',
  step: 'Step {{current}}/{{total}}',
  step_of: 'Step {{current}} out of {{total}}',
  stuffing_store_location_x: 'Stuffing Store Location {{x}}',
  the_estimate_time_may_be_late: 'The estimate time may be late than expected.',
  time_updated: 'Updated {{time}}',
  total_time: '{{time}} Total',
  truck_and_driver_details: 'Truck and Driver Details',
  truck_late_time: '{{time}} Late',
  truck_offline_count: 'Truck Offline ({{count}})',
  truck_online_count: 'Truck Online ({{count}})',
  upload_pod_for_shipment: 'Upload POD for {{shipment_number}}',
  upload_receipt_with_maximum_size: 'Upload receipt with maximum size of {{max_size}}.',
  upload_total_file_x: 'Total file upload must not exceed {{size}}',
  upload_the_KTP_with_a_maximum_size: 'Upload KTP with maximum {{size}}.',
  upload_the_NPWP_with_a_maximum_size: 'Upload NPWP with maximum {{size}}.',
  upload_the_SPPKP_with_a_maximum_size: 'Upload SPPKP with maximum {{size}}.',
  uploaded_file_medium_subtitle: 'Uploaded at {{datetime}} from {{source}}',
  uploaded_file_short_subtitle: 'Uploaded at {{datetime}}',
  uploaded_on_subtitle: 'Uploaded on {{dateTime}}',
  user_deactivation_confirmation: 'Deactivate {{name}}?',
  user_deactivation_success_message: '{{name}} successfully deactivated',
  user_deactivation_warning_message:
    '{{name}} will not be able to access Kargo Vendor Central, but it\'s information will still be saved. This action is permanent.',
  user_successfully_created: '{{name}} successfully created.',
  user_successfully_updated: '{{name}} successfully updated.',
  vendor_accepts_do: '<strong>{{transporter_name}}</strong> accepted the shipment for <strong>{{do_number}}</strong>',
  vendor_assign_truck_and_driver:
    '<strong>{{transporter_name}}</strong> assigned a vehicle <strong>{{vehicle_license_plate}}</strong> and driver <strong>{{driver_name}}</strong> for <strong>{{do_number}}</strong>',
  vendor_rejects_do: '<strong>{{transporter_name}}</strong> rejected the shipment for <strong>{{do_number}}</strong>',
  view_document: 'View {{documentType}} - Point {{pointName}}',
  warehouse_successfully_created: '{{name}} successfully added.',
  warehouse_successfully_saved: '{{name}} successfully saved.',
  warehouse_successfully_updated: '{{name}} successfully updated.',
  warehouse_successfully_uploaded: '{{name}} successfully uploaded',
  x_container_selected: '{{total_selected}} containers selected',
  x_number: '{{x}} Number',
  total_x: 'Total {{x}}',
  x_invoice_selected: '{{x}} Invoice Selected',
  x_shipments_successfully_saved: '{{x}} Shipments successfully saved.',
  payment_proof_of_invoice_x: 'Payment Proof of Invoice {{x}}',
  proof_of_payment_of_invoice_x_deleted_successfully: 'Proof of payment for invoice {{x}} deleted successfully',
  history_of_payments_for_x: 'History of payments for {{x}}',
  virtual_account_compact: 'Virtual Account: {{accountNumber}} (a.n {{accountHolder}})',
  manual_transfer_compact: 'Manual Transfer: {{accountNumber}} (a.n {{accountHolder}})',
  search_by_x: 'Search by {{x}}',
  store_x: 'Store {{x}}',
  point_x: 'Point {{x}}',
  row_x: 'Row {{x}}'
};
