import Auth from '~/Configurations/auth';

/**
 * Get Session data in JSON format.
 * @param {*} field: key data session from ingonito
 * @returns value form key data
 */
// eslint-disable-next-line import/prefer-default-export
export const getFieldSession = async field => {
  const cognitoCurrentSession = await Auth.currentSession();
  const token = cognitoCurrentSession.getIdToken().getJwtToken();
  const tokenDecode = JSON.parse(Buffer.from(token.split('.')[1] || '', 'base64').toString() || '{}');
  return tokenDecode[field];
};
