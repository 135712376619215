export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm';

export const SOA_SUMMARY_SELECTOR = {
  NOT_DUE_AMOUNT: 'notDueAmount',
  PAST_DUE_AMOUNT: 'pastDueAmount',
};

export const SOA_SUMMARY_STATUS_COLOR = {
  [SOA_SUMMARY_SELECTOR.NOT_DUE_AMOUNT]: 'primary',
  [SOA_SUMMARY_SELECTOR.PAST_DUE_AMOUNT]: 'danger',
};

export const SHIPPER_PAYMENT_STATUS = {
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  DUE: 'DUE',
  NOT_DUE: 'NOT_DUE',
};

export const SHIPPER_PAYMENT_STATUS_COLOR = {
  [SHIPPER_PAYMENT_STATUS.PAID]: 'success',
  [SHIPPER_PAYMENT_STATUS.PARTIALLY_PAID]: 'danger',
  [SHIPPER_PAYMENT_STATUS.DUE]: 'danger',
  [SHIPPER_PAYMENT_STATUS.NOT_DUE]: 'primary',
};

export const LIVE_SOA_PROOF_STATUS = {
  NOT_UPLOADED: 'NOT_UPLOADED',
  UPLOADED: 'UPLOADED',
};

export default {};
