/* eslint-disable max-len */

import {
  ADDITIONAL_DOCUMENT_RETURN_TYPE,
  BTMS_SHIPMENT_STATUSES,
  BTMS_SHIPMENT_TYPE,
  COMPANY_STATUSES,
  CONTRACT_EDITABLE_FIELDS,
  CONTRACT_STATUSES,
  CONTRACT_VENDOR_TAB,
  CUSTOMER_DIVISION,
  CUSTOMS_TYPES,
  DANONE_USER_ACCESS_TYPE,
  DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE,
  DERIVED_SHIPMENT_STATUSES,
  DOCUMENT_TYPES,
  DO_CANCELLATION_REASONS,
  DO_EDIT_LOCATION_CHANGE_REASONS,
  DO_REJECTION_REASONS,
  EVALUATE_NOTE_TYPES,
  EVALUATE_RATE_DESC_TYPES,
  EVALUATE_STATUSES,
  FILE_UPLOAD_SOURCE,
  FIRST_MILE_USER_ACCESS_TYPE,
  FM_DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE,
  GENERAL_ENTITY_STATUSES,
  INVOICE_STATUS,
  JOB_STATUSES,
  LOCAL_SHIPMENT_STATUSES,
  LOCATION_TYPE,
  PAYMENT_TYPES_INVOICE,
  PPH23_DEDUCTOR,
  PRODUCT_CATEGORY,
  REQUIRED_DOCUMENT_RETURN_TYPE,
  SHIPMENT_DOCUMENT_TYPES,
  SHIPMENT_FORM_SECTIONS,
  SHIPMENT_POINT_STATUS,
  SHIPMENT_STATUSES,
  SHIPPER_LOCATION_OPERATING_DAY,
  SHIPPER_LOCATION_STATUS,
  USER_ACCESS_TYPE,
  USER_SALES_ROLE,
  USER_STATUSES,
  VENDOR_CONFIRMATION_TIME_TYPES,
} from '~/Configurations/constants';

import {
  LIVE_SOA_PROOF_STATUS,
  SHIPPER_PAYMENT_STATUS,
  SOA_SUMMARY_SELECTOR,
} from '~/Pages/Invoices/pbi-live-soa/constants';
import { DEVICE_LOCATION_UPDATE_SOURCES } from '~/Pages/Shipment/Utilities/constants';

export default {
  acceptance_date: 'Date Issued',
  account_details: 'Account Details',
  account_holder: 'Account Holder',
  account_holder_name: 'Account Holder Name',
  account_name: 'Account Name',
  account_number: 'Account Number',
  action: 'Action',
  action_required: 'Action Required',
  active: 'Active',
  activity_log: 'Activity Log',
  activity_log_type: {
    activate_partner: {
      UPDATE: 'Transporter activated by {{name}}',
    },
    change_vendor_level: {
      UPDATE: 'Transporter\'s verification level changed by {{name}}',
    },
    change_vendor_owner_type: {
      UPDATE: 'Transporter\'s account type changed by {{name}}',
    },
    comment: {
      UPDATE: '{{name}} left a comment',
    },
    create_company_drivers: {
      INSERT: 'Driver created by {{name}}',
      UPDATE: 'Driver details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    create_company_vehicles: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    deactivate_partner: {
      UPDATE: 'Transporter deactivated by {{name}}',
    },
    save_brokerage_vendor: {
      INSERT: 'Transporter created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_customer: {
      INSERT: 'Shipper created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_partnership_contract: {
      INSERT: 'Contract {{operationRecepient}} created by {{name}}',
      UPDATE: 'Contract {{operationRecepient}} edited by {{name}}',
    },
    save_warehouse: {
      INSERT: 'Location created by {{name}}',
      UPDATE: 'Location edited by {{name}}',
    },
    save_warehouse_fee: {
      INSERT: 'Location Fee created by {{name}}',
      UPDATE: 'Location Fee edited by {{name}}',
    },
    update_partner_driver: {
      INSERT: 'Driver created by {{name}}',
      UPDATE: 'Driver details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    update_partner_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    upsert_partner_company_driver: {
      INSERT: 'Driver created by {{name}}',
      UPDATE: 'Driver details edited by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    upsert_partner_company_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    verify_company_driver: {
      UPDATE: 'Driver verified by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    verify_vendor: {
      UPDATE: 'Transporter verified by {{name}}',
    },
  },
  actual_arrival: 'Actual Arrival',
  actual_route: 'Actual Route',
  actual: 'Actual',
  add: 'Add',
  add_bank_account: 'Add Bank Account',
  add_contract: 'Add Contract',
  add_coo: 'Add Certificate of Origin',
  add_document: 'Add Document',
  add_ebilling_document: 'Add E-Billing Document',
  add_employee: 'Add Employee',
  add_location: 'Add Location',
  add_logo: 'Add Logo',
  add_msds: 'Add Material Safety Data Sheet',
  add_photo: 'Add Photo',
  add_pic_contact: 'Add PIC Contact',
  add_pod: 'Add POD',
  add_shipping_line_delivery_order: 'Add Shipping Line Delivery Order',
  add_storage_charges: 'Add Storage Charges',
  add_transporter: 'Add Transporter',
  add_vendor: 'Add Vendor',
  added_at: 'Added At',
  additional_note: 'Additional Note',
  additional_notes: 'Additional Notes',
  address: 'Address',
  address_details: 'Address Details',
  after: 'After',
  ahead: 'Ahead',
  alert_free_trial_body: 'To continue accessing Kargo Nexus features, please contact us to upgrade your account',
  alert_free_trial_expired: 'Your Free Trial Has Expired',
  alert_free_trial_expired_for: 'Your Free Trial For {{name}} Has Expired',
  all_shipments: 'All Shipment(s)',
  amount: 'Amount',
  apply: 'Apply',
  arrival_at_destination: 'Arrival at Destination',
  arrival_at_origin: 'Arrival at Origin',
  arrive_at_port_eta: 'Port Arrival ETA',
  aspect: 'Aspect',
  assign_multiple_orders: 'Assign Multiple Orders',
  assign_now: 'Assign Now',
  assign_vendor_in: 'assign vendor in',
  ask_driver_to_update_location: 'Ask Driver to Update Location',
  average_transit_time_and_queue_time: 'Average Transit Time & Queue Time',
  average_vendor_confirmation_time: 'Average Vendor Confirmation Time',
  avg_transit_time: 'AVG Transit Time',
  back: 'Back',
  back_to_dashboard: 'Back to Dashboard',
  bank: 'Bank',
  bank_account: 'Bank Account',
  bank_name: 'Bank Name',
  before: 'Before',
  bid: 'Bid',
  billing: 'Billing',
  billing_details: 'Billing Details',
  btms_shipment_type_map: {
    [BTMS_SHIPMENT_TYPE.DOOR_TO_DOOR]: 'DOOR TO DOOR',
    [BTMS_SHIPMENT_TYPE.F2DEPO]: 'FACTORY TO DEPO',
    [BTMS_SHIPMENT_TYPE.F2DIST]: 'FACTORY TO DISTRIBUTOR',
    [BTMS_SHIPMENT_TYPE.F2MT]: 'FACTORY TO MODERN TRADE',
    [BTMS_SHIPMENT_TYPE.F2W]: 'FACTORY TO WAREHOUSE',
    [BTMS_SHIPMENT_TYPE.GT]: 'GENERAL TRADE',
    [BTMS_SHIPMENT_TYPE.INBOUND_DTD]: 'INBOUND DOOR TO DOOR',
    [BTMS_SHIPMENT_TYPE.MT]: 'MODERN TRADE',
    [BTMS_SHIPMENT_TYPE.MULTIDROP]: 'MULTIDROPOFF',
    [BTMS_SHIPMENT_TYPE.MULTIDROP_MULTIPICKUP]: 'MULTIPICKUP AND MULTIDROPOFF',
    [BTMS_SHIPMENT_TYPE.MULTIPICKUP]: 'MULTIPICKUP',
    [BTMS_SHIPMENT_TYPE.MULTI_DOOR_TO_DOOR]: 'MULTI DOOR TO DOOR',
    [BTMS_SHIPMENT_TYPE.OUTBOUND_DTD]: 'OUTBOUND DOOR TO DOOR',
    [BTMS_SHIPMENT_TYPE.SHIPPING_LINE_DTD]: 'SHIPPING LINE DOOR TO DOOR',
    [BTMS_SHIPMENT_TYPE.W2DEPO]: 'WAREHOUSE TO DEPO',
    [BTMS_SHIPMENT_TYPE.W2DIST]: 'WAREHOUSE TO DISTRIBUTOR',
    [BTMS_SHIPMENT_TYPE.W2MT]: 'WAREHOUSE TO MODERN TRADE',
    [BTMS_SHIPMENT_TYPE.W2W]: 'WAREHOUSE TO WAREHOUSE',
  },
  bulk_action: 'Bulk Action',
  cancel: 'Cancel',
  cancel_multiple_orders: 'Cancel Multiple Orders',
  cancel_order: 'Cancel Order',
  cancel_orders: 'Cancel Orders',
  cancel_shipment: 'Cancel Shipment',
  cancellation_location_reason: 'Reason for Location Change',
  cancellation_reason: 'Cancellation Reason',
  cannot_add_new_employee: 'Cannot Add New Employee',
  capacity: 'Capacity',
  capacity_unit: 'Capacity Unit',
  cbm: 'CBM',
  central_address: 'Main Address',
  change: 'Change',
  change_bill_of_lading: 'Update Bill of Lading',
  change_bill_of_lading_details: 'Update Bill of Lading Details',
  change_delivery_order: 'Update Delivery Order',
  change_delivery_order_details: 'Update Delivery Order Details',
  change_details: 'Change Details',
  change_password: 'Update Password',
  change_shipment: 'Update Shipment',
  change_status: 'Change Status',
  changed: 'Changed',
  changes: 'Changes',
  check: 'Check',
  check_account: 'Check Account',
  check_data_before_submitting_message:
    'Make sure the data you inputted is correct as you can\'t edit  or delete it later',
  choose_bank: 'Choose Bank',
  choose_customize_notified: 'Choose and customize how you would like to get notified',
  choose_commodity: 'Choose Commodity',
  choose_company: 'Choose Your Company',
  choose_consignee: 'Choose Consignee',
  choose_customs_type: 'Choose Customs Type',
  choose_driver: 'Choose Driver',
  choose_file: 'Choose File',
  choose_note: 'Choose Note',
  choose_notify_party: 'Choose Notify Party',
  choose_pod: 'Choose POD',
  choose_port: 'Choose Port',
  choose_shipping_line: 'Choose Shipping Line',
  choose_supplier: 'Choose Supplier',
  choose_truck: 'Choose Truck',
  choose_winner: 'Choose Winner',
  choose_winner_automatically: 'Choose Winner Automatically',
  city: 'City',
  cleanliness: 'Cleanliness',
  clear: 'Clear',
  close: 'Close',
  committed_do: 'Committed DO',
  commodity_detail: 'Commodity Details',
  commodity: 'Commodity',
  company_address: 'Company Address',
  company_details: 'Company Details',
  company_document: 'Company Document',
  company_name: 'Company Name',
  company_settings: 'Company Settings',
  company_settings_tab_navigation: {
    organization: 'Organization Settings',
    product: 'Product Settings',
    shipment: 'Shipment Settings',
  },
  company: 'Company',
  completeness: 'Completeness',
  company_status_map: {
    [COMPANY_STATUSES.ACTIVE]: 'Active',
    [COMPANY_STATUSES.CHURN]: 'Churn',
    [COMPANY_STATUSES.DEACTIVATED]: 'Deactivated',
    [COMPANY_STATUSES.INACTIVE]: 'Inactive',
    [COMPANY_STATUSES.REGISTERED]: 'Registered',
  },
  condition: 'Condition',
  consignee: 'Consignee',
  contact: 'Contact',
  contact_details: 'Contact Details',
  contact_kargo: 'Contact Kargo',
  contact_name: 'Contact Name',
  contact_person: 'Contact Person',
  contact_phone: 'Contact Phone',
  contacts: 'Contacts',
  container: 'Container',
  container_capacity: 'Container Capacity',
  container_details: 'Container Details',
  container_grade: 'Container Grade',
  container_number: 'Container No.',
  container_number_no_abbrev: 'Container Number',
  container_type: 'Container Type',
  container_quantity: 'Container Quantity',
  containers: 'Containers',
  continue: 'Continue',
  contract: 'Contract',
  contract_editable_fields: {
    [CONTRACT_EDITABLE_FIELDS.AMOUNT]: 'Price',
    [CONTRACT_EDITABLE_FIELDS.CAPACITY]: 'Capacity',
    [CONTRACT_EDITABLE_FIELDS.CAPACITY_UNIT]: 'Capacity Unit',
    [CONTRACT_EDITABLE_FIELDS.END_DATETIME]: 'End Date',
    [CONTRACT_EDITABLE_FIELDS.MINIMUM_QUANTITY_TO_BE_PAID]: 'Minimum Quantity to be Paid',
    [CONTRACT_EDITABLE_FIELDS.PARTNERSHIP_PRICING_NUMBER]: 'Contract No.',
    [CONTRACT_EDITABLE_FIELDS.PRICING_RATE_TYPE]: 'Price Rate Type',
    [CONTRACT_EDITABLE_FIELDS.START_DATETIME]: 'Start Date',
    [CONTRACT_EDITABLE_FIELDS.STATUS]: 'Status',
  },
  contract_details: 'Contract Details',
  contract_name: 'Contract Name',
  contract_no: 'Contract No.',
  contract_status: {
    [CONTRACT_STATUSES.ACTIVE]: 'Active',
    [CONTRACT_STATUSES.INACTIVE]: 'Inactive',
  },
  contract_vendor_tab: {
    [CONTRACT_VENDOR_TAB.CONTRACT]: 'All Contracts',
    [CONTRACT_VENDOR_TAB.VENDOR]: 'Vendors',
  },
  contracts: 'Contracts',
  coo: 'Certificate of Origin',
  copy_link: 'Copy Link',
  copy_location_table_tooltip: 'Copy location table data with structure: Location ID, Location Name, Address, Location Group, combination.',
  create_bill_of_lading: 'New Bill Of Lading',
  create_delivery: 'New Delivery Order',
  create_new_warehouse: 'Create Warehouse',
  created: 'Created',
  criteria: 'Criteria',
  csv_separator: 'CSV Separator',
  customer: 'Customer',
  customer_details: 'Customer Details',
  customer_division: {
    [CUSTOMER_DIVISION.CLAIMS]: 'Claims',
    [CUSTOMER_DIVISION.FINANCE]: 'Finance',
    [CUSTOMER_DIVISION.LOGISTICS]: 'Logistics',
    [CUSTOMER_DIVISION.OPERATIONS]: 'Operations',
    [CUSTOMER_DIVISION.PROCUREMENT]: 'Procurement',
    [CUSTOMER_DIVISION.SALES]: 'Sales',
    [CUSTOMER_DIVISION.TREASURY]: 'Treasury',
    [CUSTOMER_DIVISION.WAREHOUSE]: 'Warehouse',
  },
  customer_name_slash_company: 'Customer / Company Name',
  customs_document: 'Customs Document',
  customs_type: 'Customs Type',
  customs_type_map: {
    [CUSTOMS_TYPES.BC20]: 'BC 2.0',
    [CUSTOMS_TYPES.BC23]: 'BC 2.3',
    [CUSTOMS_TYPES.BC25]: 'BC 2.5',
  },
  cut_off: 'Cut Off',
  damaged: 'Damaged',
  damaged_on_arrival: 'Damaged on Arrival',
  dashboard: 'Dashboard',
  date_added: 'Date Added',
  date_created: 'Date Created',
  date_creation: 'Date Creation',
  date_due: 'Date Due',
  date_due_date: 'Due Date',
  date_issued: 'Date Issued',
  date_start_date: 'Start Date',
  date_updated_on: 'Updated On',
  date: 'Date',
  day: 'Day',
  dd_mm_yy: 'DD/MM/YY',
  deactivate: 'Deactivate',
  deactivate_employee: 'Deactivate Employee',
  deadline_date_and_time: 'Deadline Date and Time',
  default_account: 'Default Account',
  delete_account: 'Delete Account',
  delete_bill_of_lading: 'Delete Bill of Lading',
  delete_contract: 'Delete',
  delete: 'Remove',
  delete_picture: 'Delete Picture',
  delivered: 'Delivered',
  delivery_confermission_title: 'Delivery Order Summary',
  delivery_order: 'Delivery Order',
  description: 'Description',
  destination: 'Destination',
  destination_port: 'Port of Arrival',
  destination_warehouse: 'Destination Warehouse',
  detail_title: 'Details',
  detail: 'Detail',
  details: 'Details',
  device_location_update_source_map: {
    [DEVICE_LOCATION_UPDATE_SOURCES.MOBILE]: 'D-App',
    [DEVICE_LOCATION_UPDATE_SOURCES.WHATSAPP]: 'Whatsapp',
    [DEVICE_LOCATION_UPDATE_SOURCES.GPS_DEVICE]: 'GPS Device',
  },
  different_city: 'Different City',
  dimension: 'Dimension',
  district: 'District',
  division: 'Division',
  do_cancellation_reason_map: {
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Revision Allocation',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Safety & Quality Compliance',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Force Majeure',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full Stock',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Loading Delay',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'No Empty Jugs',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'No Pallet / JR',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'No Stock',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Other Reason',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'TKBM',
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'No Show',
  },
  do_edit_location_change_reason_map: {
    [DO_EDIT_LOCATION_CHANGE_REASONS.CHANGE_OF_PLANS]: 'Change of Plans',
    [DO_EDIT_LOCATION_CHANGE_REASONS.LOCATION_IS_FULL]: 'Location is Full',
    [DO_EDIT_LOCATION_CHANGE_REASONS.OTHERS]: 'Others',
  },
  do_free_time_end_date: 'DO Free Time End Date',
  do_margin: 'Margin',
  do_max_bid_limit: 'Max Bid Limit',
  do_number: 'DO Number',
  do_rejection_reason_map: {
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Driver Not Ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Other Reason',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Vehicle Maintenance Needed',
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Vehicle Not Ready',
  },
  do_suggested_price_from_kargo: 'Suggested Price from Kargo',
  do_type: 'Route',
  document: 'Document',
  document_name: 'Document Name',
  document_number: '{{name}} Number',
  document_type: 'Document Type',
  document_type_map: {
    [DOCUMENT_TYPES.CLAIMS_AND_RETURNS]: 'Claim And Returns',
    [DOCUMENT_TYPES.CLAIM_AND_RETURN_DOCUMENT]: 'Claim And Return Document',
    [DOCUMENT_TYPES.CLAIM_AND_RETURN_GOODS]: 'Claim And Return Goods',
    [DOCUMENT_TYPES.POD]: 'POD',
    [DOCUMENT_TYPES.POL]: 'POL',
    [DOCUMENT_TYPES.DRIVER_REJECTED_GOODS]: 'Rejected Goods',
    [DOCUMENT_TYPES.DRIVER_REJECTION_LETTER]: 'Driver Rejection Letter',

    // Required document return from BTMS
    [REQUIRED_DOCUMENT_RETURN_TYPE.BERITA_ACARA]: 'Berita Acara',
    [REQUIRED_DOCUMENT_RETURN_TYPE.BUKTI_PENERIMAAN_BARANG]: 'Bukti Penerimaan Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.DISPATCH_MEMO]: 'Dispatch Memo',
    [REQUIRED_DOCUMENT_RETURN_TYPE.FORM_ABSENSI]: 'Form Absensi',
    [REQUIRED_DOCUMENT_RETURN_TYPE.FORM_ABSENSI_BONGKAR]: 'Form Absensi (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.FORM_ABSENSI_MUAT]: 'Form Absensi (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.KWITANSI_TKBM]: 'Kwitansi TKBM',
    [REQUIRED_DOCUMENT_RETURN_TYPE.NOTA_TIMBANG]: 'Nota Timbang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.NOTA_TIMBANG_CARBONIZE_BONGKAR]: 'Nota Timbang Carbonize (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.NOTA_TIMBANG_CARBONIZE_MUAT]: 'Nota Timbang Carbonize (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.NOTA_TIMBANG_ORIGINAL_BONGKAR]: 'Nota Timbang Original (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.NOTA_TIMBANG_ORIGINAL_MUAT]: 'Nota Timbang Original (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.PACKING_LIST]: 'Packing List',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_PERINTAH_JALAN]: 'Surat Perintah Jalan',
    [REQUIRED_DOCUMENT_RETURN_TYPE.BILL_OF_LADING]: 'Bill of Lading',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_CARBONIZE]: 'Surat Jalan Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_ORIGINAL]: 'Surat Jalan Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.BERITA_ACARA_MUAT]: 'Berita Acara Muat',
    [REQUIRED_DOCUMENT_RETURN_TYPE.BERITA_ACARA_SERAH_TERIMA_BARANG]: 'Berita Acara Serah Terima Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.BERITA_SERAH_TERIMA_BARANG]: 'Berita Serah Terima Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.BON_TIMBANG]: 'Bon Timbang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.COMMERCIAL_INVOICE]: 'Commercial Invoice',
    [REQUIRED_DOCUMENT_RETURN_TYPE.DELIVERY_LOCAL_ORDER_COPY]: 'Delivery Local Order Copy',
    [REQUIRED_DOCUMENT_RETURN_TYPE.DELIVERY_LOCAL_ORDER_ORIGINAL]: 'Delivery Local Order Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.DELIVERY_NOTE]: 'Delivery Note',
    [REQUIRED_DOCUMENT_RETURN_TYPE.EIR_DEPOT]: 'EIR Depot',
    [REQUIRED_DOCUMENT_RETURN_TYPE.EIR_PORT]: 'EIR Port',
    [REQUIRED_DOCUMENT_RETURN_TYPE.FAKTUR_PAJAK]: 'Faktur Pajak',
    [REQUIRED_DOCUMENT_RETURN_TYPE.FAKTUR_PAJAK_DEPOT]: 'Faktur Pajak Depot',
    [REQUIRED_DOCUMENT_RETURN_TYPE.GOOD_RECEIPT]: 'Good Receipt',
    [REQUIRED_DOCUMENT_RETURN_TYPE.LIVE_LOADING_DOCUMENT]: 'Live Loading Document',
    [REQUIRED_DOCUMENT_RETURN_TYPE.LOLO]: 'LOLO',
    [REQUIRED_DOCUMENT_RETURN_TYPE.PACKINGLIST_CARBONIZE]: 'Packinglist Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.PACKINGLIST_ORIGINAL]: 'Packinglist Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.PPFTZ_03]: 'PPFTZ-03',
    [REQUIRED_DOCUMENT_RETURN_TYPE.RBPB_CARBONIZE]: 'RBPB Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.RBPB_ORIGINAL]: 'RBPB Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.RECEIPT_DEURAGE]: 'Receipt Deurage',
    [REQUIRED_DOCUMENT_RETURN_TYPE.RECEIPT_EXTEND]: 'Receipt Extend',
    [REQUIRED_DOCUMENT_RETURN_TYPE.RECEIPT_REPAIR]: 'Receipt Repair',
    [REQUIRED_DOCUMENT_RETURN_TYPE.RECEIPT_STORAGE_PORT]: 'Receipt Storage Port',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_PABRIK_CARBONIZE]: 'Surat Jalan Pabrik Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_PABRIK_ORIGINAL]: 'Surat Jalan Pabrik Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_PANTHERA_CARBONIZE]: 'Surat Jalan Panthera Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_PANTHERA_ORIGINAL]: 'Surat Jalan Panthera Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_SHIPPER_CARBONIZE]: 'Surat Jalan Shipper Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_SHIPPER_ORIGINAL]: 'Surat Jalan Shipper Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_TRANSFER_CARBONIZE]: 'Surat Jalan Transfer Carbonize',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_JALAN_TRANSFER_ORIGINAL]: 'Surat Jalan Transfer Original',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_KETERANGAN_MUAT_BARANG]: 'Surat Keterangan Muat Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_PENERIMAAN_BARANG]: 'Surat Penerimaan Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_PERINTAH_EXPEDISI]: 'Surat Perintah Ekspedisi',
    [REQUIRED_DOCUMENT_RETURN_TYPE.SURAT_PERINTAH_PENGELUARAN_BARANG]: 'Surat Perintah Pengeluaran Barang',
    [REQUIRED_DOCUMENT_RETURN_TYPE.TALLY_SHEET]: 'Tally Sheet',
    [REQUIRED_DOCUMENT_RETURN_TYPE.TKBM_BONGKAR]: 'TKBM (Bongkar)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.TKBM_MUAT]: 'TKBM (Muat)',
    [REQUIRED_DOCUMENT_RETURN_TYPE.OTHER]: 'Other',

    // Additional document return from BTMS
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.PROOF_OF_DELIVERY]: 'POD',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.CLAIM_AND_RETURN]: 'Claims & Returns',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.LOADING_FEE]: 'Loading Fee',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.UNLOADING_FEE]: 'Unloading Fee',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.OVERNIGHT_LOADING]: 'Overnight Loading',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.OVERNIGHT_UNLOADING]: 'Overnight Unloading',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.OTHER_LOCATION_FEE]: 'Other Location Fee',
    [ADDITIONAL_DOCUMENT_RETURN_TYPE.OTHER_FEE]: 'Additional Fee',
  },
  documents: 'Documents',
  dof: 'DOF',
  done: 'Done',
  door_to_door_note: 'Door-to-door Note',
  door_to_door_new_booking_order: 'Door to Door New Booking Order',
  door_to_door_booking_order: 'Door to Door Booking Order',
  download_csv: 'Download CSV',
  download_documents_from_external: 'Download Documents From Web',
  download_documents: 'Download Documents',
  download_pdf: 'Download PDF',
  download: 'Download',
  driver_app: 'Driver App',
  driver_detail: 'Driver Detail',
  driver_not_found: 'Driver not found',
  driver_phone_number: 'Driver Phone Number',
  driver_phone: 'Driver Phone',
  driver: 'Driver',
  driver_location_update: 'Driver Location Update',
  drivers: 'Drivers',
  drop_off: 'Drop Off',
  dropoff_date_and_time: 'Dropoff Date & Time',
  dropoff_date: 'Dropoff Date',
  dropoff_date_with_dash: 'Drop-off Date',
  dropoff_location: 'Drop-off Location',
  dropoff_time: 'Dropoff Time',
  dropoff: 'Dropoff',
  dropoff_info: 'This section can help you analyze late shipment and retrieve your route trace.',
  dropoff_with_dash: 'Drop-off',
  due_date: 'Due Date',
  ebilling_document: 'E-Billing Document',
  ebilling_paid_time: 'E-Billing Paid Time',
  edit: 'Edit',
  edit_detail: 'Edit Detail',
  edit_details: 'Edit Details',
  edit_employee: 'Edit Employee',
  edit_location: 'Edit Location',
  edit_transporter: 'Update Transporter',
  email: 'Email',
  employee_details: 'Employee Details',
  employees: 'Employees',
  enable_document_upload: 'Enable Document Upload',
  end_date: 'End Date',
  end_date_vendor_contract: '31 Dec 2099',
  end_time: 'End Time',
  enter_description: 'Enter Description',
  equipment: 'Equipment',
  eta: 'ETA',
  evaluate_status_map: {
    [EVALUATE_STATUSES.EVALUATE_SHIPMENT]: 'Evaluated',
    [EVALUATE_STATUSES.RE_EVALUATE_SHIPMENT]: 'Re-Evaluated',
  },
  evaluate_note_types: {
    [EVALUATE_NOTE_TYPES.HANDLING_ISSUE]: 'Handling issue',
    [EVALUATE_NOTE_TYPES.NO_PO_PO_EXPIRED]: 'No PO / PO expired',
    [EVALUATE_NOTE_TYPES.OTHER]: 'Other',
    [EVALUATE_NOTE_TYPES.PO_IS_NOT_ON_SCHEDULE]: 'PO is not on schedule',
    [EVALUATE_NOTE_TYPES.PRODUCT_NEAR_ED]: 'Product near ED',
    [EVALUATE_NOTE_TYPES.QUALITY_ISSUE]: 'Quality issue',
  },
  evaluate_rate_desc_types: {
    [EVALUATE_RATE_DESC_TYPES.GOOD]: 'Good',
    [EVALUATE_RATE_DESC_TYPES.POOR]: 'Poor',
    [EVALUATE_RATE_DESC_TYPES.STANDARD]: 'Standard',
    [EVALUATE_RATE_DESC_TYPES.VERY_GOOD]: 'Very Good',
    [EVALUATE_RATE_DESC_TYPES.VERY_POOR]: 'Very Poor',
  },
  evaluate: 'Evaluate',
  evaluation: 'Evaluation',
  expired_free_trial_banner: {
    your_free_trial_expires: 'Your free trial expires',
    to_continue: 'To continue accessing Kargo Nexus features, please ',
    contact_kargo: 'contact Kargo',
    to_upgrade_account: ' to upgrade your account',
  },
  external_id: 'External ID',
  fee: 'Fee',
  fee_dropoff_per_point: 'Fee Dropoff per Point',
  fee_dropoff_per_point_different_city: 'Fee Dropoff per Point Different City',
  fee_dropoff_per_point_same_city: 'Fee Dropoff per Point Same City',
  fee_per_point_different_city: 'Fee per Point Different City',
  fee_per_point_with_city: 'Fee per Point with City',
  fee_pickup_per_point: 'Fee Pickup per Point',
  fee_pickup_per_point_different_city: 'Fee Pickup per Point Different City',
  fee_pickup_per_point_same_city: 'Fee Pickup per Point Same City',
  file_not_selected_yet: 'File Not Selected',
  file_upload_source_map: {
    [FILE_UPLOAD_SOURCE.BTMS]: 'BTMS',
    [FILE_UPLOAD_SOURCE.DA]: 'Mitra App',
    [FILE_UPLOAD_SOURCE.TMS]: 'Vendor Central',
  },
  first_mile: 'First Mile',
  first_mile_live_location: 'First Mile Live Location',
  forgot_password: 'Forgot Password',
  free_time_end_date: 'Free Time End Date',
  fuel_version: 'Fuel Version',
  fulfillment_rate: 'Fulfillment Rate',
  full_address: 'Full Address',
  general_entity_status_map: {
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Inactive',
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Active',
  },
  goods_type: 'Goods Type',
  group_load: 'Group Load',
  group_load_id: 'Group Load ID',
  height: 'Height',
  here: 'here',
  hide: 'Hide',
  house_bill_of_lading_number: 'House Bill Of Lading Number',
  house_bill_of_lading: 'House Bill Of Lading',
  how_clean_is_the_truck: 'How Clean is the truck? Is it clean and dry?',
  how_is_the_condition_of_the_truck: 'How is the condition of the truck?',
  hygiene: 'Hygiene',
  id_card: 'KTP',
  inactive: 'Inactive',
  inbound_number: 'Inbound PO Number',
  include: 'Include',
  in_platform: 'In-platform',
  in_words: 'In Words',
  input: 'input',
  input_target_perct: 'Input Target (%)',
  insights: 'Insights',
  internal_note: 'Additional Notes',
  invoice_accept: 'Accept Invoice',
  invoice_details: 'Invoice Details',
  invoice_document: 'Invoice Document',
  invoice_input_rejection: 'Input Rejection Reason',
  invoice_number: 'Invoice Number',
  invoice_no: 'Invoice No.',
  invoice_reject: 'Reject Invoice',
  invoice_reject_reason: 'Rejection Reason',
  invoice_status_map: {
    [INVOICE_STATUS.APPROVED]: 'Approved by Shipper',
    [INVOICE_STATUS.PAID]: 'Paid',
    [INVOICE_STATUS.PAYMENT_PENDING]: 'Invoice Received',
    [INVOICE_STATUS.CREATED]: 'Invoice Received',
    [INVOICE_STATUS.REJECTED]: 'Rejected by Shipper',
  },
  invoice_status_log_map: {
    [INVOICE_STATUS.APPROVED]: 'Accepted by Shipper',
    [INVOICE_STATUS.PAID]: 'Paid',
    [INVOICE_STATUS.PAYMENT_PENDING]: 'Invoice received',
    [INVOICE_STATUS.CREATED]: 'Invoice received',
    [INVOICE_STATUS.REJECTED]: 'Rejected by Shipper',
  },
  invoice_status_map_create: 'Invoice created by <strong>{{name}}</strong>',
  invoice: 'Invoice',
  is_the_truck_well_equipped: 'Is the truck well equipped?',
  issue: 'Issue',
  issues: 'Issue(s)',
  item: 'Item',
  kargo: 'Kargo',
  kode_lambung: 'Kode Lambung',
  lane_id: 'Lane ID',
  last_updated: 'Last Updated',
  last_updated_at: 'Last updated at',
  late: 'Late',
  late_docs_end_date: 'Late Document End Date',
  late_docs_start_date: 'Late Document Start Date',
  late_shipments: 'Late Shipment(s)',
  latest_pickup_date_and_time: 'Latest Pickup Date & Time',
  latest_pickup_date: 'Latest Pickup Date',
  latest_pickup_date_point: 'Latest Pickup Date Point {{point}}',
  latest_pickup_time: 'Latest Pickup Time',
  latest_pickup_time_point: 'Latest Pickup Time Point {{point}}',
  latest_pickup: 'Latest Pickup',
  latest_status: 'Latest Status',
  latest_vendor_confirmation_time: 'Latest Vendor Confirmation Time',
  lead_time: 'Lead Time',
  leave_page: 'Leave Page',
  length: 'Length',
  license_plate: 'License Plate',
  license_plate_dooring: 'License Plate Dooring',
  license_plate_origin: 'License Plate Origin',
  limited_hours: 'Limited Hours',
  line: 'Line',
  link: 'Link',
  link_copied: 'Link Copied',
  list: 'Table',
  live_location: 'Live Location',
  live_tracking: 'Live Tracking',
  load_and_unload: 'Loading & Unloading',
  load_date_and_time: 'Loading Date and Time',
  load_location: 'Loading Location',
  load_rating: 'Load Rating',
  load_more: 'Load More',
  load_schedule: 'Loading Schedule',
  load_only: 'Load',
  load: 'Loading',
  loading_date: 'Loading Date',
  local_shipment_statuses: {
    [LOCAL_SHIPMENT_STATUSES.ALL]: 'All Orders',
    [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: 'At Location',
    [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: 'In Location',
    [LOCAL_SHIPMENT_STATUSES.COMPLETED]: 'History',
    [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: 'In Transit',
    [LOCAL_SHIPMENT_STATUSES.ONGOING]: 'Ongoing',
    [LOCAL_SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [LOCAL_SHIPMENT_STATUSES.TRACKED]: 'Tracked', // for avoid key error
  },
  location: 'Location',
  location_and_warehouse: 'Location and Warehouse',
  location_code: 'Location Code',
  location_details: 'Location Details',
  location_group: 'Location Group',
  location_id: 'Location ID',
  location_name: 'Location Name',
  location_status_map: {
    [SHIPPER_LOCATION_STATUS.INACTIVE]: 'Inactive',
    [SHIPPER_LOCATION_STATUS.REGISTERED]: 'Active',
  },
  location_type: {
    [LOCATION_TYPE.HOLDING]: 'Holding Location',
    [LOCATION_TYPE.NORMAL]: 'Normal',
    [LOCATION_TYPE.PORT]: 'Port',
    [LOCATION_TYPE.RETURN_DEPO]: 'Return Depo',
    [LOCATION_TYPE.STORE]: 'Store',
  },
  locations: 'Locations',
  login: 'Log in',
  logo: 'Logo',
  logout: 'Logout',
  mark_all_as_read: 'Mark All As Read',
  measurement_unit: 'Unit',
  mid_mile: 'Mid Mile',
  minutes: 'Minutes',
  minimum_quantity_paid: 'Minimum Quantity to be Paid',
  more_filters: 'More Filters',
  monthly_transaction: 'Monthly Transaction',
  msds: 'Material Safety Data Sheet',
  multi_pickup_dropoff: 'Multipickup/Multidropoff',
  multistop_fee: 'Multistop Fee',
  name: 'Name',
  negotiation_price: 'Negotiation Price',
  new_bill_of_lading: 'New Bill of Lading',
  new_customer: 'New Customer',
  new_delivery_order: 'New Delivery Order',
  new_location: 'New Location',
  new_password: 'New Password',
  new_pricing: {
    additional_do: 'Additional DO',
    committed_do: 'Committed DO',
    cycle_time: 'Cycle Time',
    do: '{{number}} DO',
    do_s: '{{number}} DO(s)',
    do_s_created: 'DO(s) Created',
    effective_date: 'Effective Date',
    history: 'History',
    total_do_created: 'Total DO Created',
    your_committed_do: 'Your Committed DO',
  },
  new_warehouse: 'New Warehouse',
  next: 'Next',
  no_container: 'Container Number',
  no_data_during_this_time_frame: ' No Data During This Time Frame',
  no_data: ' No Data',
  no_expected_time: 'No Expected Time',
  no_photos: 'No Photos',
  no_po: 'PO Number',
  no_suggested_price_from_kargo: 'No Suggested Price from Kargo',
  no: 'No',
  noa: 'NOA',
  non_actual_route: 'Non actual route',
  note: 'Note',
  note_with_colon: 'Note :',
  notes_for_driver: 'Notes for Driver',
  notes: 'Notes',
  notification: 'Notification',
  notifications: 'Notifications',
  notify_party: 'Notify Party',
  off: 'Off',
  ok: 'Ok',
  ok_caps: 'OK',
  on: 'On',
  on_time: 'On Time',
  on_time_perfomance: 'On-Time Perfomance',
  on_time_shipments: 'On Time Shipment(s) ',
  open: 'Open',
  open_24_hrs: 'Open 24 hrs',
  open_map: 'Open Map',
  operating_hours: 'Operating Hours',
  operational_hours: 'Operational Hours',
  optional: 'Optional',
  order: 'Order',
  order_accepted_by_vendor: 'Order Accepted by Vendor',
  order_created: 'Order Created',
  order_detail: 'Order Detail',
  order_details: 'Order Details',
  order_num: 'Order Number',
  order_number: 'Order No.',
  order_no_existed: 'Order No. Existed',
  order_plural: 'Orders',
  order_rejected_by_vendor: 'Order Rejected by Vendor',
  order_type: 'Order Type',
  origin: 'Origin',
  other: 'Others',
  outbound_number: 'Outbound PO Number',
  owner_name: 'Owner Name',
  password: 'Password',
  past_seven_days: 'Past 7 Days',
  past_three_months: 'Past 3 Months',
  payment_and_tax_details: 'Payment and Tax Details',
  payment_details: 'Payment Details',
  payment_types: {
    [PAYMENT_TYPES_INVOICE.LOADING_FEE]: 'Loading Fee',
    [PAYMENT_TYPES_INVOICE.MULTIDROPOFF_FEE]: 'Multi Drop-off Fee',
    [PAYMENT_TYPES_INVOICE.MULTIPICKUP_FEE]: 'Multi Pickup Fee',
    [PAYMENT_TYPES_INVOICE.OTHER_FEE]: 'Other Fee',
    [PAYMENT_TYPES_INVOICE.OVERNIGHT_FEE]: 'Overnight Fee',
    [PAYMENT_TYPES_INVOICE.TICKET_FEE]: 'Ticket Fee',
    [PAYMENT_TYPES_INVOICE.TRUCKING_FEE]: 'Shipment Fee',
    [PAYMENT_TYPES_INVOICE.UNLOADING_FEE]: 'Unloading Fee',
  },
  pbi_shipment_cannot_be_selected: 'PT Panthera Biru Indonesia shipment cannot be selected',
  period: 'Period',
  per_cbm: 'Per CBM',
  per_kg: 'Per Kg',
  per_ton: 'Per Ton',
  per_route: 'Per Route',
  percentage_delivered: '% Delivered',
  personal_account: 'Personal Account',
  phone_number: 'Phone Number',
  phone: 'Phone',
  pib: 'PIB',
  pic_name: 'PIC Name',
  pickup: 'Pickup',
  pickup_and_dropoff_time: 'Pickup and Dropoff Time',
  pickup_date_and_time: 'Pickup Date and Time',
  pickup_date: 'Pickup Date',
  pickup_date_point: 'Pickup Date Point {{point}}',
  pickup_location: 'Pickup Location',
  pickup_time: 'Pickup Time',
  pickup_time_point: 'Pickup Time Point {{point}}',
  plan: 'Plan',
  planned_date: 'Planned Date',
  planned_loading: 'Planned Loading',
  planned_unloading: 'Planned Unloading',
  planned: 'Planned',
  po_number: 'PO Number',
  pod_details: 'POD Details',
  pod_number: 'POD Number',
  podf: 'PODF',
  point: 'Point',
  port: 'Port',
  port_of_discharge: 'Port of Discharge',
  port_of_loading: 'Port of Loading',
  posted_price: 'Posted Price',
  pph23: 'Pph23',
  pph23_deductor: 'PPh23 Deductor',
  pph23_deductors: {
    [PPH23_DEDUCTOR.PARTNER]: 'Customer',
    [PPH23_DEDUCTOR.SELF]: 'My Company',
  },
  ppn: 'PPN',
  pre_alert_document: 'Pre-Alert Documents',
  previous_month: 'Past Month',
  price: 'Price',
  price_bid_limit_input_reminder: 'Price and bid limit input reminder after order is assigned to Private Marketplace',
  price_history: 'Price History',
  price_per_cbm: 'Price (per CBM)',
  price_per_kg: 'Price (per Kg)',
  price_per_ton: 'Price (per Ton)',
  price_rate_type: 'Price rate type',
  pricing: 'Pricing',
  pricing_details: 'Pricing Details',
  pricing_settings: 'Pricing Settings',
  private_marketplace: 'Private Marketplace',
  product: 'Product',
  product_batch: 'Product Batch',
  product_category_map: {
    [PRODUCT_CATEGORY.ALCOHOLIC_BEVARAGES]: 'Alcoholic Beverages',
    [PRODUCT_CATEGORY.DRINKING_WATER]: 'Drinking Water',
    [PRODUCT_CATEGORY.COFFEE]: 'Coffee',
    [PRODUCT_CATEGORY.FRUIT_FLAVORED_DRINKS]: 'Fruit Flavored Drinks',
    [PRODUCT_CATEGORY.JUICE]: 'Juice',
    [PRODUCT_CATEGORY.MILK]: 'Milk',
    [PRODUCT_CATEGORY.NON_DAIRY]: 'Non-Dairy Milk',
    [PRODUCT_CATEGORY.POWDERED_BEVERAGES_MIXES]: 'Powdered Beverage Mixes',
    [PRODUCT_CATEGORY.SODA]: 'Soda',
    [PRODUCT_CATEGORY.SPORTS_ENERGY_DRINKS]: 'Sports & Energy Drinks',
    [PRODUCT_CATEGORY.TEA_INFUSIONS]: 'Tea & Infusions',
    [PRODUCT_CATEGORY.WATER]: 'Water',
    [PRODUCT_CATEGORY.BAKERY]: 'Bakery',
    [PRODUCT_CATEGORY.SNAKES]: 'Snacks',
    [PRODUCT_CATEGORY.CONDIMENTS_SOUCES]: 'Condiments & Sauces',
    [PRODUCT_CATEGORY.COOKING_BAKING_INGREDIENTS]: 'Cooking & Baking Ingredients',
    [PRODUCT_CATEGORY.DAIRY]: 'Dairy Products (Chilled)',
    [PRODUCT_CATEGORY.DIPS]: 'Dips & Spreads',
    [PRODUCT_CATEGORY.FOOD]: 'Food Gift Baskets',
    [PRODUCT_CATEGORY.FROZEN]: 'Frozen Desserts & Novelties',
    [PRODUCT_CATEGORY.FRUITS]: 'Fruits & Vegetables',
    [PRODUCT_CATEGORY.GRAINS]: 'Grains, Rice & Cereal',
    [PRODUCT_CATEGORY.MEAT]: 'Meat, Seafood & Eggs',
    [PRODUCT_CATEGORY.NUTS]: 'Nuts & Seeds',
    [PRODUCT_CATEGORY.PASTA]: 'Pasta & Noodles',
    [PRODUCT_CATEGORY.PREPARED]: 'Prepared Foods',
    [PRODUCT_CATEGORY.SPICES]: 'Seasonings & Spices',
    [PRODUCT_CATEGORY.SNACKS]: 'Snack Foods',
    [PRODUCT_CATEGORY.SOUPS]: 'Soups & Broths',
    [PRODUCT_CATEGORY.TOUFU]: 'Tofu, Soy & Vegetarian Products',
    [PRODUCT_CATEGORY.FITNESS_AND_NUTRITION]: 'Fitness & Nutrition',
    [PRODUCT_CATEGORY.MEDICINE_AND_DRUGS]: 'Medicine & Drugs',
    [PRODUCT_CATEGORY.FMCG]: 'FMCG',
    [PRODUCT_CATEGORY.PLASTIC]: 'Plastic Pellets',
    [PRODUCT_CATEGORY.PACKAGING]: 'Packaging',
  },
  product_details: 'Product Details',
  product_group: 'SKU Group',
  province: 'Province',
  purchase_order: 'Purchase Order',
  qr_code: {
    message_error_permission_title: 'GPS And Camera Access Required',
    message_error_permission_content: 'Please enable the location service and camera in your device settings',
    message_gps: 'GPS or location services must be turned on at all times',
    message_read: 'Please Read :',
    message_scan: 'Scan the QR code to save and update your order status',
    message_status: 'Status will be updated sequentially from  the last recorded status',
    message_position: 'Place QR code within the frame',
    message_unable: 'Unable to Scan QR Code',
    message_unable_content:
      'Please make sure your shipment information is recorded in our system before updating the status',
    scan_qr: 'Scan QR Code',
  },
  qty_damaged: 'Qty Damaged',
  qty_delivered: 'Qty Delivered',
  qty_planned: 'Qty Planned',
  qty_semi_damaged: 'Qty Semi-damaged',
  quantity: 'Quantity',
  quantity_delivered: 'Quantity Delivered',
  quantity_damaged: 'Quantity Damaged',
  quantity_semi_damaged: 'Quantity Semi-Damaged',
  quantity_type: 'Quantity Type',
  quarantine_date: 'Quarantine Date',
  quarantine_document: 'Quarantine Document',
  quarantine_type: 'Quarantine Type',
  queue_overtime: 'Queue Overtime',
  queue_time: 'Queue Time',
  queueing_time: 'Queueing Time',
  rate: 'Rate',
  rating_details: 'Rating Details',
  rating_score: 'Rating Score',
  rating: 'Rating',
  reason: 'Reason',
  reassign: 'Reassign',
  real_time_update: 'Real Time Update',
  receiver_detail: 'Receiver Details',
  reevaluate: 'Re-evaluate',
  region: 'Region',
  register: 'Register',
  register_new_vendor: 'Register New Vendor',
  registration_id: 'Registration ID',
  reject: 'Reject',
  remaining_lead_time: 'Remaining Lead Time',
  reports: 'Reports',
  resend_verification_code: 'Resend Verification Code',
  retry: 'Retry',
  retype_new_password: 'Retype New Password',
  retype_password: 'Re-enter Password',
  reupload: 'Reupload',
  review: 'Review',
  review_pod: 'Review POD',
  roles: 'Roles',
  route: 'Route',
  route_details: 'Route Details',
  route_to_be_traversed: 'Route to be traversed',
  route_type: 'Route Type',
  routes: 'Routes',
  route_suggested_by_google: 'Route suggested by Google Maps',
  route_summary: 'Route Summary',
  same_city: 'Same City',
  save: 'Save',
  save_changes: 'Save Changes',
  save_customer: 'Save Customer',
  save_location: 'Save Location',
  save_password: 'Save Password',
  save_transporter: 'Save Transporter',
  save_warehouse: 'Save Warehouse',
  saved_warehouse: 'Saved Warehouse',
  score: 'Score',
  seal_no: 'Seal No.',
  seal_number: 'Seal Number',
  search_address: 'Search Address',
  search_contract_number: 'Search Contract Number',
  search_location: 'Search Location',
  search_vendor_code_id: 'Search Vendor Name, Code, ID',
  search_vendor_or_contract_name: 'Search Vendor Name or Contract Name',
  search: 'Search',
  search_do: 'Search DO',
  see_changes: 'See changes',
  see_details: 'See Details',
  see_more: 'See More',
  see_orders: 'See Orders',
  see_live_tracking: 'See live tracking',
  select_as: 'Select AS',
  select_reason_for_cancellation: 'Select cancellation reason',
  select_reason_for_change_location: 'Select location change reason',
  select_time: 'Select Time',
  semi_damaged: 'Semi-Damaged',
  semi_damaged_on_arrival: 'Semi-Damaged on Arrival',
  serial_numbers: 'Serial Number',
  set_price: 'Set Price',
  settings: 'Settings',
  share_link: 'Share Link',
  shipment_alerts_for_issues_during_shipment_process: 'Shipment alerts for issues during shipment process',
  shipment_completed: 'Shipment Completed',
  shipment_date: 'Shipment Date',
  shipment_created: 'Shipment <strong>{{name}}</strong> has been created',
  shipment_direction: 'Shipment Direction',
  shipment_evaluation: 'Shipment Evaluation',
  shipment_points: 'Shipment Points',
  shipment_rating: 'Shipment Rating',
  shipment_route: 'Shipment Route',
  shipment: 'Shipment',
  shipment_document_type_map: {
    [SHIPMENT_DOCUMENT_TYPES.CLAIMS_AND_RETURNS]: 'Claims And Returns',
    [SHIPMENT_DOCUMENT_TYPES.INTERNAL_POL]: 'POL',
    [SHIPMENT_DOCUMENT_TYPES.INTERNAL_POD]: 'POD',
  },
  shipment_drop: 'Dropoff',
  shipment_form_section_map: {
    [SHIPMENT_FORM_SECTIONS.ASSIGN_VENDOR_SECTION]: 'Assign Vendor',
    [SHIPMENT_FORM_SECTIONS.ORDER_DETAILS_SECTION]: 'Order Details',
    [SHIPMENT_FORM_SECTIONS.PRODUCT_DETAIL_SECTION]: 'Product Details',
    [SHIPMENT_FORM_SECTIONS.ROUTE_SECTION]: 'Route',
    [SHIPMENT_FORM_SECTIONS.SUMMARY_SECTION]: 'Order Summary',
  },
  shipment_job_status_map: {
    [JOB_STATUSES.UNASSIGNED]: 'Unassigned',
    [JOB_STATUSES.BIDDING]: 'Bidding',
    [JOB_STATUSES.CANCELLED]: 'Cancelled',
    [JOB_STATUSES.CONFIRMATION_EXPIRED]: 'Confirmation Expired',
    [JOB_STATUSES.OPEN]: 'Not Assigned',
    [JOB_STATUSES.TRANSPORTER_CONFIRMED]: 'Vendor Confirmed',
    [JOB_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'Vendor Confirmed',
    [JOB_STATUSES.WAITING_FOR_CONFIRMATION]: 'Awaiting Vendor\'s Confirmation',
    COMPLETED: 'Completed',
    PROCEED_TO_SHIPPING: 'Proceed to shipping',
    WAITING_FOR_TRUCK_DATA: 'Waiting for truck data',
    READY_TO_BOOK: 'Ready to Book'
  },
  shipment_no: 'Shipment Number',
  shipment_number: 'Shipment Number',
  shipment_pickup: 'Pickup',
  shipment_price: 'Shipment Price',
  shipment_status_map: {
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Late',
    [DERIVED_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Canceled by shipper',
    [DERIVED_SHIPMENT_STATUSES.JOB_BIDDING]: 'Bidding',
    [DERIVED_SHIPMENT_STATUSES.JOB_WAITING_FOR_PRICE_INPUT]: 'Waiting for Price Input',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [DERIVED_SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'Awaiting Vendor\'s Confirmation',
    [DERIVED_SHIPMENT_STATUSES.ORDER_PENDING]: 'Awaiting Vendor\'s Confirmation',
    [DERIVED_SHIPMENT_STATUSES.PICKUP_EMPTY_CONTAINER]: 'Pickup Empty',
    [DERIVED_SHIPMENT_STATUSES.STUFFING]: 'Stuffing',
    [DERIVED_SHIPMENT_STATUSES.ONGOING_TO_PORT_OF_LOADING]: 'Ongoing to Port of Loading',
    [DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_LOADING]: 'At Port of Loading',
    [DERIVED_SHIPMENT_STATUSES.ONGOING_TO_PORT_OF_DISCHARGE]: 'Ocean Freight',
    [DERIVED_SHIPMENT_STATUSES.AT_PORT_OF_DISCHARGE]: 'At Port of Discharge',
    [DERIVED_SHIPMENT_STATUSES.ORDER_CONFIRMED]: 'Vendor Confirmed',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'At Destination',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'At Origin',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Not Confirmed by Vendor',
    [SHIPMENT_STATUSES.DOCUMENT_UPLOADED]: 'Document Uploaded',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Ongoing to Destination',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Ongoing to Destination',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to Origin',
    [SHIPMENT_STATUSES.PLANNED]: 'Vendor Confirmed',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.SHIPMENT_REASSIGNMENT]: 'Shipment Reassignment',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled by Internal',
    [SHIPMENT_STATUSES.UPCOMING]: 'Not Assigned',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'Finish Unloading',
    [SHIPMENT_STATUSES.ACTIVATE_VEHICLE_ALERT]: 'Activated',
    [SHIPMENT_STATUSES.DEACTIVATE_VEHICLE_ALERT]: 'Deactivated',
    [DERIVED_SHIPMENT_STATUSES.ISSUES]: 'Issue',
  },
  shipment_status_map_multi: {
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [DERIVED_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Canceled by shipper',
    [DERIVED_SHIPMENT_STATUSES.JOB_BIDDING]: 'Bidding',
    [DERIVED_SHIPMENT_STATUSES.JOB_WAITING_FOR_PRICE_INPUT]: 'Waiting for Price Input',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin {{sequence}}',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination {{sequence}}',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrived at Point {{sequence}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrived at Point {{sequence}}',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Not Confirmed by Vendor',
    [SHIPMENT_STATUSES.DOCUMENT_UPLOADED]: 'Document Uploaded {{sequence}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading at Point {{sequence}}',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading at Point {{sequence}}',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Ongoing to Point {{sequence}}',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Ongoing to Point {{sequence}}',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Ongoing to Point {{sequence}}',
    [SHIPMENT_STATUSES.PLANNED]: 'Vendor Confirmed',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Rejected by vendor',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading at Point {{sequence}}',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading at Point {{sequence}}',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled by Internal',
    [SHIPMENT_STATUSES.UPCOMING]: 'Not Assigned',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'Finish Unloading {{sequence}}',
    [SHIPMENT_POINT_STATUS.COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.ACTIVATE_VEHICLE_ALERT]: 'Activated',
    [SHIPMENT_STATUSES.DEACTIVATE_VEHICLE_ALERT]: 'Deactivated',
    ISSUE: 'Issue',
  },
  shipment_new_status_map_btms: {
    [BTMS_SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled by Vendor',
    [BTMS_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Vendor',
  },
  shipment_summary: 'Shipment Summary',
  shipment_trace_messages: {
    canceled: 'This shipment has been cancelled.',
    no_data: 'Failed to retrieve data for this shipment.',
    ongoing: 'Your shipment is ongoing. See the live location',
    planned: 'Your shipment has not started yet. This trace is for completed shipment only.',
  },
  shipment_type: 'Shipment Type',
  shipment_vendor_assignment_type_description: {
    BROADCAST_CONTRACTED: 'Assign DO to all vendors in your contacts.',
    PRIVATE_BIDDING: 'Assign DO to only your vendor in a private marketplace.',
    SINGLE_CONTRACTED: 'Assign DO to a specific vendor of your choice.',
  },
  shipment_vendor_assignment_type_statement: {
    BROADCAST_CONTRACTED: 'Price will change according to the new route',
    PRIVATE_BIDDING: 'Price will not change and use the previously price inputted',
    SINGLE_CONTRACTED: 'Price will change according to the new route',
  },
  shipment_vendor_assignment_type_name: {
    BROADCAST_CONTRACTED: 'All Vendors',
    PRIVATE_BIDDING: 'Private Marketplace',
    SINGLE_CONTRACTED: 'Selected Vendors',
  },
  shipments: 'Shipments',
  shipmentsParentheses: 'Shipment(s)',
  shipper: 'Shipper',
  shipper_location_operating_day_map: {
    [SHIPPER_LOCATION_OPERATING_DAY.FRIDAY]: 'Friday',
    [SHIPPER_LOCATION_OPERATING_DAY.HOLIDAY]: 'National Holiday',
    [SHIPPER_LOCATION_OPERATING_DAY.MONDAY]: 'Monday',
    [SHIPPER_LOCATION_OPERATING_DAY.SATURDAY]: 'Saturday',
    [SHIPPER_LOCATION_OPERATING_DAY.SUNDAY]: 'Sunday',
    [SHIPPER_LOCATION_OPERATING_DAY.THURSDAY]: 'Thursday',
    [SHIPPER_LOCATION_OPERATING_DAY.TUESDAY]: 'Tuesday',
    [SHIPPER_LOCATION_OPERATING_DAY.WEDNESDAY]: 'Wednesday',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKDAY]: 'Working Day',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKEND]: 'Weekend',
  },
  shipper_profile_activity_log: 'Shipper Profile Activity Log',
  shipping_line: 'Shipping Line',
  shipping_line_delivery_order: 'Shipping Line Delivery Order',
  shipping_seal_number: 'Shipping Seal No.',
  shipping_seal_number_no_abbrev: 'Shipping Seal Number',
  show: 'Show',
  single_pickup_dropoff: 'Single Pickup and Dropoff',
  skip: 'Skip',
  sku: 'SKU',
  sku_details: 'SKU Details',
  sku_name: 'SKU Name',
  sms: 'SMS',
  so_sto_number: 'SO/STO Number',
  source_port: 'Port of Origin',
  sppb: 'SPPB',
  sppkp: 'SPPKP',
  standard: 'Standard',
  start_date: 'Start Date',
  start_end_date: 'Start - End date',
  start_time: 'Start Time',
  start_trial: 'Start Trial',
  status: 'Status',
  status_change_timestamp: 'Status Change Timestamp',
  status_confirmation: 'Status Confirmation',
  status_log: 'Status Log',
  stay: 'Stay',
  storage_charges: 'Storage Charges',
  store: 'Store',
  stuffing_date: 'Stuffing Date',
  stuffing_date_time: 'Stuffing Date Time',
  stuffing_time: 'Stuffing Time',
  submit: 'Submit',
  submitted_bid: 'Submitted Bid',
  subtotal: 'Subtotal',
  successfully_saved_changes: 'Successfully saved changes',
  summary: 'Summary',
  supplier: 'Supplier',
  supporting_document: 'Supporting Document',
  switch_account: 'Switch Account',
  target_input_error: 'Input target between 1 to 100',
  tax_document: 'Tax Document',
  telephone_no: 'Phone Number',
  terminal: 'Terminal',
  things: 'Things',
  this_month: 'This Month',
  this_year: 'This Year',
  tila: 'TILA',
  time: 'Time',
  time_in_hours: 'Time (Hour)',
  time_rating: 'Time Rating',
  tin: 'NPWP',
  today: 'Today',
  tonnage: 'Tonnage',
  tonnage_or_cubication: 'Capacity',
  total: 'Total',
  total_amount: 'Total Amount',
  total_shipment: 'Total Shipment',
  trace_history: 'Trace History',
  timestamp: 'Timestamp',
  trace_timestamp: 'Trace Timestamp',
  tracking: 'Tracking',
  transaction_recap: 'Transaction Recap',
  transit_time: 'Transit Time',
  transporter: 'Transporter',
  transporter_details: 'Transporter Details',
  transporter_tms: 'Transporter TMS',
  travelling_expenses: 'Travelling Expenses',
  truck: 'Truck',
  truck_and_driver: 'Truck & Driver',
  truck_and_driver_assigned_by_vendor: 'Truck and Driver Assigned by Vendor',
  truck_and_driver_reassigned_by_vendor: 'Order details changed by Vendor',
  truck_and_driver_reassignment_etc: 'e.g. Truck and driver reassignment, etc.',
  truck_detail: 'Truck Detail',
  truck_is_experiencing_issues: 'Truck is experiencing issues',
  truck_list: 'Truck List',
  truck_monitoring: 'Truck Monitoring',
  truck_monitoring_warehouse_status_map: {
    [DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.ALL]: 'All Direction',
    [DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.INBOUND]: 'Inbound',
    [DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.OUTBOUND]: 'Outbound',
  },
  fm_truck_monitoring_warehouse_status_map: {
    [FM_DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.STUFFING]: 'Stuffing',
    [FM_DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.PORT]: 'Port',
  },
  truck_not_found: 'Truck not found',
  truck_rating: 'Truck Rating',
  truck_selection: 'Truck Selection',
  truck_type: 'Truck Type',
  type: 'Type',
  type_of_access: 'Access Type',
  type_of_company: 'Type of Company',
  type_of_product: 'Type of Product',
  type_to_search_driver: 'Type 1 character to search driver',
  type_to_search_license_plate: 'Type 1 character to search license plate',
  unit_list: 'Unit list',
  unload: 'Unloading',
  unload_date_and_time: 'Unloading Date and Time',
  unload_location: 'Unloading Location',
  unloading_location_type_date_and_time: {
    [LOCATION_TYPE.HOLDING]: 'Unloading Date & Time',
    [LOCATION_TYPE.NORMAL]: 'Unloading Date & Time',
    [LOCATION_TYPE.PORT]: 'Unloading Date & Time',
    [LOCATION_TYPE.RETURN_DEPO]: 'Return Date & Time',
    [LOCATION_TYPE.STORE]: 'Unloading Date & Time',
  },
  unloading_time: 'Unloading Time',
  update_shipment_change: 'Update Shipment Changes',
  updated: 'Updated',
  updates: 'Updates',
  upload: 'Upload',
  upload_bill_of_lading: 'Upload Bill Of Lading',
  upload_contracts: 'Upload Contracts',
  upload_coo: 'Upload Certificate of Origin',
  upload_csv: 'Upload CSV',
  upload_document: 'Upload Document',
  upload_ebilling_document: 'Upload E-Billing Document',
  upload_house_bill_of_lading: 'Upload House Bill Of Lading',
  upload_invoice_document: 'Upload Invoice Document',
  upload_locations: 'Upload Locations',
  upload_logo: 'Upload Logo',
  upload_message: 'To upload this file, please change',
  upload_msds: 'Upload Material Safety Data Sheet',
  upload_multiPD_order: 'Upload Multipickup/Multidropoff Orders',
  upload_noa: 'Upload NOA',
  upload_npwp: 'Upload NPWP',
  upload_orders: 'Create Multiple Orders',
  upload_pib: 'Upload PIB',
  upload_pod: 'Upload POD',
  upload_quarantine_document: 'Upload Quarantine Document',
  upload_shipping_line_delivery_order: 'Upload Delivery Order',
  upload_singlePD_order: 'Upload Single Pickup and Dropoff Orders',
  upload_sppb: 'Upload SPPB',
  upload_sppkp: 'Upload SPPKP',
  upload_storage_charges: 'Upload Storage Charges',
  upload_tax_document: 'Upload Tax Document',
  upload_the_id_card: 'Upload KTP',
  upload_tila: 'Upload TILA',
  uploaded_from: 'Uploaded From',
  upselling_popup: {
    upselling_banner_title_1: 'Transportation Management',
    upselling_banner_body_1:
      'End-to-end delivery order management seamlessly done from a centralized dashboard. Create Delivery Orders, dispatch, allocate, and set pricing—all tailored to your needs. ',
    upselling_banner_title_2: 'Private Marketplace',
    upselling_banner_body_2:
      'Empower your procurement process with ease of sourcing, inputting price bid limit, bid winner selection, and enabling logistics cost reduction',
    upselling_banner_title_3: 'Shipment Visibility',
    upselling_banner_body_3:
      'Track and trace shipments going into and out of warehouses with ease. Get alerts, monitor queues, and keep tabs on every move.',
    upselling_banner_title_4: 'Real-time Data Insights',
    upselling_banner_body_4:
      'Make the most of insights only Kargo Nexus can provide. Turn your analytics into solid reports for better strategy—from fulfillment rates down to the average lead time.',
    upselling_button_start_trial: 'Start Free Trial',
    upselling_button_explore: 'Explore Kargo Nexus',
    upselling_feedback_title: 'Help us develop Kargo Nexus to suit your needs!',
    upselling_feedback_subtitle: 'Choose one that best represents your current situation:',
    upselling_feedback_checkbox_own: 'I already have my own system',
    upselling_feedback_checkbox_manually: 'I can manage my shipment manually',
    upselling_feedback_checkbox_features: 'I need other features that are not currently available',
    upselling_feedback_checkbox_others: 'Others (please specify)',
    upselling_feedback_dont_show: `Don't show me again`,
    upselling_feedback_placeholder_need: 'Tell us what you need',
    upselling_feedback_placeholder_others: 'Other reasons',
    upselling_feedback_placeholder_please_fill: 'Please fill in your reference',
    upselling_home_title: 'Get the Full Kargo Nexus Experience',
    upselling_home_body:
      'Easily manage shipments through a seamless integration based on your logistics needs, from transportation management to analytics reports—all in one.',
  },
  user: 'User',
  user_access_type_map: {
    [DANONE_USER_ACCESS_TYPE.DANONE_ADMIN_3PL]: '3PL Admin',
    [DANONE_USER_ACCESS_TYPE.DANONE_LOGISTIC]: 'Logistics',
    [DANONE_USER_ACCESS_TYPE.DANONE_PROCUREMENT]: 'Procurement',
    [DANONE_USER_ACCESS_TYPE.WAREHOUSE]: 'Warehouse',
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [USER_ACCESS_TYPE.ADMIN]: 'Admin',
    [USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Full Access',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Restricted Access',
    [USER_SALES_ROLE.SME_SALES_EXECUTIVE]: 'Sales Executive',
    [USER_SALES_ROLE.SME_TEAM_LEADER]: 'Team Leader',
    [USER_SALES_ROLE.SME_TELESALES]: 'Telesales',
    [USER_ACCESS_TYPE.FINANCE]: 'Finance',
  },
  user_status_map: {
    [USER_STATUSES.ACTIVE]: 'Active',
    [USER_STATUSES.INACTIVE]: 'Inactive',
    [USER_STATUSES.REGISTERED]: 'Registered',
  },
  valid_until: 'Valid Until',
  variant: 'Variant',
  vehicle_body_type: 'Body Type',
  vehicle_type: 'Truck Type',
  vehicles: 'Vehicles',
  vendor: 'Vendor',
  vendor_address: 'Vendor Address',
  vendor_code: 'Vendor Code',
  vendor_confirmation_time_types: {
    [VENDOR_CONFIRMATION_TIME_TYPES.AFTER]: 'After Pickup Date and Time',
    [VENDOR_CONFIRMATION_TIME_TYPES.AT]: 'At Pickup Date and Time',
    [VENDOR_CONFIRMATION_TIME_TYPES.BEFORE]: 'Before Pickup Date and Time',
  },
  vendor_contract: 'Vendor Contract',
  vendor_details: 'Vendor Details',
  vendor_id: 'Vendor ID',
  vendor_name: 'Vendor Name',
  vendor_registration_id: 'Vendor Registration ID',
  vendor_trucking: 'Vendor Trucking',
  vendor_with_parentheses: '(Vendor)',
  vendors: 'Vendors',
  verification_code: 'Verification Code',
  vessel_detail: 'Vessel Details',
  vessel_name: 'Vessel Name',
  vessel_voyage: 'Vessel Voyage',
  vessel_voyage_schedule: 'Vessel Voyage Schedule',
  via_qr: 'via QR Code',
  view: 'View',
  view_changes: 'View Changes',
  view_delivery: 'View Delivery',
  view_order_details: 'View Order Details',
  view_order: 'View Order',
  view_truck_list: 'View Truck List',
  voyage_number: 'Voyage Number',
  warehouse: 'Warehouse',
  warehouse_name: 'Warehouse Name',
  weight: 'Weight',
  weight_per_container: 'Weight per Container',
  where_you_receive_notifications: 'Where you receive these notifications',
  width: 'Width',
  wps_seal_number: 'Seal No.',
  yes: 'Yes',
  yes_cancel: 'Yes, cancel',
  pbi_full_text: 'Panthera Biru Indonesia',
  other_vendors: 'Other Vendors',
  project_name: 'Project Name',
  invoice_date: 'Invoice Date',
  invoice_amount: 'Invoice Amount',
  payment: 'Payment',
  remaining_amount: 'Remaining Amount',
  payment_status: 'Payment Status',
  payment_proof: 'Payment Proof',
  search_invoice_number: 'Search Invoice Number',
  download_xls: 'Download XLS',
  upload_payment_proof: 'Upload Payment Proof',
  soa_summary_card_title: {
    [SOA_SUMMARY_SELECTOR.NOT_DUE_AMOUNT]: 'Not Due',
    [SOA_SUMMARY_SELECTOR.PAST_DUE_AMOUNT]: 'Past Due Date',
  },
  shipper_payment_status_map: {
    [SHIPPER_PAYMENT_STATUS.DUE]: 'Due',
    [SHIPPER_PAYMENT_STATUS.NOT_DUE]: 'Not Due',
    [SHIPPER_PAYMENT_STATUS.PARTIALLY_PAID]: 'Partially Paid',
    [SHIPPER_PAYMENT_STATUS.PAID]: 'Paid',
  },
  past_due_date: 'Past Due Date',
  remaining_payment: 'Remaining Payment',
  payment_amount: 'Payment Amount',
  view_proof: 'View Proof',
  not_uploaded: 'Not Uploaded',
  yes_delete: 'Yes, delete',
  payment_date: 'Payment Date',
  total_payment: 'Total Payment',
  live_soa_proof_status_map: {
    [LIVE_SOA_PROOF_STATUS.UPLOADED]: 'Proof Uploaded',
    [LIVE_SOA_PROOF_STATUS.NOT_UPLOADED]: 'No Proof Uploaded',
  },
  no_record: 'No Record',
  no_project: 'No Project',
};
