export function isValidBooleanString(booleanString) {
  if (booleanString === undefined) {
    return false;
  }

  return booleanString.toUpperCase() === 'TRUE' || booleanString.toUpperCase() === 'FALSE';
}

export function convertStringBooleanToBoolean(stringBoolean, fallbackValue = undefined) {
  if (!(isValidBooleanString(stringBoolean))) {
    return fallbackValue;
  }

  return stringBoolean.toUpperCase() === 'TRUE';
}

export function splitAndTrim(input = '', delimiter = '|') {
  if (!input) return [];
  return String(input)?.split(delimiter).map(item => item?.trim());
}
