/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function OngoingTo() {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 0C4.94772 0 4.5 0.447715 4.5 1C4.5 1.55228 4.94772 2 5.5 2H10.8018L10.0161 6.32111C9.91733 6.86449 10.2777 7.38507 10.8211 7.48387C11.3645 7.58267 11.8851 7.22226 11.9839 6.67889L12.2891 5H14.9648L16.5 7.30278V10.5H16.042C15.6562 9.61705 14.7752 9 13.75 9C12.7248 9 11.8438 9.61705 11.458 10.5H7.04198C6.6562 9.61705 5.77516 9 4.75 9C3.72484 9 2.8438 9.61705 2.45802 10.5H2V8.5C2 7.94772 1.55228 7.5 1 7.5C0.447715 7.5 0 7.94772 0 8.5V10.5C0 11.6046 0.895431 12.5 2 12.5H2.45802C2.8438 13.383 3.72484 14 4.75 14C5.77516 14 6.6562 13.383 7.04198 12.5H11.458C11.8438 13.383 12.7248 14 13.75 14C14.7752 14 15.6562 13.383 16.042 12.5H16.6464C17.138 12.5 17.6095 12.3047 17.9571 11.9571C18.3047 11.6095 18.5 11.138 18.5 10.6464V7.30278C18.5 6.90793 18.3831 6.52191 18.1641 6.19338L16.6289 3.8906C16.258 3.3342 15.6335 3 14.9648 3H12.6528L12.7695 2.35777C12.9927 1.13009 12.0496 0 10.8018 0H5.5ZM2 0C1.44772 0 1 0.447715 1 1C1 1.55228 1.44772 2 2 2H2.5C3.05228 2 3.5 1.55228 3.5 1C3.5 0.447715 3.05228 0 2.5 0H2ZM1 4C1 3.44772 1.44772 3 2 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H2C1.44772 5 1 4.55228 1 4ZM4.5 6C3.94772 6 3.5 6.44772 3.5 7C3.5 7.55228 3.94772 8 4.5 8H8C8.55228 8 9 7.55228 9 7C9 6.44772 8.55228 6 8 6H4.5ZM4.75 10.5C4.19772 10.5 3.75 10.9477 3.75 11.5C3.75 12.0523 4.19772 12.5 4.75 12.5C5.30228 12.5 5.75 12.0523 5.75 11.5C5.75 10.9477 5.30228 10.5 4.75 10.5ZM12.75 11.5C12.75 10.9477 13.1977 10.5 13.75 10.5C14.3023 10.5 14.75 10.9477 14.75 11.5C14.75 12.0523 14.3023 12.5 13.75 12.5C13.1977 12.5 12.75 12.0523 12.75 11.5Z" fill="currentColor" />
    </svg>
  );
}
