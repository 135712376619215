/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function Pickup() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0C2.11929 0 1 1.11929 1 2.5V7.5C1 8.88071 2.11929 10 3.5 10H16.5C17.8807 10 19 8.88071 19 7.5V2.5C19 1.11929 17.8807 0 16.5 0H3.5ZM3 2.5C3 2.22386 3.22386 2 3.5 2H16.5C16.7761 2 17 2.22386 17 2.5V7.5C17 7.77614 16.7761 8 16.5 8H3.5C3.22386 8 3 7.77614 3 7.5V2.5ZM0.5 12C0.5 11.4477 0.947715 11 1.5 11H4.5H9.5H18.5C19.0523 11 19.5 11.4477 19.5 12C19.5 12.5523 19.0523 13 18.5 13H17V14H17.5C18.0523 14 18.5 14.4477 18.5 15C18.5 15.5523 18.0523 16 17.5 16H16H14.5C13.9477 16 13.5 15.5523 13.5 15C13.5 14.4477 13.9477 14 14.5 14H15V13H11.95C11.9828 13.1616 12 13.3288 12 13.5C12 14.8807 10.8807 16 9.5 16C8.11929 16 7 14.8807 7 13.5C7 13.3288 7.01722 13.1616 7.05001 13H6.94999C6.98278 13.1616 7 13.3288 7 13.5C7 14.8807 5.88071 16 4.5 16C3.11929 16 2 14.8807 2 13.5C2 13.3288 2.01722 13.1616 2.05001 13H1.5C0.947715 13 0.5 12.5523 0.5 12ZM9.5 13C9.22386 13 9 13.2239 9 13.5C9 13.7761 9.22386 14 9.5 14C9.77614 14 10 13.7761 10 13.5C10 13.2239 9.77614 13 9.5 13ZM4 13.5C4 13.2239 4.22386 13 4.5 13C4.77614 13 5 13.2239 5 13.5C5 13.7761 4.77614 14 4.5 14C4.22386 14 4 13.7761 4 13.5ZM4 3.75C4 3.33579 4.33579 3 4.75 3C5.16421 3 5.5 3.33579 5.5 3.75V6.25C5.5 6.66421 5.16421 7 4.75 7C4.33579 7 4 6.66421 4 6.25V3.75ZM7.75 3C7.33579 3 7 3.33579 7 3.75V6.25C7 6.66421 7.33579 7 7.75 7C8.16421 7 8.5 6.66421 8.5 6.25V3.75C8.5 3.33579 8.16421 3 7.75 3ZM11.5 3.75C11.5 3.33579 11.8358 3 12.25 3C12.6642 3 13 3.33579 13 3.75V6.25C13 6.66421 12.6642 7 12.25 7C11.8358 7 11.5 6.66421 11.5 6.25V3.75ZM15.25 3C14.8358 3 14.5 3.33579 14.5 3.75V6.25C14.5 6.66421 14.8358 7 15.25 7C15.6642 7 16 6.66421 16 6.25V3.75C16 3.33579 15.6642 3 15.25 3Z" fill="currentColor" />
    </svg>
  );
}
