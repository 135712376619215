import gql from 'graphql-tag';

export const profileQuery = ({ NFS_PBI_ORDERS_TRACKING = false } = {}) => gql`
query PROFILE($companyKsuid: String!) {
  myEmployeeProfile(companyKsuid: $companyKsuid) {
    accessType
    accessTypes
    company {
      address
      aliasName
      email
      handle
      hasConsentedCustomer
      isDofEligible
      isPodfEligible
      ksuid
      ${NFS_PBI_ORDERS_TRACKING ? 'linkedBrokerageKsuid' : ''}
      logo {
        fileName
        uploadedAt {
          date
        }
        url {
          full
        }
      }
      metadata
      name
      phoneNumber
      pricingSettings {
        pricingRateType
        pricingRequiredVariables
        pricingIdempotentVariables
      }
      status
      type
      companyReference {
        ksuid
      }
    }
    email
    ksuid
    name
    notificationMetadata
    user {
      defaultLocale
      email
      ksuid
      phoneNumber
    }
  }
  companySettings(companyKsuid: $companyKsuid) {
    companyDocuments {
      ktpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
      npwpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
      sppkpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
    }
  }
  companyBankAccounts(companyKsuid: $companyKsuid) {
    ksuid
    accountHolder
    accountNumber
    isDefault
    bank {
      ksuid
      name
    }
  }
  enrolledCompanies {
    ksuid
  }
  companyNfsConfigs(
    companyKsuid: $companyKsuid,
  ) {
    companyConfigs{
      name
      configs {
        name
        value
        valueType
      }
    }
  }
}
`;

export default profileQuery();
