import { DERIVED_SHIPMENT_STATUSES } from '~/Configurations/constants';

export const ADMINISTRATIVE_AREAS = {
  LEVEL_1: 'administrative_area_level_1',
  LEVEL_2: 'administrative_area_level_2',
};

export const DATE_TIME = {
  FULL_DATE_WITHOUT_HOUR: 'DD MMMM YYYY',
  FULL_DATE_WITH_HOUR: 'D MMM YYYY, HH.mm',
  HOUR: 'HH:mm',
};

export const GPS_SOURCES = {
  GPS_DEVICE: 'GPS',
  MOBILE: 'Driver app',
};

export const SHIPMENT_POINTS = {
  DROPOFF: 'DROPOFF',
  PICKUP: 'PICKUP',
};

export const TABS = {
  LATE: DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE,
  ON_TIME: DERIVED_SHIPMENT_STATUSES.ON_TIME,
  ISSUE: DERIVED_SHIPMENT_STATUSES.ISSUES,
};

export const TRUCK_LIST_RADIO_BUTTON_FILTER = {
  ALL: 'all',
  OFFLINE: 'offline',
  ONLINE: 'online',
};

export const TRUCK__STATUSES = {
  LATE: 'Late',
  OFFLINE: 'Offline',
  ISSUE: 'Issue',
};

export const DEFAULT_MAP_POSITION = {
  CENTER: {
    lat: -6.2008406,
    lng: 106.7987143
  },
  ZOOM: 8
};

export const FIRST_MILE_STATUS_LOG_OPERATION_NAME = 'change_shipment_status';

export const STMS_DTD_TRUCKING_STATUS = {
  PICKUP_EMPTY_CONTAINER: 'PICKUP_EMPTY_CONTAINER',
  STUFFING: 'STUFFING',
  ONGOING_TO_PORT_OF_LOADING: 'ONGOING_TO_PORT_OF_LOADING',
  AT_PORT_OF_LOADING: 'AT_PORT_OF_LOADING',
};
