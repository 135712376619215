/* eslint-disable max-len */
import Port from './Icons/Port';
import Exclude from './Icons/Exclude';
import VendorConfirmed from './Icons/VendorConfirmed';
import Pickup from './Icons/Pickup';
import Warehouse from './Icons/Warehouse';
import OngoingTo from './Icons/OngoingTo';
import OceanFreight from './Icons/OceanFreight';
import AwaitingConfirmation from './Icons/AwaitingConfirmation';
import { SHIPMENT_STATUSES } from '~/Configurations/constants';

export const TIMELINE_STATUS_COLOR = {
  WAIT: '#818385',
  PROCESS: '#058EFF',
  FINISH: '#05B012',
  ERROR: '#E6221C',
};

export const TIMELINE_STATUS_NAME = {
  WAIT: 'wait',
  PROCESS: 'process',
  FINISH: 'finish',
  ERROR: 'error',
};

export const STMS_AGGREGATED_STATUS = {
  ORDER_PENDING: 'ORDER_PENDING',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  PICKUP_EMPTY_CONTAINER: 'PICKUP_EMPTY_CONTAINER',
  STUFFING: 'STUFFING',
  ONGOING_TO_PORT_OF_LOADING: 'ONGOING_TO_PORT_OF_LOADING',
  AT_PORT_OF_LOADING: 'AT_PORT_OF_LOADING',
  ONGOING_TO_PORT_OF_DISCHARGE: 'ONGOING_TO_PORT_OF_DISCHARGE',
  AT_PORT_OF_DISCHARGE: 'AT_PORT_OF_DISCHARGE',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  REJECTED_BY_VENDOR: 'REJECTED_BY_TRANSPORTER',
};

export const STMS_AGGREGATED_STATUS_COLORS = {
  [STMS_AGGREGATED_STATUS.ORDER_PENDING]: 'warning',
  [STMS_AGGREGATED_STATUS.ORDER_CONFIRMED]: 'success',
  [STMS_AGGREGATED_STATUS.PICKUP_EMPTY_CONTAINER]: 'primary',
  [STMS_AGGREGATED_STATUS.STUFFING]: 'primary',
  [STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_LOADING]: 'primary',
  [STMS_AGGREGATED_STATUS.AT_PORT_OF_LOADING]: 'primary',
  [STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_DISCHARGE]: 'primary',
  [STMS_AGGREGATED_STATUS.AT_PORT_OF_DISCHARGE]: 'primary',
  [STMS_AGGREGATED_STATUS.ONGOING_TO_DESTINATION]: 'primary',
  [STMS_AGGREGATED_STATUS.ARRIVE_AT_DESTINATION]: 'primary',
  [STMS_AGGREGATED_STATUS.SHIPMENT_COMPLETE]: 'success',
  [STMS_AGGREGATED_STATUS.CANCELLED_BY_SHIPPER]: 'danger',
  [STMS_AGGREGATED_STATUS.REJECTED_BY_VENDOR]: 'danger',
};

// TODO: add translation to title shipment:stms_statuses
export const STMS_STATUSES_MAPPING = [
  {
    key: STMS_AGGREGATED_STATUS.ORDER_PENDING,
    icon: AwaitingConfirmation(),
    title: 'Awaiting Confirmation',
  },
  {
    key: STMS_AGGREGATED_STATUS.ORDER_CONFIRMED,
    icon: VendorConfirmed(),
    title: 'Vendor Confirmed',
  },
  {
    key: STMS_AGGREGATED_STATUS.PICKUP_EMPTY_CONTAINER,
    icon: Pickup(),
    title: 'Pickup Empty',
  },
  {
    key: STMS_AGGREGATED_STATUS.STUFFING,
    icon: Warehouse(),
    title: 'Stuffing',
  },
  {
    key: STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_LOADING,
    icon: OngoingTo(),
    title: 'Ongoing to Port',
  },
  {
    key: STMS_AGGREGATED_STATUS.AT_PORT_OF_LOADING,
    icon: Port(),
    title: 'at Port of Loading',
  },
  {
    key: STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_DISCHARGE,
    icon: OceanFreight(),
    title: 'Ocean Freight',
  },
  {
    key: STMS_AGGREGATED_STATUS.AT_PORT_OF_DISCHARGE,
    icon: Port(),
    title: 'at Port of Discharge',
  },
  {
    key: STMS_AGGREGATED_STATUS.ONGOING_TO_DESTINATION,
    icon: OngoingTo(),
    title: 'Ongoing to Destination',
  },
  {
    key: STMS_AGGREGATED_STATUS.ARRIVE_AT_DESTINATION,
    icon: Warehouse(),
    title: 'At Destination',
  },
  {
    key: STMS_AGGREGATED_STATUS.SHIPMENT_COMPLETE,
    icon: Exclude(),
    title: 'Shipment Complete',
  }
];

export const STMS_STATUSES_WITH_ERROR = [
  {
    key: STMS_AGGREGATED_STATUS.ORDER_PENDING,
    title: 'Awaiting Confirmation',
  },
  {
    key: STMS_AGGREGATED_STATUS.ORDER_CONFIRMED,
    title: 'Vendor Confirmed',
  },
  {
    key: STMS_AGGREGATED_STATUS.PICKUP_EMPTY_CONTAINER,
    title: 'Pickup Empty',
  },
  {
    key: STMS_AGGREGATED_STATUS.STUFFING,
    title: 'Stuffing',
  },
  {
    key: STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_LOADING,
    title: 'Ongoing to Port',
  },
  {
    key: STMS_AGGREGATED_STATUS.AT_PORT_OF_LOADING,
    title: 'at Port of Loading',
  },
  {
    key: STMS_AGGREGATED_STATUS.ONGOING_TO_PORT_OF_DISCHARGE,
    title: 'Ocean Freight',
  },
  {
    key: STMS_AGGREGATED_STATUS.AT_PORT_OF_DISCHARGE,
    title: 'at Port of Discharge',
  },
  {
    key: STMS_AGGREGATED_STATUS.ONGOING_TO_DESTINATION,
    title: 'Ongoing to Destination',
  },
  {
    key: STMS_AGGREGATED_STATUS.ARRIVE_AT_DESTINATION,
    title: 'At Destination',
  },
  {
    key: STMS_AGGREGATED_STATUS.SHIPMENT_COMPLETE,
    title: 'Shipment Complete',
  },
  {
    key: STMS_AGGREGATED_STATUS.CANCELLED_BY_SHIPPER,
    title: 'Cancelled by Shipper',
  },
  {
    key: STMS_AGGREGATED_STATUS.REJECTED_BY_VENDOR,
    title: 'Rejected by Vendor',
  }
];

export const DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME = {
  // pre-shipment
  nfs_dtd_shipment_created_from_btms: 'nfs_dtd_shipment_created_from_btms',
  nfs_dtd_shipment_created: 'nfs_dtd_shipment_created',
  nfs_purchase_order_confirmed: 'nfs_purchase_order_confirmed',

  // DO 1
  nfs_assign_planned_trucking_shipment_from_btms: 'nfs_assign_planned_trucking_shipment_from_btms',
  nfs_update_to_arrive_at_empty_depo_from_btms: 'nfs_update_to_arrive_at_empty_depo_from_btms',
  nfs_update_to_arrive_at_pickup_from_btms: 'nfs_update_to_arrive_at_pickup_from_btms',
  nfs_update_to_gate_out_depo_from_btms: 'nfs_update_to_gate_out_depo_from_btms',
  nfs_update_to_gate_in_store_from_btms: 'nfs_update_to_gate_in_store_from_btms',
  nfs_update_to_gate_out_store_from_btms: 'nfs_update_to_gate_out_store_from_btms',
  nfs_update_to_gate_in_cy_from_btms: 'nfs_update_to_gate_in_cy_from_btms',
  nfs_update_to_completed_from_btms: 'nfs_update_to_completed_from_btms',
  nfs_update_to_completed_no_spl_from_btms: 'nfs_update_to_completed_no_spl_from_btms',

  // DO 2
  nfs_dtd_shipping_line_created_from_btms: 'nfs_dtd_shipping_line_created_from_btms',
  nfs_shipping_line_departure_from_btms: 'nfs_shipping_line_departure_from_btms',
  nfs_shipping_line_arrival_from_btms: 'nfs_shipping_line_arrival_from_btms',
  nfs_shipping_line_shipment_complete_from_btms: 'nfs_shipping_line_shipment_complete_from_btms',

  // DO 3
  nfs_update_planned_dooring_date: 'nfs_update_planned_dooring_date',
  nfs_assign_planned_dooring_shipment_from_btms: 'nfs_assign_planned_dooring_shipment_from_btms',
  nfs_dooring_at_destination_from_btms: 'nfs_dooring_at_destination_from_btms',
  nfs_dooring_completed_from_btms: 'nfs_dooring_completed_from_btms',

  // cancel reject PO
  nfs_cancel_purchase_order: 'nfs_cancel_purchase_order',
  nfs_cancel_dtd_containers_from_po_from_btms: 'nfs_cancel_dtd_containers_from_po_from_btms',
  nfs_reject_po_from_btms: 'nfs_reject_po_from_btms',

  // handle reassignment
  nfs_reallocate_trucking_do_from_btms: 'nfs_reallocate_trucking_do_from_btms',
  nfs_reallocate_dooring_do_from_btms: 'nfs_reallocate_dooring_do_from_btms',
  nfs_approve_reassignment_from_btms: 'nfs_approve_reassignment_from_btms', // TODO: add translation when spreadsheet is updated
  nfs_update_vessel_voyage_from_btms: 'nfs_update_vessel_voyage_from_btms',

  // edit routes PO
  nfs_edit_route_change_destination_location: 'nfs_edit_route_change_destination_location',
  nfs_edit_route_change_stuffing_location: 'nfs_edit_route_change_stuffing_location',
  nfs_edit_route_change_planned_pickup_date: 'nfs_edit_route_change_planned_pickup_date',
};

export const DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAMES = Object.values(DOOR_TO_DOOR_ACTIVITY_LOGS_OPERATION_NAME);

export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm';

export const STATUS_TO_STMS_TIMESTAMP_KEY_MAP = {
  ...STMS_AGGREGATED_STATUS,
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: STMS_AGGREGATED_STATUS.STUFFING,
};
