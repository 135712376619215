/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function Port() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1410_44651)">
        <circle cx="10" cy="4" r="2" stroke="currentColor" stroke-width="2" />
        <path d="M10 6V17" stroke="currentColor" stroke-width="2" />
        <path d="M16 11C16 14.3137 13.3137 17 10 17C6.68629 17 4 14.3137 4 11" stroke="currentColor" stroke-width="2" />
        <path d="M17.5 14V11H14" stroke="currentColor" stroke-width="1.5" />
        <path d="M2.5 14V11H6" stroke="currentColor" stroke-width="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_1410_44651">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
