import React, { createContext } from 'react';
import platformConfig from '../Configurations/platform';
import i18n from '~/Services/i18n';

const { lang: DEFAULT_LANG } = platformConfig;

if (platformConfig.lang) {
  i18n.changeLanguage(DEFAULT_LANG);
}

export const AppContext = createContext(platformConfig);

function AppProvider({ children }) {
  return (
    <AppContext.Provider value={platformConfig}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
