/* eslint-disable camelcase */
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { intersection, isEmpty, isEqual } from 'lodash-es';
import { useFlag } from '@unleash/proxy-client-react';

import { AuthContext } from '@kargotech/tms-core/auth';

import { USER_ACCESS_TYPE } from '~/Configurations/constants';
import { getUserProfile } from '~/Models/userProfile';
import { profileQuery as PROFILE } from '~/GraphQL/ProfileService/Queries/profile';
import { APOLLO_CLIENTS } from '~/Services/apollo';
import sentry from '~/Services/sentry';
import useExpiredFreeTrialHook from '~/Hooks/ExpiredBannerHooks';
import { convertStringBooleanToBoolean } from '~/Utilities/string';
import FEATURE_FLAG from '~/Configurations/featureFlag';

export const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const ENABLE_HIDE_SHIPPER_TYPES_CONFIG = useFlag(FEATURE_FLAG.HIDE_SHIPPER_TYPES_CONFIG);

  const { selectedCompanyKsuid } = useContext(AuthContext);
  const [profile, setProfile] = useState(getUserProfile(null));
  const NFS_PBI_ORDERS_TRACKING = useFlag('nfs_pbi_orders_tracking');

  const resetProfile = useCallback(() => setProfile(getUserProfile(null)), []);

  const getSelectedCompany = useCallback(() => {
    const { ksuid, ...restProfileAttrrs } = profile?.company || {};
    return {
      ksuid: selectedCompanyKsuid || ksuid,
      ...restProfileAttrrs
    };
  }, [profile, selectedCompanyKsuid]);

  const {
    data: profileData,
    refetch: getProfile,
    loading: isFetchingProfile,
  } = useQuery(PROFILE({ NFS_PBI_ORDERS_TRACKING }), {
    client: APOLLO_CLIENTS.PROFILE,
    fetchPolicy: 'no-cache',
    skip: !selectedCompanyKsuid,
    variables: {
      companyKsuid: selectedCompanyKsuid,
    },
    onError: resetProfile
  });

  if (!isEmpty(profileData)) {
    const formattedProfileData = getUserProfile(profileData);
    if (!isEqual(formattedProfileData, profile)) {
      setProfile(formattedProfileData);
      // set sentry user context
      sentry.setUser({
        id: formattedProfileData.ksuid,
        email: formattedProfileData.email,
        companyId: formattedProfileData.company.ksuid,
      });
    }
  }

  const getNotificationMetadata = useCallback(() => JSON.parse(profile?.notificationMetadata || null), [profile]);

  const getBtmsNfsLinked = useCallback(() => {
    const { linkedBrokerageKsuid } = getSelectedCompany();

    return Boolean(linkedBrokerageKsuid || null);
  }, [getSelectedCompany]);

  const getCompanyMetadata = useCallback(() => {
    const { metadata } = getSelectedCompany();
    if (!metadata || typeof metadata !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(metadata);
    } catch {
      return undefined;
    }
  }, [getSelectedCompany]);

  const {
    getExpiredBannerCopy,
    getNfsUpsellingFreeTrialMetadata,
    isExpiredFreeTrial,
    isShowExpiredBanner,
    setCloseExpiredBanner,
  } = useExpiredFreeTrialHook(getCompanyMetadata);

  const getFirstMileCompany = useCallback(
    () => {
      try {
        const {
          is_first_mile,
          is_first_mile_transporter,
          ...otherProps
        } = getCompanyMetadata() || {};

        if (is_first_mile) {
          return {
            isFirstMileShipper: true,
            ...otherProps
          };
        }

        if (is_first_mile_transporter) {
          return {
            isFirstMileTransporter: true,
            ...otherProps
          };
        }
        /* eslint-enable camelcase */

        return null;
      } catch (_) {
        return null;
      }
    },
    [getCompanyMetadata]
  );

  // is_first_mile toggle should has been deprecated after intoducing door to door management toggle
  const hasFirstMileNfsConfig = useMemo(() => {
    if (ENABLE_HIDE_SHIPPER_TYPES_CONFIG) {
      return false;
    }

    const companyConfigs = profile?.companyNfsConfigs;
    if (companyConfigs && Array.isArray(companyConfigs)) {
      const shipperTypeConfigs = companyConfigs.find(config => config?.name === 'shipper_type')?.configs;

      if (shipperTypeConfigs && Array.isArray(shipperTypeConfigs)) {
        return convertStringBooleanToBoolean(
          shipperTypeConfigs.find(config => config?.name === 'is_first_mile')?.value
        );
      }
    }

    return false;
  }, [ENABLE_HIDE_SHIPPER_TYPES_CONFIG, profile?.companyNfsConfigs]);

  const hasDoorToDoorManagementConfig = useMemo(() => {
    const companyConfigs = profile?.companyNfsConfigs;
    if (companyConfigs && Array.isArray(companyConfigs)) {
      const modulesConfigs = companyConfigs.find(config => config?.name === 'modules')?.configs;

      if (modulesConfigs && Array.isArray(modulesConfigs)) {
        return convertStringBooleanToBoolean(
          modulesConfigs.find(config => config?.name === 'is_door_to_door_enable')?.value
          // Currently dtd management only available for linked nfs btms account
        ) && getBtmsNfsLinked();
      }
    }

    return false;
  }, [getBtmsNfsLinked, profile?.companyNfsConfigs]);

  // is_first_mile toggle should has been deprecated after intoducing door to door management toggle
  const isFirstMileShipper = useMemo(() => {
    if (ENABLE_HIDE_SHIPPER_TYPES_CONFIG) {
      return false;
    }

    const firstMileCompany = getFirstMileCompany();
    return Boolean(firstMileCompany && firstMileCompany.isFirstMileShipper);
  }, [ENABLE_HIDE_SHIPPER_TYPES_CONFIG, getFirstMileCompany]);

  const isFirstMileTransporter = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return firstMileCompany && firstMileCompany.isFirstMileTransporter;
  }, [getFirstMileCompany]);

  /**
   * Check logged-in user roles are matching with allowed roles.
   * Logged-in user roles is stored in localStorage under selectedCompany.accessTypes property.
   * Allowed roles should be any value from ACCESS_PRIVILEGE.
   * The logged-in user roles will be intersecting with the provided Allowed roles excluding RESTRICTED_ACCESS
   * User is authorized if there's one or more matching roles
   * @require src/Configurations/accessPrevillegeMap.js#ACCESS_PRIVILEGE
   * @example
   * isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPMENT_READ);
   * @param {String[]} roles must-have roles
   * @returns {Boolean} is user authorized or not
   */
  const isAuthorizedToAccess = useCallback(
    (roles = []) => {
      const allowedRoles = roles.filter(role => role !== USER_ACCESS_TYPE.RESTRICTED_ACCESS);
      const userAccessTypes = getSelectedCompany()
        .accessTypes?.filter(accessType => accessType !== USER_ACCESS_TYPE.RESTRICTED_ACCESS) || [];

      return !!intersection(allowedRoles, userAccessTypes).length;
    },
    [getSelectedCompany]
  );

  return (
    <ProfileContext.Provider
      value={{
        getBtmsNfsLinked,
        getCompanyMetadata,
        getExpiredBannerCopy,
        getFirstMileCompany,
        getNfsUpsellingFreeTrialMetadata,
        getNotificationMetadata,
        getProfile,
        getSelectedCompany,
        hasDoorToDoorManagementConfig,
        hasFirstMileNfsConfig,
        isAuthorizedToAccess,
        isExpiredFreeTrial,
        isFetchingProfile,
        isFirstMileShipper,
        isFirstMileTransporter,
        isShowExpiredBanner,
        profile,
        resetProfile,
        setCloseExpiredBanner,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
