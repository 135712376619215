/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function AwaitingConfirmation() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM7 3.5V8.75C7 9.44036 7.55964 10 8.25 10H11.75V8.5H8.5V3.5H7Z" fill="currentColor" />
    </svg>
  );
}
