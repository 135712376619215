/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function Exclude() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.1878 5.65078C12.5473 5.23145 12.4987 4.60015 12.0794 4.24073C11.66 3.88131 11.0287 3.92987 10.6693 4.3492L6.22923 9.52929L4.70711 8.00717C4.31658 7.61664 3.68342 7.61664 3.29289 8.00717C2.90237 8.39769 2.90237 9.03086 3.29289 9.42138L5.57861 11.7071C5.77557 11.9041 6.04575 12.0099 6.32409 11.9993C6.60243 11.9886 6.8637 11.8623 7.04497 11.6508L12.1878 5.65078Z" fill="currentColor" />
    </svg>
  );
}
